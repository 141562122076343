import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import formatRichMessage from '@utils/formatRichMessage';
import {DRAG_AND_DROP, ROUTES} from '@constants';
import ManageColumnsItem from "@pages/EntityView/components/EntityViewGridTable/components/ManageColumns/components/ManageColumnsItem";

import OverlaySpinner from '@scripts/reusable/OverlaySpinner/OverlaySpinner';
import {withDragAndDrop} from "@reusable/HOC/withDragAndDrop";

const ManageColumns = (props) => {
    // refs
    const popoverRef = useRef(null);
    const manageColumnsRef = useRef(null);
    // state
    const [isOpen, setIsOpen] = useState(false);
    const [state, setState] = useState({
        overlayIsVisible: false,
        hoverIndex: null,
        currentIndex: null,
        canDrop: true
    });

    const [droppedContentTypeName, setDroppedContentTypeName] = useState(null);

    //props
    const {
        elementsVisibility: contentTypes,
        updateElementsVisibility,
        resetTable, isLoading,
        tableColumnsOrder,
        handleTableColumnsOrderChange
    } = props;

    const popupText = formatRichMessage({ id: 'BatchUpload.hideColumnsPopup.preferencesPopupText' });
    const [pendingDroppedContentTypeName, setPendingDroppedContentTypeName] = useState(null);

    const someContentTypesAreDisabled = contentTypes.some(
        ({ enabledInGeneralSettings }) => !enabledInGeneralSettings
    );

    const handleOpen = () => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const handleClickOutside = useCallback(
        (event) => {
            if (
                popoverRef.current &&
                !popoverRef.current.contains(event.target) &&
                !manageColumnsRef.current.contains(event.target)
            ) {
                handleOpen();
            }
        },
        [popoverRef, manageColumnsRef]
    );

    useEffect(() => {
        isOpen && document.addEventListener('mousedown', handleClickOutside);
        ReactTooltip.rebuild();

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside, isOpen]);

    const updateVisualDropTarget = (currentIndex, hoverIndex) => {
        let prevHoverIndex = state.hoverIndex;
        if ((hoverIndex === undefined || currentIndex === hoverIndex) && prevHoverIndex !== null) {
            setState({
                currentIndex: null,
                hoverIndex: null,
            });
        }
        if (prevHoverIndex !== hoverIndex && Math.abs(currentIndex - hoverIndex)) {
            setState({
                currentIndex: currentIndex,
                hoverIndex: hoverIndex,
            });
        }
    }

    const reorderColumns = (columns, currentIndex, hoverIndex, droppedContentTypeName) => {
        const newColumns = columns.slice();

        while (currentIndex < 0) {
            currentIndex += newColumns.length;
        }
        while (hoverIndex < 0) {
            hoverIndex += newColumns.length;
        }
        if (hoverIndex >= newColumns.length) {
            let k = hoverIndex - newColumns.length;
            while (k-- + 1) {
                newColumns.push(undefined);
            }
        }

        newColumns.splice(hoverIndex, 0, newColumns.splice(currentIndex, 1)[0]);

        if (hasColumnsOrderChanged(newColumns)) {
            setPendingDroppedContentTypeName(droppedContentTypeName);
            handleTableColumnsOrderChange(newColumns)
        } else {
            setPendingDroppedContentTypeName(droppedContentTypeName);
            startDroppedAnimation(false, droppedContentTypeName);
        }
    }

    const hasColumnsOrderChanged = (newColumns) => {
        const arraysAreEqual = newColumns.every((value, index) => value === tableColumnsOrder[index]);
        return !arraysAreEqual;
    }

    const startDroppedAnimation = (isLoading, newDroppedContentTypeName) => {
        if (!isLoading && newDroppedContentTypeName) {
            setState(() => ({
                hoverIndex: null,
            }));

            setDroppedContentTypeName(newDroppedContentTypeName);
            setPendingDroppedContentTypeName(null);

            setTimeout(() => {
                setDroppedContentTypeName(null);
            }, 2500);
        }
    }

    //This useEffect hook is used to set and reset droppedContentTypeName for animation purposes in ManageColumnsItem
    useEffect(() => {
        startDroppedAnimation(isLoading, pendingDroppedContentTypeName);
    }, [isLoading])

    const requestReorder = (index, hoverIndex, currentElement) => {
        reorderColumns(tableColumnsOrder, index, hoverIndex, currentElement.contentType.columnId);
    };

    const getMatchingContentType = (columnId) => {
        return contentTypes.find(contentType => contentType.columnId === columnId);
    }

    const renderContentTypes = () => {
        return tableColumnsOrder.map((column, index) => {
            const contentType = getMatchingContentType(column);
            const { enabledInGeneralSettings} = contentType;
            const shouldContentTypeBeDisabled =
                contentType.columnId === "entityName" || contentType.columnId === "comments" || !enabledInGeneralSettings;
            const { hoverIndex, currentIndex} = state;

            return (
                <ManageColumnsItem
                    key={contentType.columnId}
                    index={index}
                    contentType={contentType}
                    shouldContentTypeBeDisabled={shouldContentTypeBeDisabled}
                    updateElementsVisibility={updateElementsVisibility}
                    droppedContentTypeName={droppedContentTypeName}
                    showDropTarget={hoverIndex === (index || 0)}
                    insertAbove={hoverIndex < currentIndex}
                    updateVisualDropTarget={updateVisualDropTarget}
                    requestReorder={requestReorder}
                />
            );
        });
    };

    return (
        <div className='manage-columns-filter-container'>
            <div
                ref={manageColumnsRef}
                className={`la-Columns manage-columns-button ${isOpen ? 'active' : ''}`}
                onClick={handleOpen}
                data-track='manage-columns-button'
                data-testid='manage-columns-button'
            >
                <p>{formatRichMessage({ id: 'BatchScreening.page.manageColumns' })}</p>
                <div className={isOpen ? 'la-TriangleUp active' : 'la-TriangleDown'} />
            </div>
            {isOpen && (
                <>
                    <div ref={popoverRef} className={`manage-columns-popover ${isLoading ? 'disabled' : ''}`} data-track='manage-columns-popover' data-testid='manage-columns-popover'>
                        <button className='link-button' data-track='manage-columns-reset-table-button' data-testid='manage-columns-reset-table-button' onClick={resetTable}>
                            <FormattedMessage id='BatchScreening.page.contextMenu.resetTable' />
                        </button>
                        {contentTypes.length !== 0 && renderContentTypes()}
                        <Link
                            className='link-button'
                            to={ROUTES.USER_PREFERENCES}
                            onClick={handleOpen}
                            data-tip={popupText}
                            data-for='column-disabled-in-preferences'
                            data-testid='manage-columns-preferences-link-button'
                        >
                            <FormattedMessage id='BatchUpload.hideColumnsPopup.preferencesLink' />
                        </Link>
                        {isLoading && (
                            <>
                                <div className='loading-overlay' data-track='manage-columns-loading-overlay' data-testid='manage-columns-loading-overlay'/>
                                <OverlaySpinner className='overlay-spinner' size='large' />
                            </>
                        )}
                    </div>

                    {someContentTypesAreDisabled && (
                        <ReactTooltip
                            id='column-disabled-in-preferences'
                            type='light'
                            event='mouseenter'
                            eventOff='mouseleave'
                            border={true}
                            effect='solid'
                            className='tooltips'
                            place='right'
                        />
                    )}
                </>
            )}
        </div>
    );
};

ManageColumns.propTypes = {
    elementsVisibility: PropTypes.array,
    updateElementsVisibility: PropTypes.func,
    resetTable: PropTypes.func,
};

const dndHookInfo = {
    componentType: 'parent',
    accept: DRAG_AND_DROP.CONTENT_TYPE,
};

export default injectIntl(withDragAndDrop(dndHookInfo)(ManageColumns));
