import React, { Component } from 'react';

export class BatchHeaderTemplate extends Component {
    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text;
        return { ...uncertainCell, text };
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    render(cell) {
        const headerValue = cell.text;

        return (
            <div className="generic-table grid-table-batch-header-template" >
                <div>
                    {headerValue}
                </div>
            </div>
        );
    }
}
