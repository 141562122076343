// @flow
import * as React from 'react';
import CountPods from '@pages/MainSearch/components/CountPods';
import {
    ALL_CATEGORIES,
    BREADCRUMBS,
    CATEGORIES,
    CATEGORY_NAMES,
    DOWNLOAD_DEFAULT_FILENAME,
    DOWNLOAD_SNAPSHOT_DEFAULT_FILENAME,
    ERROR_CODES,
    MODAL_TYPE,
    PERSON_SEARCH,
    SNAPSHOT_PAGE_TITLE,
    LAUNCHED_SEARCH_FROM,
    POST_FILTER_FUZZY_SEARCH,
    POST_FILTER_FUZZY_THRESHOLD,
    DEFAULT_DELIVERY_OPTIONS,
    BREADCRUMBS_PREV_PATH,
    DISPLAY_RISK_SCORES,
    RESULTS_PAGE_TITLE,
    DOCUMENT_PAGE_TITLE,
} from '@constants';
import { EVENT_SUBTYPES, ACTION_TYPES } from '@sagas/constants/investigationConstants';
import ArticleNavigationActions from '@pages/MainSearch/redux/ArticleNavigation.actions';
import PopupModel from '@reusable/PopupModal/PopupBuilder';
import reduxStore from '@reduxStore';
import searchSagaActions from '@pages/MainSearch/redux/searchSaga.actions';
import popupModelActions from '@reusable/PopupModal/redux/PopupModel.actions';
import ArticlesUtils from '@pages/MainSearch/ArticlesUtils';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import { FormattedMessage, injectIntl } from 'react-intl';
import PillList from '@reusable/PillList/PillList';
import BreadCrumbsModel from '@utils/breadCrumbBuilder';
import LoadingPillList from '@reusable/PillList/LoadingPillList';
import categoryUtils, { getCategoryOrder } from '@utils/categoryUtils';
import { withAppContext } from '@utils/contexts';
import uboActions from '@pages/StartPage/redux/Ubo.actions';
import actions from '@UserPreferences/redux/UserPreferences.actions';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import userPreferencesUtils from '@utils/userPreferencesUtils';
import errorUtils from '@utils/errors/error-utils';
import ResultsSnapshotError from '@pages/MainSearch/components/ResultsSnapshotError';
import MainSearchApi from '@MainSearch/MainSearch.api';
import SuggestedNamesUtils from '@reusable/SuggestedNames/SuggestedNamesUtils';
import SnapshotDeliveryUtils from '@utils/snapshotDeliveryUtils';
import { areAllCategoriesLoaded } from '@sagas/helpers/metricHelpers';
import type {
    ContentTypesType,
    GeneralSettingsType,
    SearchResultsType,
} from '@pages/MainSearch/components/typeGuards/ResultsList.typeGuards';
import type { SearchResultsCategoryType } from '@MainSearch/typeGuards/SearchResults.typeGuard';
import type { UserType } from '@sagas/investigationTypeGuards/investigationSelectors.type.guards';
import type { InvestigationWithReportType } from '@sagas/investigationTypeGuards/investigationFlows.type.guards';
import type { PopupModelType, RouterLocation } from '@utils/flow/utilities.type.guards';
import type { FuzzyNamesType } from '@utils/flow/deliveryService.type.guards';
import type { SuggestedNamesType } from '@reusable/SearchBarHeader/flow/SearchBarHeader.typeGuards';
import type { BreadCrumbsType } from '@reusable/Breadcrumbs/typeGuards/Breadcrumbs.typeGuards';
import searchUtils from '@pages/MainSearch/SearchUtils';
import withPreferenceRefresh from '@pages/Main/hoc/withPreferenceRefresh';
import type { SearchParams } from '@reusable/SearchBar/redux/flow/SearchBar.type.guards';
import OverlaySpinner from '@reusable/OverlaySpinner/OverlaySpinner';
import SaveSearchButton from './SaveSearchButton';
import AddToEntityViewButton from './AddToEntityViewButton';
import costCodeUtils from '@utils/costCodeUtils';
import withThemeSwitcherContext from '@contexts/hoc/withThemeSwitcher';
// $FlowFixMe
import { ReactComponent as BannerInfoIcon } from '../../../../assets/icons/BannerInfoIcon.svg';
// $FlowFixMe
import { ReactComponent as BannerInfoIconDark } from '../../../../assets/icons/BannerInfoIconDark.svg';
import { hashHistory } from 'react-router';
import withInvestigationEvents from '@pages/MainSearch/components/hoc/WithInvestigationEvents';
import InvestigationEventsProvider from '@pages/MainSearch/components/providers/InvestigationEventsProvider';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';

import articlesManagerActions from '../redux/ArticlesManager.actions';
import investigationActions from '@MainSearch/redux/Investigation.actions';
import searchParamsActions from '@MainSearch/redux/SearchParams.actions';
import breadcrumbsActions from '@scripts/reusable/Breadcrumbs/redux/Breadcrumbs.actions';
import sanctionsRiskActions from "@MainSearch/components/sanctionsRisk/redux/SanctionsRisk.actions";

const BREADCRUMBS_PADDING = 15;

// TODO: Currently each category is added as a separate prop,
//  just add the whole SearchResults from redux and make it all dynamic.
//  Adding a new category is painful

type CategoriesType = {
    allCategoriesList: Array<string>,
    categoryName: string,
    count: number,
    enabled: boolean,
    loaded: boolean,
};

type State = {
    all: CategoriesType,
    legal: CategoriesType,
    top: number,
    pillListHeaderWidth: number,
    pillListContainerWidth: number,
    suggestedNamesPillListHeaderWidth: number,
    suggestedNamesPillListContainerWidth: number,
};

type InjectedProps = {|
    +intl: Object,
    context: ContextType,
|};

type Props = {
    investigationEventsProviderProps: Object,
    searchResults: SearchResultsType,
    location: RouterLocation,
    showSnapshot: boolean,
    negativeNews: SearchResultsCategoryType,
    news: SearchResultsCategoryType,
    customNews: SearchResultsCategoryType,
    financialReports: SearchResultsCategoryType,
    directors: SearchResultsCategoryType,
    sanctions: SearchResultsCategoryType,
    peps: SearchResultsCategoryType,
    biographical: SearchResultsCategoryType,
    agencyDecision: SearchResultsCategoryType,
    legal: SearchResultsCategoryType,
    dockets: SearchResultsCategoryType,
    federalDockets: SearchResultsCategoryType,
    lawReviews: SearchResultsCategoryType,
    verdicts: SearchResultsCategoryType,
    lawSources: SearchResultsCategoryType,
    ubo: SearchResultsCategoryType,
    esgRatings: SearchResultsCategoryType,
    dnbSubcategories: Array<string>,
    fuzzyNames: FuzzyNamesType,
    suggestedNames: SuggestedNamesType,
    isSuggestedNamesEnabled: boolean,
    suggestedNamesFeatureEnabled: boolean,
    isFuzzyLoaded: boolean,
    generalSettings: GeneralSettingsType,
    updateBreadcrumbs: (breadcrumbs: BreadCrumbsType) => void,
    adHocNewsSource: string,
    newsSource: string,
    contentTypes: Array<ContentTypesType>,
    useNewResearchSummary: boolean,
    useSnapshotDelivery: boolean,
    negativeNewsVisualisation: Object,
    sanctionsRisk: Object,
    user: UserType,
    investigation: InvestigationWithReportType,
    isSearchComplete: boolean,
    backgroundMessages: Object,
    uboInit: () => void,
    adHocNewsDateRange: {
        range: string,
    },
    adHocLegalDateRange: {
        range: string,
    },
    adHocCompanyDateRange: {
        range: string,
    },
    isFuzzyEnabled: boolean,
    searchParams: SearchParams,
    isSnapshotVisible: boolean,
    checkPreferencesAreObsolete: () => void,
    displayRiskScores: string,
    showEsgRatings: boolean,
    popupModel: PopupModelType,
    clearPopup: () => void,
    isBatchReportsEnabled: boolean,
    prevPath: string,
    isSavingSearch: boolean,
    isAddingToEntityView: boolean,
    searchState: Object,
    updatePrevPath: () => void,
    sanctionsAndWatchListsSnapshot: boolean,
    reduxStoreState: Object,
    ...InjectedProps,
};

let shouldSendFeatureFunctionAccessEvent = false;

class ResultsSnapshot extends React.Component<Props, State> {
    wrapper: { offsetTop: number };
    pillListContainer: HTMLElement | null;
    pillListHeader: HTMLElement | null;
    suggestedNamesPillListContainer: HTMLElement | null;
    suggestedNamesPillListHeader: HTMLElement | null;
    searchTermContainer: HTMLElement | null;

    constructor(props: Props) {
        super(props);

        if (window._paq) {
            window._paq.push(['setDocumentTitle', 'Snapshot Page']);
            window._paq.push(['trackPageView']);
        }
    }

    setBreadcrumbs(): void {
        const searchFrom = this.props.location?.query?.searchFrom;
        let breadcrumbs = new BreadCrumbsModel.Builder(BREADCRUMBS.SNAPSHOT)
            .setProperty('searchQuery', utils.sanitizeSearchStringInput(this.props.searchParams.query))
            .setProperty('searchQueryType', this.props.searchParams.searchType)
            .setProperty('isSnapshotVisible', this.props.isSnapshotVisible)
            .setProperty('prevPath', this.props.prevPath)
            .setProperty('category', this.props.searchParams.category)
            .setProperty('searchFrom', searchFrom)
            .setProperty('launchedFrom', this.props.searchState.launchedFrom)
            .build().breadcrumbs;
        this.props.updateBreadcrumbs(breadcrumbs);
    }

    UNSAFE_componentWillMount(): void {
        this.setBreadcrumbs();
        window.scroll(0, 0);
        const isSearchLaunchedFromScreening =
            this.props.location.query?.searchFrom === LAUNCHED_SEARCH_FROM.SCREENING_PAGE;

        if (utils.isUboEnabled() && !isSearchLaunchedFromScreening) {
            // this helps to init ubo when going back/forward in browser history
            this.props.uboInit();
        }
        this.setState({
            legal: this.calculateAllSources(CATEGORY_NAMES.CASES, this.props),
            all: this.calculateAllSources(ALL_CATEGORIES, this.props),
        });
    }

    componentDidMount(): void {
        shouldSendFeatureFunctionAccessEvent = document.title === RESULTS_PAGE_TITLE || document.title === DOCUMENT_PAGE_TITLE;
        document.title = SNAPSHOT_PAGE_TITLE;
        this.props.setCategory(null);
        this.setState({
            top: this.wrapper.offsetTop + BREADCRUMBS_PADDING,
        });
    }

    componentWillUnmount() {
        if (this.props.popupModel && this.props.popupModel.popupType === MODAL_TYPE.APPLY_RISK_SCORES_PREFERENCE) {
            this.props.clearPopup();
        }
    }

    UNSAFE_componentWillReceiveProps(props: Props): void {
        this.setBreadcrumbs();
        this.setState({
            legal: this.calculateAllSources(CATEGORY_NAMES.CASES, props),
            all: this.calculateAllSources(ALL_CATEGORIES, props),
        });
    }

    componentDidUpdate(prevProps: Props, prevState: State): void {
        if (
            prevProps.fuzzyNames !== null &&
            !prevState.pillListHeaderWidth &&
            this.pillListContainer &&
            this.pillListHeader
        ) {
            this.setState({
                pillListHeaderWidth: this.pillListHeader.clientWidth,
                pillListContainerWidth: this.pillListContainer.clientWidth,
            });
        }
        if (
            prevProps.suggestedNames !== null &&
            !prevState.suggestedNamesPillListHeaderWidth &&
            this.suggestedNamesPillListContainer &&
            this.suggestedNamesPillListHeader
        ) {
            this.setState({
                suggestedNamesPillListHeaderWidth: this.suggestedNamesPillListHeader.clientWidth,
                suggestedNamesPillListContainerWidth: this.suggestedNamesPillListContainer.clientWidth,
            });
        }
        if (
            this.props.prevPath === '/' &&
            this.props.location?.query?.searchFrom === LAUNCHED_SEARCH_FROM.SCREENING_PAGE
        ) {
            this.props.updatePrevPath({
                ...this.props.location,
                pathname: BREADCRUMBS_PREV_PATH.SCREENING_PAGE,
            });
        }
    }

    getArticleCount: (categoryName: string) => number = (categoryName) => {
        let count: number = 0;

        if (categoryName === this.state.legal.categoryName) {
            count = this.state.legal.count;
        } else if (categoryName === this.state.all.categoryName) {
            count = this.state.all.count;
        } else if (utils.isLegalSubcategoryBoolean(categoryName)) {
            let legalSources = this.calculateAllSources(CATEGORY_NAMES.CASES, this.props);
            count = legalSources.count;
        } else {
            count = this.props[categoryName].count;
        }

        return count;
    };

    getArticleType: (categoryName: string) => string | Array<string> = (categoryName) => {
        let articleType: string | Array<string> = '';
        if (categoryName === this.state.legal.categoryName) {
            articleType = this.state.legal.allCategoriesList;
        } else if (categoryName === this.state.all.categoryName) {
            articleType = this.state.all.allCategoriesList;
        } else if (utils.isLegalSubcategoryBoolean(categoryName)) {
            let legalSources = this.calculateAllSources(CATEGORY_NAMES.CASES, this.props);
            articleType = legalSources.allCategoriesList;
        } else {
            articleType = categoryName;
        }

        return articleType;
    };

    getPostFilters: (categoryName: string) => Object = (categoryName) => {
        //TODO: add other postFilters depend on selected category
        let postFilters = {};
        postFilters.fuzzyNames = this.props.fuzzyNames;
        if (categoryUtils.isNewsSource(categoryName) || categoryName === 'all') {
            postFilters.newsSource = utils.mapNewsSourceNameToKey(this.props.newsSource);
        }

        return postFilters;
    };

    snapshotAllResultsDownload(category: string): boolean {
        return category === this.state.all.categoryName;
    }

    getCategoryName: (category: string) => string = (category) => {
        switch (category) {
            case CATEGORY_NAMES.LAW_REVIEWS_BY_COUNTRY:
            case CATEGORY_NAMES.LAW_REVIEWS:
            case CATEGORY_NAMES.CASES:
            case CATEGORY_NAMES.STATE_DOCKETS:
            case CATEGORY_NAMES.FEDERAL_DOCKETS:
            case CATEGORY_NAMES.VERDICTS:
            case CATEGORY_NAMES.AGENCY_DECISION:
                return 'Legal Source';
            case ALL_CATEGORIES:
                return 'All';
            default:
                return categoryUtils.getCategoryName(category);
        }
    };

    downloadResultList: (event: SyntheticEvent<HTMLButtonElement>) => void = (event) => {
        const category: string = event.currentTarget.value;
        const articleCount: number = this.getArticleCount(category);
        const categoryName: string = this.getCategoryName(category);
        const postFilters = this.getPostFilters(category);
        const currentDate: Date = new Date();
        //TO DO: Temporary fix, this should be sent only for old rs flow reports
        const researchSummary = ArticlesUtils.getReportSummary();
        const categoryOrder = this.props.useNewResearchSummary ? getCategoryOrder(this.props.contentTypes) : undefined;
        const fileNameExtension = this.props.searchParams.query + '_';
        const fileName: string =
            DOWNLOAD_DEFAULT_FILENAME +
            utils.formatReportFileName(fileNameExtension) +
            utils.formatFileName(categoryName) +
            '_' +
            utils.formatReportDateWithtTimezoneOffset(currentDate.getTime());
        const popupType: string = MODAL_TYPE.DOWNLOAD_RESULT_SNAPSHOT;
        const popupModel: PopupModelType = new PopupModel.Builder(popupType)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('fileName', fileName)
            .setPopupProperty('researchSummary', researchSummary)
            .setPopupProperty('categoryOrder', categoryOrder)
            .setPopupProperty('searchQuery', utils.sanitizeSearchStringInput(this.props.searchParams.query))
            .setPopupProperty('searchQueryType', this.props.searchParams.searchType)
            .setPopupProperty('prefilterQuery', this.props.searchParams.prefilterQuery)
            .setPopupProperty('totalArticlesCount', articleCount)
            .setPopupProperty('postFilters', postFilters)
            .setPopupProperty('articleType', this.getArticleType(category))
            .setPopupProperty('snapshotAllResultsDownload', this.snapshotAllResultsDownload(category))
            .setPopupProperty('contentsOptions', DEFAULT_DELIVERY_OPTIONS.snapshotDeliveries)
            .setPopupProperty('showDeliveryOptions', true)
            .build();
        reduxStore.dispatch(popupModelActions.setPopupModel(popupModel));
    };

    downloadSnapshot: () => void = () => {
        const popupType: string = MODAL_TYPE.DOWNLOAD_SNAPSHOT_PAGE;
        const currentDate: Date = new Date();
        const fileName: string =
            DOWNLOAD_SNAPSHOT_DEFAULT_FILENAME +
            utils.formatReportFileName(this.props.searchParams.query + '_') +
            utils.formatReportDateWithtTimezoneOffset(currentDate.getTime());
        const categoryOrder = this.props.useNewResearchSummary ? getCategoryOrder(this.props.contentTypes) : undefined;
        const categoryName = this.getCategoryName(ALL_CATEGORIES);
        const postFilters = this.getPostFilters(ALL_CATEGORIES);
        const researchSummary = ArticlesUtils.getReportSummary();
        const dateRangeForCategories = categoryUtils.getDateRangeForCategories({
            adHocNewsDateRange: this.props.adHocNewsDateRange,
            adHocLegalDateRange: this.props.adHocLegalDateRange,
            adHocCompanyDateRange: this.props.adHocCompanyDateRange,
            generalSettings: this.props.generalSettings,
        });

        // if the search is triggered from the Entity View page, the date range should be taken from the entity's post filters set in the searchResults reducer from Redux
        if(this.props.searchState.launchedFrom === LAUNCHED_SEARCH_FROM.SCREENING_PAGE) {
            dateRangeForCategories.news = this.props.news.postFilters.dateRange;
            dateRangeForCategories.lawSources = this.props.legal.postFilters.dateRange;
            dateRangeForCategories.directors = this.props.directors.postFilters.dateRange;
        }

        const snapshotDelivery = {
            searchQuery: utils.mergeQueryAndBoolean(
                utils.sanitizeSearchStringInput(this.props.searchParams.query),
                this.props.searchParams.prefilterQuery
            ),
            suggestedNames: SuggestedNamesUtils.getSelectedSuggestedNames(this.props.suggestedNames),
            deliveryDate: utils.formatDateWithShortMonth(currentDate, this.props.user.preferences.language),
            filtersApplied: this.hasFiltersApplied(),
            displayRiskScores: this.props.displayRiskScores,
            pods: SnapshotDeliveryUtils.buildPodsForSnapshotDelivery({
                searchResults: this.props.searchResults,
                negativeNewsVisualisation: this.props.negativeNewsVisualisation,
                dateRangeForCategories,
                sanctionsRisk: this.props.sanctionsRisk,
            }),
        };

        const storeProperties = {
            timezone: this.props.user.timezone,
            language: this.props.user.preferences.language,
            investigationId: this.props.investigation.id,
            billingId: this.props.investigation.billingId,
            isCustomFuzzy: this.props.searchParams.isCustomFuzzy,
            startEachArticleOnNewPage: this.props.user.preferences.startEachArticleOnNewPage,
        };

        const popupModel: PopupModelType = new PopupModel.Builder(popupType)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('fileName', fileName)
            .setPopupProperty('searchQuery', utils.sanitizeSearchStringInput(this.props.searchParams.query))
            .setPopupProperty('searchQueryType', this.props.searchParams.searchType)
            .setPopupProperty('prefilterQuery', this.props.searchParams.prefilterQuery)
            .setPopupProperty('categoryOrder', categoryOrder)
            .setPopupProperty('researchSummary', researchSummary)
            .setPopupProperty('categoryName', categoryName)
            .setPopupProperty('postFilters', postFilters)
            .setPopupProperty('snapshotDelivery', snapshotDelivery)
            .setPopupProperty('articleType', this.getArticleType(ALL_CATEGORIES))
            .setPopupProperty('storeProperties', storeProperties)
            .build();

        reduxStore.dispatch(popupModelActions.setPopupModel(popupModel));
    };

    getLegalSources: (props: Props) => Array<Object> = (props) => {
        return [
            props.legal,
            props.dockets,
            props.federalDockets,
            props.agencyDecision,
            props.verdicts,
            props.lawReviews,
        ];
    };

    getAllSources: (props: Props) => Array<Object> = (props) => {
        let allSources = [
            props.negativeNews,
            props.news,
            props.customNews,
            props.directors,
            props.sanctions,
            props.peps,
            props.biographical,
            props.legal,
            props.dockets,
            props.federalDockets,
            props.agencyDecision,
            props.verdicts,
            props.lawReviews,
            props.ubo,
            props.esgRatings,
        ];
        if (props.searchParams.searchType !== PERSON_SEARCH) {
            allSources.push(props.financialReports);
        }

        return allSources;
    };

    calculateAllSources: (type: string, props: Props) => Object = (type, props) => {
        let allSources = [];
        let categoryName: string;

        if (type === ALL_CATEGORIES) {
            allSources = this.getAllSources(props);
            categoryName = ALL_CATEGORIES;
        } else if (type === CATEGORY_NAMES.CASES) {
            allSources = this.getLegalSources(props);
            allSources.forEach((item) => {
                if (item && item.enabled && item.loaded && item.count !== 0) {
                    categoryName = item.name;
                }
            });
        }
        let loaded: boolean = true;
        let enabled: boolean = false;
        let count: number = 0;
        let allCategoriesList: Array<string> = [];
        allSources.forEach((item) => {
            if (item && item.enabled) {
                allCategoriesList.push(item.name);
                loaded = (item.loaded || false) && loaded;
                count = (item.count || 0) + count;
                if (item.enabled && categoryName === undefined) {
                    categoryName = item.name;
                }
            }
            enabled = enabled || item.enabled;
        });
        return {
            loaded: loaded,
            count: count,
            categoryName: categoryName,
            enabled: enabled,
            allCategoriesList: allCategoriesList,
        };
    };

    handleShowSnapshot: () => void = () => {
        let popupModel: PopupModelType = new PopupModel.Builder(MODAL_TYPE.SHOW_SNAPSHOT)
            .setPopupProperty('isVisible', true)
            .build();
        reduxStore.dispatch(popupModelActions.setPopupModel(popupModel));
    };

    onBillingEvent: (billingId: string, costCode: string) => void = (billingId, costCode) => {
        MainSearchApi.billingForSearch({ billingId: billingId, costCode: costCode });
    };

    getArticleInfo: () => Object = () => {
        let article = this.props.esgRatings && this.props.esgRatings.articles && this.props.esgRatings.articles[0];
        if (!article) {
            return;
        }
        const articleType = CATEGORY_NAMES.ESG_RATINGS;
        article = {
            ...article,
            reportSnippetType: articleType,
            articleType: articleType,
            index: 0, // used for pagination
        };
        const {
            proximity = '',
            includeTerms = [],
            terms = [],
            suggestedNames,
        } = this.props.searchResults[articleType].postFilters;
        const costCode = costCodeUtils.getCostCode();
        const researchSummary = ArticlesUtils.getReportSummary();
        const singleSource = utils.isSingleSourceSearch(this.props.postFiltersConfig[articleType]);

        const fuzzyThreshold = this.props.searchResults[articleType].postFilters[POST_FILTER_FUZZY_SEARCH]
            ? this.props.searchResults[articleType].postFilters[POST_FILTER_FUZZY_THRESHOLD]
            : '';
        const suggestedNamesPayload = suggestedNames
            ? utils.getFuzzyNameList(suggestedNames)
            : utils.getFuzzyNameList(this.props.suggestedNamesObject);

        return {
            index: article.index,
            article,
            list: [...this.props.esgRatings.articles],
            startPage: 0,
            pageSize: 50,
            totalCount: 1,
            params: {
                researchSummary,
                query: utils.sanitizeSearchStringInput(this.props.searchParams.query),
                category: articleType,
                searchType: this.props.searchParams.searchType,
                prefilterQuery: this.props.searchParams.prefilterQuery,
                proximity,
                includeTerms,
                costCode,
                singleSource,
                fuzzyThreshold,
                suggestedNamesPayload,
                terms,
            },
        };
    };

    onChartClick: () => void = () => {
        const {
            isSnapshotVisible,
            setSelectedArticle,
            location,
            searchResults,
            investigationEventsProviderProps,
            initArticleNavigation,
            esgRatings,
            investigation,
        } = this.props;
        const payload = this.getArticleInfo();

        if (payload) {
            setSelectedArticle(
                payload.article,
                payload && payload.params && payload.params.researchSummary,
                payload.params.query,
                payload.params.searchType
            );
            const categoryName = CATEGORY_NAMES.ESG_RATINGS;

            initArticleNavigation(payload);

            const categoryData = searchResults[categoryName];
            const { filteredFields } = location.query;

            if (categoryUtils.isVisitedCategory(categoryData, investigation)) {
                investigationEventsProviderProps.sendInvestigationEvent({
                    type: EVENT_SUBTYPES.revisited,
                    payload: { categoryData },
                });
            } else {
                investigationEventsProviderProps.sendInvestigationEvent({
                    type: ACTION_TYPES.loadDocuments,
                    payload: { categoryName, filteredFields, isSnapshotVisible },
                });
            }

            const costCode = costCodeUtils.getCostCode();
            const linkTo = `/document?query=${encodeURIComponent(payload.params.query)}&category=esgRatings&type=${
                payload.params.searchType
            }&id=${esgRatings.documentId}&costCode=${costCode}`;
            hashHistory.push(linkTo);
        }
    };

    onFullLoadedWithError: () => boolean = () => {
        let loaded: boolean = true;
        Object.keys(CATEGORY_NAMES).forEach((categoryName) => {
            let categoryKey = CATEGORY_NAMES[categoryName];
            if (utils.isCategoryEnabled(categoryKey) && this.props[categoryKey]) {
                if (!this.props[categoryKey].loaded) loaded = false;
            }
        });

        if (loaded) {
            const categoriesWithErrors = errorUtils.failedCategoriesCollector.getFlatList();
            if (categoriesWithErrors.length > 0) return true;
        }

        return false;
    };

    buildCategoryName: (categoryKey: string) => string = (categoryKey) => {
        let parentKey: string = categoryUtils.getParent(categoryKey);
        let defaultLabel: string = 'General.categoryName.label.';

        switch (parentKey) {
            case CATEGORY_NAMES.NEGATIVE_NEWS:
            case CATEGORY_NAMES.LAW_SOURCES:
                return `${formatRichMessage({ id: defaultLabel + parentKey }, this.props.intl)} - ${formatRichMessage(
                    { id: defaultLabel + categoryKey },
                    this.props.intl
                )}`;
            case CATEGORY_NAMES.CUSTOM_NEWS:
                let customNewsTitle = utils.translateTitleForCustomNews(categoryKey);

                customNewsTitle = customNewsTitle.shouldBeTranslated
                    ? formatRichMessage(customNewsTitle.titleContent, this.props.intl)
                    : customNewsTitle.titleContent;

                if (customNewsTitle[0] === '') {
                    return `${formatRichMessage({ id: defaultLabel + parentKey }, this.props.intl)}`;
                }
                return `${formatRichMessage({ id: defaultLabel + parentKey }, this.props.intl)} - ${customNewsTitle}`;

            default:
                return `${formatRichMessage({ id: defaultLabel + categoryKey }, this.props.intl)}`;
        }
    };

    sortCategoriesBasedOnOrder: (categories: Array<string>) => Array<string> = (categories) => {
        if (categories && categories.length > 0) {
            categories.sort((a, b) => {
                let categoryA = categoryUtils.getParent(a) || a;
                let categoryB = categoryUtils.getParent(b) || b;

                return CATEGORIES[categoryA]?.order - CATEGORIES[categoryB]?.order;
            });
        }

        return categories;
    };

    formatErrorsMessages: () => Object = () => {
        const invalidQueryErrors = this.sortCategoriesBasedOnOrder(
            errorUtils.failedCategoriesCollector.getByErrorCode(ERROR_CODES.INVALID_QUERY_STRING)
        );
        const internalServerErrors = this.sortCategoriesBasedOnOrder(
            errorUtils.failedCategoriesCollector.getByErrorCode(ERROR_CODES.INTERNAL_SERVER_ERROR)
        );
        const allAvailableCategories = Object.keys(this.props.searchResults).filter(
            (categoryName) => utils.isCategoryEnabled(categoryName) && !categoryUtils.getParent(categoryName)
        );

        let internalErrorTerm = null,
            invalidQueryTerm = null;

        if (internalServerErrors && internalServerErrors.length) {
            let translatedKeys = internalServerErrors.map((categoryKey) => this.buildCategoryName(categoryKey));

            internalErrorTerm =
                internalServerErrors.length === allAvailableCategories.length
                    ? formatRichMessage({ id: 'ResultsSnapshot.internalServerErrorForAllCategories' }, this.props.intl)
                    : formatRichMessage({ id: 'ResultsSnapshot.internalServerError' }, this.props.intl, {
                          categories: translatedKeys.join(', '),
                      });
        }

        if (invalidQueryErrors && invalidQueryErrors.length) {
            let formattedCategories = invalidQueryErrors.map((categoryKey) => this.buildCategoryName(categoryKey));
            invalidQueryTerm = formatRichMessage({ id: 'ResultsSnapshot.invalidNewsQuery' }, this.props.intl, {
                categories: formattedCategories.join(', '),
            });
        }

        return {
            internalError: internalErrorTerm,
            invalidQuery: invalidQueryTerm,
        };
    };

    hasFiltersApplied: () => boolean | typeof undefined = () => {
        const { searchResults } = this.props;
        if (searchResults) {
            return Object.values(searchResults).some((categoryData: Object) => categoryData.hasFiltersApplied);
        }
    };

    handleFiltersReset: () => void = () => {
        const { searchState } = this.props;
        const { launchedFrom } = searchState;
        launchedFrom === LAUNCHED_SEARCH_FROM.SCREENING_PAGE
            ? this.resetSearchFilters()
            : this.resetAdHocSearchFilters();
    };

    resetSearchFilters: () => void = () => {
        const { searchState, searchParams } = this.props;

        reduxStore.dispatch(
            searchSagaActions.runSearch({
                searchQuery: searchParams.query,
                prefilterQuery: searchParams.prefilterQuery,
                launchedFrom: LAUNCHED_SEARCH_FROM.SCREENING_PAGE,
                entityId: searchState.entityId,
                checkPreferencesAreObsolete: () => null,
                shouldNavigate: false,
            })
        );
    };

    resetAdHocSearchFilters: () => void = () => {
        searchUtils.getArticlesCounts(
            {
                searchQuery: this.props.searchParams.query,
                searchQueryType: this.props.searchParams.searchType,
                prefilterQuery: this.props.searchParams.prefilterQuery,
                countOnly: true,
                preloadCategory: null,
                useNewResearchSummary: this.props.useNewResearchSummary,
                filtersResetSearchPerformed: true,
            },
            {
                checkPreferencesAreObsolete: this.props.checkPreferencesAreObsolete,
                isSnapshotEnabled: this.props.isSnapshotVisible,
            },
            false,
            this.props.reduxStoreState,
        );

        if (utils.isUboEnabled()) {
            // submit empty ubo when searching from header, basically removing the list of duns
            reduxStore.dispatch(uboActions.submit({ selected: null }));
        }
    };

    openSaveSearchPopup: () => void = () => {
        const dataForPayload = this.getDataFromProps();
        const requestPayload = searchUtils.computePayload(dataForPayload);
        const { searchState } = this.props;

        const data = {
            popupType: MODAL_TYPE.SAVE_SEARCH,
            acceptAction: () => searchUtils.saveSearch(searchState.entityId, requestPayload),
        };

        this.openPopup(data);
    };

    addEntityToEntityView: () => void = () => {
        const dataForPayload = this.getDataFromProps();
        const requestPayload = searchUtils.buildPayloadForCreatingEntity(dataForPayload);

        searchUtils.createEntityFromSearch(requestPayload);
    };

    openPopup = (data) => {
        const popupModel: PopupModelType = new PopupModel.Builder(data.popupType)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('acceptAction', data.acceptAction)
            .build();

        reduxStore.dispatch(popupModelActions.setPopupModel(popupModel));
    };

    getDataFromProps() {
        return {
            user: this.props.user,
            investigation: this.props.investigation,
            useNewResearchSummary: this.props.useNewResearchSummary,
            contentTypes: this.props.contentTypes,
            searchResults: this.props.searchResults,
            postFiltersConfig: this.props.postFiltersConfig,
            searchParams: this.props.searchParams,
            popupModel: this.props.popupModel,
            viewId: this.props.viewId,
        };
    }

    setDisplayRiskScores(riskScore) {
        const { updateRiskScores } = this.props;
        updateRiskScores(riskScore);
        userPreferencesUtils.saveRiskScores(riskScore);
    }

    displayRiskScoresPopup() {
        const { popupModel, setPopupModel, searchType, language } = this.props;

        if (popupModel && popupModel.popupType !== MODAL_TYPE.APPLY_RISK_SCORES_PREFERENCE) {
            let popupModel = new PopupModel.Builder(MODAL_TYPE.APPLY_RISK_SCORES_PREFERENCE)
                .setPopupProperty('isVisible', true)
                .setPopupProperty('acceptAction', () => this.setDisplayRiskScores(DISPLAY_RISK_SCORES.SHOW))
                .setPopupProperty('rejectAction', () => this.setDisplayRiskScores(DISPLAY_RISK_SCORES.HIDE))
                .setPopupProperty('searchQueryType', searchType)
                .setPopupProperty('userLanguage', language)
                .build();

            setPopupModel(popupModel);
        }
    }

    createPcsiCountMap(documents) {
        return documents.reduce((acc, document) => {
            acc[document.pcsi] = (acc[document.pcsi] || 0) + 1;
            return acc;
        }, {});
    };

    createBusEvents(pcsiCountMap){
        return Object.entries(pcsiCountMap).map(([key, value]) => ({
            contentComponentId: key,
            quantity: value
        }));
    };

    handleFeatureFunctionAccess(documents){
        const pcsiCountMap = this.createPcsiCountMap(documents);
        const busEvents = this.createBusEvents(pcsiCountMap);

        const featureAccessEvent = {
            busEventChild: busEvents
        };

        MainSearchApi.sendFeatureFunctionAccessEvent(featureAccessEvent);
    };

    render(): React.Node {
        const {
            isBatchReportsEnabled,
            isSavingSearch,
            isAddingToEntityView,
            searchState: { launchedFrom },
        } = this.props;
        let fuzzyNames =
            this.props.fuzzyNames === null ? [] : this.props.fuzzyNames.list.filter((fuzzy) => fuzzy.selected);
        let suggestedNames = [];
        if (this.props.suggestedNames && this.props.suggestedNames.list) {
            suggestedNames = this.props.suggestedNames.list.filter((suggestedName) => suggestedName.selected);
        }

        const legalSubcategory: string | null = utils.isCategoryEnabled(CATEGORY_NAMES.LAW_SOURCES)
            ? utils.getLegalSubCategory()
            : null;

        let postFilterNewsSourcesDateRange: string = this.props.news.postFilters.dateRange;
        let postFilterCompanySourcesDateRange: string = this.props.directors.postFilters.dateRange;
        let postFilterLegalSourcesDateRange: string = this.props.legal.postFilters.dateRange;
        let postFilterNegativeNewsSourcesDateRange: string = launchedFrom === LAUNCHED_SEARCH_FROM.SCREENING_PAGE ? this.props.news.postFilters.dateRange : this.props.searchResults.negativeNewsEn.postFilters.dateRange;

        let newsDateRange: string = postFilterNewsSourcesDateRange
            ? postFilterNewsSourcesDateRange
            : this.props.adHocNewsDateRange
            ? this.props.adHocNewsDateRange.range
            : this.props.generalSettings.newsSources.dateRange;
        let negativeNewsDateRange: string = postFilterNegativeNewsSourcesDateRange
            ? postFilterNegativeNewsSourcesDateRange
            : this.props.adHocNewsDateRange
            ? this.props.adHocNewsDateRange.range
            : this.props.generalSettings.newsSources.dateRange;
        let companyDateRange: string = postFilterCompanySourcesDateRange
            ? postFilterCompanySourcesDateRange
            : this.props.adHocCompanyDateRange
            ? this.props.adHocCompanyDateRange.range
            : this.props.generalSettings.companySources.dateRange;
        let legalDateRange: string = postFilterLegalSourcesDateRange
            ? postFilterLegalSourcesDateRange
            : this.props.adHocLegalDateRange
            ? this.props.adHocLegalDateRange.range
            : this.props.generalSettings.legalSources.dateRange;

        let snapshotStyle = this.state.top && {
            top: this.state.top,
        };

        const areDownloadButtonsDisabled =
            !this.props.isSearchComplete ||
            !areAllCategoriesLoaded(this.props.searchResults) ||
            (this.props.backgroundMessages &&
                this.props.backgroundMessages.isVisible &&
                this.props.backgroundMessages.title === null);

        const matomoTrackingPropertiesDownloadSnapshotClick = {
            'data-gm-u-pn': 'nexisDiligence_snapshot',
            'data-gm-u-c': 'downloadButton',
            'data-gm-u-cc': 'downloadSnapshot',
            'data-gm-u-e': 'delivery_download',
            'data-gm-u-v': 'download',
            'data-gm-u-a': 'click',
        };

        const matomoTrackingPropertiesDownloadFullReportClick = {
            ...matomoTrackingPropertiesDownloadSnapshotClick,
            'data-gm-u-cc': 'downloadFullReport',
        };

        const shouldDisplaySaveSearchButton = utils.shouldDisplaySaveSearchButton(
            isBatchReportsEnabled,
            launchedFrom,
            LAUNCHED_SEARCH_FROM.SNAPSHOT_PAGE
        );

        const isLoading = isAddingToEntityView || isSavingSearch;

        const hasAcceptedRiskScores = this.props.displayRiskScores === DISPLAY_RISK_SCORES.SHOW;

        const searchTerm = utils.mergeQueryAndBoolean(
            utils.sanitizeSearchStringInput(this.props.searchParams.query),
            this.props.searchParams.prefilterQuery
        );

        let mergedQuery = formatRichMessage({ id: 'ResultsSnapshot.resultsForSearch' }, this.props.intl, {
            searchTerm,
        });

        const SearchTermTooltipContent = ReactDOMServer.renderToString(
            <div className="searchTerm-tooltip">
                <p>{searchTerm}</p>
            </div>
        );

        const isSearchTermEllipsized =
            this.searchTermContainer && this.searchTermContainer.scrollWidth > this.searchTermContainer.clientWidth;

        const betaText = formatRichMessage({ id: 'Marketing_overlay.Diligence_plus_beta_text' }, this.props.intl);

        const {
            documents,
            sendFeatureFunctionAccessEvent,
            count,
            riskLevel
        } = this.props.sanctionsRisk || {};

        if (sendFeatureFunctionAccessEvent === false && shouldSendFeatureFunctionAccessEvent) {
            this.handleFeatureFunctionAccess(documents);
            const newSanctionsRiskData = {
                count: count,
                riskLevel: riskLevel,
                documents: documents,
                sendFeatureFunctionAccessEvent: true,
            };

            this.props.updateSanctionsRisk(newSanctionsRiskData);
        }

        return (
            <div className="snapshots-wrapper notranslate" ref={(ref: Object) => (this.wrapper = ref)}>
                {isLoading && <OverlaySpinner className="category-overlay" size="massive" />}
                {isSearchTermEllipsized && (
                    <ReactTooltip
                        event="mouseenter"
                        eventOff="mouseleave"
                        type="light"
                        border={true}
                        effect="solid"
                        place="bottom"
                        multiline={true}
                        offset={{ left: 0, right: 20 }}
                        html={true}
                        className="tooltips"
                        id="searchTerm-tooltip"
                    />
                )}

                <div className="snapshot-show-hide" style={snapshotStyle}>
                    <div className="user-preference-checkbox">
                        <input
                            id={'user-preferences-show-snapshot'}
                            type="checkbox"
                            title={'Show Snapshot results view'}
                            checked={this.props.showSnapshot !== true ? 'checked' : ''}
                            onChange={this.handleShowSnapshot}
                        />
                        <label htmlFor={'user-preferences-show-snapshot'} className="preference-checkbox-label"></label>
                    </div>
                    <span className="snapshot-show-hide-text">
                        <FormattedMessage id={'UserPreferences_hide_snapshot'} />
                    </span>
                </div>
                <div className="count-pods-container">
                    <div className="count-pods-header">
                        <div className="count-pods-header-results">
                            <div className="count-pods-header-text-container">
                                <h1
                                    ref={(ref) => (this.searchTermContainer = ref)}
                                    className="count-pods-header-results-for"
                                    data-track="count-pods-header-results-for"
                                    data-tip={SearchTermTooltipContent}
                                    data-for="searchTerm-tooltip"
                                    dangerouslySetInnerHTML={{ __html: mergedQuery }}
                                />
                                <div className="risk-scores-disclaimer">
                                    {hasAcceptedRiskScores ? (
                                        <div
                                            data-testid="hide-risk-scores"
                                            onClick={() => this.setDisplayRiskScores(DISPLAY_RISK_SCORES.HIDE)}
                                        >
                                            <FormattedMessage id="RiskScores.label.hideScores" />
                                        </div>
                                    ) : (
                                        <div
                                            data-testid="show-risk-scores"
                                            onClick={() => this.displayRiskScoresPopup()}
                                        >
                                            <FormattedMessage id="RiskScores.label.acceptScores" />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="snapshot-action-buttons-wrapper">
                                {isBatchReportsEnabled && (
                                    <AddToEntityViewButton
                                        onClickHandler={this.addEntityToEntityView}
                                        isButtonDisabled={areDownloadButtonsDisabled}
                                    />
                                )}
                                {shouldDisplaySaveSearchButton && (
                                    <SaveSearchButton
                                        onClickHandler={this.openSaveSearchPopup}
                                        isButtonDisabled={areDownloadButtonsDisabled}
                                        isSavingSearch={isSavingSearch}
                                    />
                                )}
                                {this.props.useSnapshotDelivery && (
                                    <button
                                        className="button-primary-lg"
                                        id="download-snapshot-button"
                                        data-track="snapshot-page-download-snapshot-button"
                                        disabled={areDownloadButtonsDisabled}
                                        onClick={this.downloadSnapshot}
                                        {...matomoTrackingPropertiesDownloadSnapshotClick}
                                    >
                                        {this.state.all.count === 0 ? (
                                            <FormattedMessage id="ResultsSnapshot.noResultsForDownloadSnapshot" />
                                        ) : (
                                            <FormattedMessage id="ResultsSnapshot.downloadSnapshot" />
                                        )}
                                    </button>
                                )}
                                {this.state.all.enabled && (
                                    <button
                                        className="button-primary-lg"
                                        id="qa-download-full-report-button"
                                        data-track="snapshot-page-download-full-report-button"
                                        disabled={areDownloadButtonsDisabled}
                                        value="all"
                                        onClick={this.downloadResultList}
                                        {...matomoTrackingPropertiesDownloadFullReportClick}
                                    >
                                        {this.state.all.count === 0 ? (
                                            <FormattedMessage id="ResultsSnapshot.noResultsForDownload" />
                                        ) : (
                                            <FormattedMessage id="ResultsSnapshot.downloadFullReport" />
                                        )}
                                    </button>
                                )}
                            </div>
                        </div>
                        {this.props.isFuzzyEnabled || this.props.searchParams.isCustomFuzzy ? (
                            <div
                                className="count-pods-header-fuzzy-names"
                                ref={(ref) => (this.pillListContainer = ref)}
                            >
                                {fuzzyNames.length > 0 && (
                                    <div
                                        className="count-pods-header-fuzzy-names-label"
                                        ref={(ref) => (this.pillListHeader = ref)}
                                    >
                                        <FormattedMessage id={'CompanySnapshot.similarNames'} />
                                    </div>
                                )}
                                {this.props.isFuzzyLoaded && (
                                    <PillList
                                        items={fuzzyNames}
                                        headerWidth={this.state.pillListHeaderWidth}
                                        pillListContainerWidth={this.state.pillListContainerWidth}
                                        pillListHeaderWidth={this.state.pillListHeaderWidth}
                                    />
                                )}
                                {this.props.isFuzzyLoaded === false &&
                                    this.props.searchParams.searchType === PERSON_SEARCH && (
                                        <LoadingPillList
                                            pillListContainerWidth={this.state.pillListContainerWidth}
                                            pillListHeaderWidth={this.state.pillListHeaderWidth}
                                            headerWidth={this.state.pillListHeaderWidth}
                                        />
                                    )}
                            </div>
                        ) : null}

                        {
                            // suggestedNamesFeatureEnabled prop - consul flag for turning on/off suggestedNames feature. will be removed on cleanup
                            this.props.suggestedNamesFeatureEnabled &&
                            this.props.isSuggestedNamesEnabled &&
                            this.props.searchParams.searchType === PERSON_SEARCH ? (
                                <div
                                    className="count-pods-header-suggested-names"
                                    ref={(ref) => (this.suggestedNamesPillListContainer = ref)}
                                >
                                    {suggestedNames.length > 0 && (
                                        <div
                                            className="count-pods-header-suggested-names-label"
                                            ref={(ref) => (this.suggestedNamesPillListHeader = ref)}
                                        >
                                            <FormattedMessage id={'Snapshot.suggestedNames'} />
                                        </div>
                                    )}
                                    <PillList
                                        items={suggestedNames}
                                        headerWidth={this.state.suggestedNamesPillListHeaderWidth}
                                        suggestedNamesPillListContainerWidth={
                                            this.state.suggestedNamesPillListContainerWidth
                                        }
                                        suggestedNamesPillListHeaderWidth={this.state.suggestedNamesPillListHeaderWidth}
                                    />
                                </div>
                            ) : null
                        }
                    </div>
                    <div className="count-pods-body">
                        {this.onFullLoadedWithError() && (
                            <ResultsSnapshotError
                                internalServerError={this.formatErrorsMessages().internalError}
                                invalidQueryError={this.formatErrorsMessages().invalidQuery}
                            />
                        )}
                        {this.hasFiltersApplied() && (
                            <div className={`embedded-message informative ${this.props.isDarkMode ? 'dark-mode' : ''}`}>
                                <div className="embedded-message__icon">
                                {this.props.isDarkMode ? <BannerInfoIconDark /> : <BannerInfoIcon />}
                                </div>
                                <div className="embedded-message__message">
                                    <FormattedMessage id={'ResultsSnapshot.filtersApplied'} />
                                </div>
                                &nbsp;
                                <div className="embedded-message__link">
                                    <a onClick={this.handleFiltersReset}>
                                        <FormattedMessage id={'ResultsSnapshot.resetAppliedFilters'} />
                                    </a>
                                </div>
                            </div>
                        )}
                        {utils.isCategoryEnabled(CATEGORY_NAMES.NEGATIVE_NEWS) && (
                            <CountPods
                                searchResults={this.props.searchResults}
                                loading={this.props.negativeNews.loaded}
                                value={this.props.negativeNews.count}
                                title={CATEGORY_NAMES.NEGATIVE_NEWS}
                                dateRange={negativeNewsDateRange}
                                downloadResultList={this.downloadResultList}
                                location={this.props.location.pathname + this.props.location.search}
                                onBillingEvent={this.onBillingEvent}
                                categoryName={CATEGORY_NAMES.NEGATIVE_NEWS}
                                categoryLocation={this.props.location}
                                hasFeedbackLink={true}
                            />
                        )}
                        {utils.isCategoryEnabled(CATEGORY_NAMES.SANCTIONS_WATCHLIST) && (
                            <CountPods
                                loading={this.props.sanctions.loaded || this.props.sanctionsRisk?.documents.length}
                                value={this.props.sanctions.count || this.props.sanctionsRisk?.count}
                                title={CATEGORY_NAMES.SANCTIONS_WATCHLIST}
                                location={this.props.location.pathname + this.props.location.search}
                                downloadResultList={this.downloadResultList}
                                onBillingEvent={this.onBillingEvent}
                                categoryName={CATEGORY_NAMES.SANCTIONS_WATCHLIST}
                                tagLabel={betaText}
                                isNew={this.props.sanctionsAndWatchListsSnapshot}
                                hasTag={this.props.sanctionsAndWatchListsSnapshot && this.props.displayRiskScores !== DISPLAY_RISK_SCORES.HIDE}
                            />
                        )}
                        {utils.isCategoryEnabled(CATEGORY_NAMES.NEWS) && (
                            <CountPods
                                loading={this.props.news.loaded}
                                value={this.props.news.count}
                                title={CATEGORY_NAMES.NEWS}
                                dateRange={newsDateRange}
                                downloadResultList={this.downloadResultList}
                                location={this.props.location.pathname + this.props.location.search}
                                onBillingEvent={this.onBillingEvent}
                                categoryName={CATEGORY_NAMES.NEWS}
                            />
                        )}
                        {utils.isCategoryEnabled(CATEGORY_NAMES.CUSTOM_NEWS) && (
                            <CountPods
                                loading={this.props.customNews.loaded}
                                value={this.props.customNews.count}
                                title={CATEGORY_NAMES.CUSTOM_NEWS}
                                dateRange={newsDateRange}
                                downloadResultList={this.downloadResultList}
                                location={this.props.location.pathname + this.props.location.search}
                                onBillingEvent={this.onBillingEvent}
                                categoryName={CATEGORY_NAMES.CUSTOM_NEWS}
                            />
                        )}

                        {utils.isCategoryEnabled(CATEGORY_NAMES.ESG_RATINGS) && this.props.showEsgRatings && (
                            <CountPods
                                loading={this.props.esgRatings.loaded}
                                value={this.props.esgRatings.count}
                                overallRating={this.props.esgRatings.overallRating}
                                ratingRange={this.props.esgRatings.range}
                                onChartClick={this.onChartClick}
                                title={CATEGORY_NAMES.ESG_RATINGS}
                                downloadResultList={this.downloadResultList}
                                location={this.props.location.pathname + this.props.location.search}
                                onBillingEvent={this.onBillingEvent}
                                categoryName={CATEGORY_NAMES.ESG_RATINGS}
                            />
                        )}
                        {utils.isCategoryEnabled(CATEGORY_NAMES.DNB) && (
                            <CountPods
                                loading={this.props[CATEGORY_NAMES.DNB].loaded}
                                value={this.props[CATEGORY_NAMES.DNB].count}
                                hasError={this.props[CATEGORY_NAMES.DNB].hasError}
                                title={CATEGORY_NAMES.DNB}
                                downloadResultList={this.downloadResultList}
                                location={this.props.location.pathname + this.props.location.search}
                                categoryName={CATEGORY_NAMES.DNB}
                            />
                        )}
                        {utils.isCategoryEnabled(CATEGORY_NAMES.COMPANY_RESOURCES) && (
                            <CountPods
                                loading={this.props.directors.loaded}
                                value={this.props.directors.count}
                                title={CATEGORY_NAMES.COMPANY_RESOURCES}
                                dateRange={companyDateRange}
                                downloadResultList={this.downloadResultList}
                                location={this.props.location.pathname + this.props.location.search}
                                onBillingEvent={this.onBillingEvent}
                                categoryName={CATEGORY_NAMES.COMPANY_RESOURCES}
                            />
                        )}
                        {utils.isCategoryEnabled(CATEGORY_NAMES.FINANCIAL_REPORT) &&
                            this.props.searchParams.searchType !== PERSON_SEARCH && (
                                <CountPods
                                    loading={this.props.financialReports.loaded}
                                    value={this.props.financialReports.count}
                                    title={CATEGORY_NAMES.FINANCIAL_REPORT}
                                    location={this.props.location.pathname + this.props.location.search}
                                    downloadResultList={this.downloadResultList}
                                    onBillingEvent={this.onBillingEvent}
                                    categoryName={CATEGORY_NAMES.FINANCIAL_REPORT}
                                />
                            )}
                        {utils.isCategoryEnabled(CATEGORY_NAMES.PEPS) && (
                            <CountPods
                                loading={this.props.peps.loaded}
                                value={this.props.peps.count}
                                title={CATEGORY_NAMES.PEPS}
                                downloadResultList={this.downloadResultList}
                                location={this.props.location.pathname + this.props.location.search}
                                onBillingEvent={this.onBillingEvent}
                                categoryName={CATEGORY_NAMES.PEPS}
                            />
                        )}
                        {utils.isCategoryEnabled(CATEGORY_NAMES.BIOGRAPHICAL) && (
                            <CountPods
                                loading={this.props.biographical.loaded}
                                value={this.props.biographical.count}
                                title={CATEGORY_NAMES.BIOGRAPHICAL}
                                downloadResultList={this.downloadResultList}
                                location={this.props.location.pathname + this.props.location.search}
                                onBillingEvent={this.onBillingEvent}
                                categoryName={CATEGORY_NAMES.BIOGRAPHICAL}
                            />
                        )}
                        {utils.isCategoryEnabled(CATEGORY_NAMES.LAW_SOURCES) && (
                            <CountPods
                                loading={this.props.lawSources.loaded}
                                value={this.props.lawSources.count}
                                title={CATEGORY_NAMES.LAW_SOURCES}
                                dateRange={legalDateRange}
                                downloadResultList={this.downloadResultList}
                                location={this.props.location.pathname + this.props.location.search}
                                onBillingEvent={this.onBillingEvent}
                                categoryName={legalSubcategory}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    contentTypes: state.user.preferences.generalSettings.contentTypes,
    useNewResearchSummary: state.user.useNewResearchSummary,
    useSnapshotDelivery: state.user.useSnapshotDelivery,
    negativeNewsVisualisation: state.negativeNewsVisualisation,
    user: state.user,
    viewId: state.user.preferences.screeningEntity.lastSelectedView,
    investigation: state.investigation,
    isSearchComplete: state.searchStatus.isSearchComplete,
    backgroundMessages: state.backgroundMessages,
    searchResults: state.searchResults,
    searchParams: state.searchParams,
    searchState: state.searchState,
    isSnapshotVisible: state.user.preferences.generalSettings.showSnapshot,
    displayRiskScores: state.user.preferences.generalSettings.displayRiskScores,
    showEsgRatings: state.user.showEsgRatings,
    popupModel: state.popupModel,
    prevPath: state.breadcrumbs.prevPath,
    isBatchReportsEnabled: state.user.appSettings.isBatchReportsEnabled,
    isSavingSearch: state.searchStatus.isSavingSearch,
    isAddingToEntityView: state.searchStatus.isAddingToEntityView,
    postFiltersConfig: state.postFilterConfiguration,
    language: state.user.preferences.language,
    negativeNews: state.searchResults.negativeNews,
    sanctionsAndWatchListsSnapshot: state.user.sanctionsAndWatchListsSnapshot,
    sanctionsRisk: state.sanctionsRisk,
    reduxStoreState: state,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            uboInit: uboActions.init,
            clearPopup: popupModelActions.clearPopup,
            setSelectedArticle: articlesManagerActions.selectArticle,
            resetInvestigation: investigationActions.resetInvestigation,
            resetQueryParams: searchParamsActions.resetQueryParams,
            setCategory: searchParamsActions.setCategory,
            initArticleNavigation: ArticleNavigationActions.initArticleNavigation,
            searchDocAccessEvent: ArticleNavigationActions.searchDocAccessEvent,
            updatePrevPath: breadcrumbsActions.updatePrevPath,
            setPopupModel: popupModelActions.setPopupModel,
            updateRiskScores: actions.updateRiskScores,
            updateSanctionsRisk: (sanctionsRisk) => dispatch(sanctionsRiskActions.updateSanctionsRisk(sanctionsRisk)),
        },
        dispatch
    );

export default (compose(
    connect(mapStateToProps, mapDispatchToProps),
    withAppContext,
    injectIntl,
    withInvestigationEvents(InvestigationEventsProvider),
    withPreferenceRefresh,
    withThemeSwitcherContext
)(ResultsSnapshot): React.AbstractComponent<Props, State>);
export { ResultsSnapshot as TestResultsSnapshot };
