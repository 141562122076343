import React from "react";
import {withDragAndDrop} from "@reusable/HOC/withDragAndDrop";
import {DRAG_AND_DROP} from "@constants";
import PropTypes from "prop-types";

const ManageColumnsItem = ({
    contentType,
    shouldContentTypeBeDisabled,
    index,
    updateElementsVisibility,
    isDragging,
    showDropTarget,
    insertAbove,
    droppedContentTypeName,
    connectDragSource,
    connectDropTarget
}) => {
    const { enabledInGeneralSettings, columnId, hidden, label } = contentType;

    let showDroppedAnimation = droppedContentTypeName === contentType.columnId;

    const isDraggable = !shouldContentTypeBeDisabled && !hidden;

    const handleItemClick = () => {
        if (!shouldContentTypeBeDisabled) {
            updateElementsVisibility({ target: { name: columnId, checked: hidden } });
        }
    }

    const ManageColumnsItemContent = (
        <div
            key={index}
            id={columnId}
            className={
                'custom-checkbox-container' +
                (isDragging ? ' dragging' : '') +
                (showDropTarget ? (insertAbove ? ' drop-top ' : ' drop-bottom ') : '') +
                (showDroppedAnimation ? ' dropped' : '')
            }
        >
            <div className={'draggable-icon' + (!isDraggable ? ' disabled' : '')}/>
            <div className='custom-checkbox' onClick={handleItemClick}>
                <input
                    id={`${columnId}`}
                    disabled={shouldContentTypeBeDisabled}
                    type='checkbox'
                    name={columnId}
                    checked={!hidden || !enabledInGeneralSettings}
                    onChange={updateElementsVisibility}
                    data-testid={`checkbox-${columnId}-${
                        hidden || !enabledInGeneralSettings ? 'hidden' : 'unhidden'
                    }-testid`}
                />
                <label htmlFor={`${columnId}`} className='checkbox-label'>
                    {label}
                </label>
            </div>
        </div>
    )

    if (!isDraggable) {
        return ManageColumnsItemContent;
    } else {
        return (connectDragSource(connectDropTarget(ManageColumnsItemContent)));
    }
}

const dndHookInfo = {
    componentType: 'child',
    accept: DRAG_AND_DROP.CONTENT_TYPE,
};

ManageColumnsItem.propTypes = {
    contentType: PropTypes.shape({
        enabledInGeneralSettings: PropTypes.bool.isRequired,
        columnId: PropTypes.string.isRequired,
        hidden: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired
    }).isRequired,
    shouldContentTypeBeDisabled: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    updateElementsVisibility: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    showDropTarget: PropTypes.bool.isRequired,
    insertAbove: PropTypes.bool.isRequired,
    droppedContentTypeName: PropTypes.string,
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired
}

export default withDragAndDrop(dndHookInfo)(ManageColumnsItem);