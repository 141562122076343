import React from "react";
import SortComponent from "@reusable/SortComponent/SortComponent";

const HeaderContainer = ({handleSortClick, columnKey, FormattedMessage, sortInfo}) => {
    return (
        <span className="entity-view-header-container" onClick={() => handleSortClick(columnKey)}>
            <div className="header-text">
                {FormattedMessage}
            </div>
            <SortComponent sortInfo={sortInfo} columnKey={columnKey}/>
        </span>
    )
}

export default HeaderContainer;
