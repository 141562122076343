const ellipsisStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

export const headerStyleObj = {
    ...ellipsisStyle,
    background: '#fff',
    paddingLeft: '0.5rem',
    border: {
        top: {
            width: '0.1rem',
        },
        bottom: {
            width: '0.1rem',
            style: 'solid',
            color: 'rgb(224, 224, 224)',
        },
        left: {
            width: '0',
        },
        right: {
            width: '0',
        },
    },
};

export const addTooltipIfOverflow = (element) => {
    if (element.offsetWidth < element.scrollWidth) {
        element.title = element.textContent;
    } else {
        element.title = '';
    }
};

export const loadingCellStyleObj = {
    background: '#fff',
    paddingLeft: '0',
    border: {
        top: {
            width: '0',
        },
        bottom: {
            width: '0',
        },
        left: {
            width: '0',
        },
        right: {
            width: '0',
        },
    },
};

export const emptyCellStyleObj = {
    background: '#fff',
    paddingLeft: 'calc(50% - 163px)',
    border: {
        top: {
            width: '0',
        },
        bottom: {
            width: '0',
        },
        left: {
            width: '0',
        },
        right: {
            width: '0',
        },
    },
};

export const cellStyleObj = (index) => ({
    background: index % 2 === 0 ? '#fff' : '#F0F3F5',
    paddingLeft: '1rem',
    border: {
        top: {
            width: '0',
        },
        bottom: {
            width: '0',
        },
        left: {
            width: '0',
        },
        right: {
            width: '0',
        },
    },
});