import React from 'react';

const NestedCheckSkeletonLoader = ({ classNames, itemCount }) => (
    <div className={`${classNames} nested-checkbox-group`}>
        <div className="skeleton-loading-wrapper">
            {[...Array(itemCount)].map((_, index) => (
                <div key={index} className="label-container">
                    <div className="label-text skeleton-loading filter-label-loading"></div>
                    <div className="filter-count skeleton-loading filter-count-loading"></div>
                </div>
            ))}
        </div>
    </div>
);

export default NestedCheckSkeletonLoader;