// @flow
import * as React from 'react';
import SearchTypeFilter from '@reusable/SearchTypeFilter/SearchTypeFilter';
import { hashHistory, withRouter } from 'react-router';
import ResultsListHeader from '@pages/MainSearch/components/ResultsListHeader';
import ActiveFilterList from '@pages/MainSearch/components/ActiveFilterList';
import ArticleActions from '@pages/MainSearch/components/ArticleActions';
import searchParamsActions from '@pages/MainSearch/redux/SearchParams.actions';
import {
    BREADCRUMBS,
    CALENDAR_DATE_FORMAT_BE,
    CATEGORY_NAMES,
    CATEGORY_TYPE_UBO_CHILD,
    CUSTOM_DATES_LABEL,
    DOC_TYPE_SEARCH_EVENT_REQUEST,
    DOCUMENT_VIEW_TYPE_PREVIEW,
    EDIT_ALERT_SEARCH_PAGE_TITLE,
    EXCLUDE,
    FILTER_BAR_DATE_RANGE_CUSTOM_LABEL,
    FILTER_INFO,
    FOOTER_HEIGHT,
    HEADER_AND_FOOTER_HEIGHT,
    HEADER_HEIGHT,
    INCLUDE,
    LOADED,
    NEGATIVE_NEWS_SELECTED_LANGUAGE,
    PAGE_NAMES,
    PERSON_SEARCH,
    POST_FILTER_DATE_RANGE,
    POST_FILTER_FUZZY_SEARCH,
    POST_FILTER_FUZZY_THRESHOLD,
    POST_FILTER_NEGATIVITY_LEVELS,
    POST_FILTER_TERMS,
    POST_FILTERS_DEFAULT_SORT,
    POSTFILTER_COMPONENT_TYPE,
    POSTFILTER_TYPE,
    PREFERENCES_KEY,
    REMOVE_ALL_INCLUDE_TERMS,
    RESULTS_PAGE_TITLE,
    TERMS,
    MODAL_TYPE,
    LAUNCHED_SEARCH_FROM,
    MATOMO,
    BREADCRUMBS_PREV_PATH,
    ROUTES,
    SAVE_SEARCH_LEAVE_ACTION,
} from '@constants';
import callMatomoEvent from '@utils/matomoUtils';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import breadcrumbsActions from '@reusable/Breadcrumbs/redux/Breadcrumbs.actions';
import searchUtils, {determineDateRange} from '@pages/MainSearch/SearchUtils';
import articlesManagerActions from '@pages/MainSearch/redux/ArticlesManager.actions';
import currentReportActions from '@pages/ReportBuilder/redux/CurrentReport.actions';
import scrollingPageActions from '@pages/Main/ScrollingPage.actions';
import ArticleNavigationActions from '@pages/MainSearch/redux/ArticleNavigation.actions';
import { cloneDeep, differenceBy, fill, find, values, isEqual, isEmpty, debounce } from 'lodash';
import ElasticDocumentsList from '@pages/MainSearch/components/ElasticDocumentsList';
import BreadCrumbsModel from '@utils/breadCrumbBuilder';
import ArticlesUtils from '@pages/MainSearch/ArticlesUtils';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import searchResultsActions from '@pages/MainSearch/redux/SearchResults.actions';
import OverlaySpinner from '@reusable/OverlaySpinner/OverlaySpinner';
import userPreferenceApi from '@pages/UserPreferances/UserPreferencesApi.api';
import userPreferencesActions from '@pages/UserPreferances/redux/UserPreferences.actions';
import postFilterConfigActions from '@pages/MainSearch/redux/PostFilterConfiguration.actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import categoryUtils, { withSearchResultsFilter } from '@utils/categoryUtils';
import reduxStore from '@reduxStore';
import { withAppContext } from '@utils/contexts';
import withSliderAnimation from '@reusable/Modal/hoc/withSliderAnimation';
import MainSearchApi from '@MainSearch/MainSearch.api';
import { AddToReportBtnElastic } from '@reusable/Buttons/redux/AddToReportBtn.containers';
import costCodeUtils from '@utils/costCodeUtils';
import { isUboCategory } from '@sagas/helpers/uboHelper';
import CustomDocumentsList from '@pages/MainSearch/components/CustomDocumentsList';
import * as investigationEvents from '@sagas/constants/investigationConstants';
import withPreferenceRefresh from '@pages/Main/hoc/withPreferenceRefresh';

import type {
    ArticleListType,
    SearchParamsType,
    SearchResultsType,
    PostFiltersConfigType,
    PreferencesType,
    ContentsOptionsType,
    UserLocksType,
    PersonCheckType,
    ContextType,
    CompanyCheckType,
} from '@pages/MainSearch/components/typeGuards/ResultsList.typeGuards';
import type { RouterType, RoutesType } from '@utils/flow/utilities.type.guards';
import type { AdHocSearchType } from '@reusable/AdHocSearch/flow/AdHocSearch.typeGuards';
import { EVENT_TYPES, ACTION_TYPES } from '@sagas/constants/investigationConstants';
import userPreferencesUtils from '@utils/userPreferencesUtils';
import articleNavigationActions from '@pages/MainSearch/redux/ArticleNavigation.actions';
import ConfirmationModal from '@reusable/ConfirmationModal/ConfirmationModal';
import withHistoryEvents from '@pages/MainSearch/components/hoc/WithHistoryEvents';
import HistoryEventsProvider from '@pages/MainSearch/components/providers/HistoryEventsProvider';
import withInvestigationEvents from '@pages/MainSearch/components/hoc/WithInvestigationEvents';
import InvestigationEventsProvider from '@pages/MainSearch/components/providers/InvestigationEventsProvider';
import investigationActions from '@pages/MainSearch/redux/Investigation.actions';
import { searchStatusActions } from '@pages/MainSearch/redux/SearchStatus.action';
import AlertsApi from '@ManageAlerts/ManageAlertsApi';
import manageAlertsActions from '@ManageAlerts/redux/ManageAlerts.actions';
import PopupModel from '@reusable/PopupModal/PopupBuilder';
import popupModelActions from '@reusable/PopupModal/redux/PopupModel.actions';
import mainActions from '@pages/Main/Main.actions';
import DilQueue from '@utils/promiseQueue';
import commonApi from '@api/Common.api';

function hasChangedAfterCategoryLoaded(props) {
    let languageLastUpdated = props.preferences.languageLastUpdated;
    let categoryLastUpdated = props.searchResults[props.articleType].categoryLastUpdated;

    return languageLastUpdated && languageLastUpdated > categoryLastUpdated;
}

type State = {
    visibleDuplicatesIds: Array<Object>,
    showDuplicatesState: Object,
    isOpenLegalCategory?: ?boolean,
    duplicates: Object,
    shouldUpdateCustomDatesState: boolean,
    showEditModal: boolean,
    confirmationModalTitle: string,
    confirmationModalDescription: string,
    showLeaveModal: boolean,
    leaveNextLocation: ?string,
};
type Option = {
    label: ?string,
    labelId: ?string,
    count: number | boolean,
    checked: boolean,
};

type Options = Array<Option>;

type Props = {
    articleType: string,
    articleList: ArticleListType,
    router: RouterType,
    params: Object,
    location: Object,
    routes: Array<RoutesType>,
    searchResults: SearchResultsType,
    isSearchComplete: boolean,
    searchParams: SearchParamsType,
    report: Object,
    uploadingDocuments: Object,
    selectedArticles: Object,
    previousScrollY: number,
    isSnapshotVisible: boolean,
    pageSize: number,
    sort: string,
    fuzzyNames: Array<string>,
    postFiltersConfig: PostFiltersConfigType,
    generalSettings: Object,
    language: string,
    adHocSearch: Object,
    preferences: PreferencesType,
    investigationId: string,
    investigation: Object,
    singleArticle: Object,
    historyCategory: Object,
    showFilters: boolean,
    prevPath: string,
    contentsOptions: ContentsOptionsType,
    showDeliveryOptions: boolean,
    preferencesNewsSource: string,
    personCheck: PersonCheckType,
    userLocks: UserLocksType,
    companyCheck: CompanyCheckType,
    newFuzzyEnabled: boolean,
    suggestedNamesEnabled: boolean,
    user: Object,
    clearSelectedDuplicates: (args: Object) => void,
    setScrollY: (arg1: string, arg2: number) => void,
    updateBreadcrumbs: (arg1: string) => void,
    setLastSort: (arg1: string, arg2: string) => void,
    setSortOption: (arg1: string, arg2: string) => void,
    clearCategoryTerms: (arg: string) => void,
    setSelectedArticle: (arg: Object, arg2: Object, arg3: string, arg4: string) => void,
    initArticleNavigation: (arg: Object) => void,
    setSelectedArticles: (arg: Object, arg2: string, arg3: boolean) => void,
    setSelectedDuplicates: (arg: Object, arg2: string, arg3: boolean) => void,
    clearSelectedArticles: (arg: Array<string>) => void,
    clearSelectedArticle: () => void,
    removeTerms: (includeTerm: string, filterType: string, category: string) => void,
    onSearchWhithinChange?: (category: string, filterType: string) => void,
    resetToDefaultAllPostFiltersConfigValues: (arg: string, arg3: PostFiltersConfigType) => void,
    resetAllPostFiltersToDefaultValues: (arg: string, arg1: PostFiltersConfigType) => void,
    resetAllFilters: (articleType: string) => void,
    updatePostFiltersSort: (articleType: string, filterType: string, arg3: string) => void,
    resetPostFilterConfigValues: (articleType: string, filterType: string, arg3: Array<string>) => void,
    onPostfilterChange: (articleType: string, filterType: string, onPostFilterChange?: Array<any>) => void,
    resetPostFilterToDefaultValues: (
        articleType: string,
        filterType: string,
        postFilterFieldName: string,
        arg3: Array<string>
    ) => void,
    updatePageSize: (newPageSize: number) => void,
    onSlideModal: (modalProps: Object, arg2: () => void) => void,
    onUpdateModalInfo: (modalProps: Object, arg2: () => void) => void,
    adHocNewsSource: AdHocSearchType,
    updateCategoryFlag: () => void,
    updatePostFilterForCategory: (filterName: string, dateRange: string, negativityLevels: Array<string>) => void,
    updatePostFilterConfigValue: () => void,
    updatePostFilterConfig: (categoryName: string, dateRange: string, configDateRangeValues: Options) => void,
    addTerms: () => void,
    refreshPreferences: (payload: Object, updatedByAdmin: boolean) => void,
    useNegativeNewsVisualisations: boolean,
    isTrendsExpanded: boolean,
    searchDocAccessEvent: (args?: Object) => void,
    markCategoryAsVisited: (categoryName: string) => void,
    checkPreferencesAreObsolete: () => void,
    setFiltersAppliedForCategory: (articleType: string, hasFilterApplied: Function) => void,
    personNegativityLevel: Array<string>,
    companyNegativityLevel: Array<string>,
    areDocumentsLoading: boolean,
    isFilterSectionCollapsed: boolean,
    useNewResearchSummary: boolean,
    suggestedNamesObject: {
        list: Array<string | null>,
        query: string,
    },
    billingId: ?string,
    investigationEventsProviderProps: Object,
    historyEventsProviderProps: Object,
    toggleDocumentsLoadingStatus: (status: boolean) => void,
    updateFilterSectionVisibility: (args: boolean) => void,
    clearCategoryNegativeTerms: (category: string) => void,
    uniqueId: ?string,
    enforceAlertsLimit: boolean,
    activeAlertsLimit: number,
    activeAlertsCount: number,
    setActiveAlertsCount: (number) => number,
    setSelectedArticlesInfo: (Array<Object>, boolean) => Array<Object>,
    isBatchReportsEnabled: boolean,
    isSavingSearch: boolean,
    isAddingToEntityView: boolean,
    showSaveSearchModalBeforeLeave: boolean,
    resetLaunchedFrom: () => void,
    updatePrevPath: () => void,
    adminPermissionChanged: boolean,
    searchState: Object,
    googleTranslateStatus: boolean,
    expandedFiltersSection: string,
    updateExpandedFiltersSection: (expandedFiltersSection: string) => void,

    ...InjectedProps,
};

type InjectedProps = {|
    +intl: Object,
    context: ContextType,
|};

class ResultsList extends React.Component<Props, State> {
    state: State;
    resultsListContentRef: { current: Object | HTMLDivElement };
    postFilterContainerRef: { current: Object | HTMLDivElement };
    toggleFilterVisibilityButtonRef: { current: Object | HTMLDivElement };

    constructor(props: Props) {
        super(props);

        this.resultsListContentRef = React.createRef();
        this.postFilterContainerRef = React.createRef();
        this.toggleFilterVisibilityButtonRef = React.createRef();
        this.state = {
            visibleDuplicatesIds: [],
            showDuplicatesState: {},
            duplicates: {},
            shouldUpdateCustomDatesState: false,
            showEditModal: false,
            confirmationModalTitle: '',
            confirmationModalDescription: '',
            showLeaveModal: false,
            leaveNextLocation: null,
            initialPostFilters: null,
            unregisterLeaveHook: null,
            ignoreScrollingGraphVisibilityCheck: true,
            showTrendsGraphTemporarily: true,
        };
    }

    updateContainerPositionWhenScroll: Function = () => {
        const position = this.resultsListContentRef.current.getBoundingClientRect().top;
        const extraVisibleContent = position > HEADER_HEIGHT ? position + FOOTER_HEIGHT : HEADER_AND_FOOTER_HEIGHT;
        this.postFilterContainerRef.current.style.setProperty(
            'max-height',
            window.innerHeight - extraVisibleContent + 'px'
        );
        if (this.resultsListContentRef && this.resultsListContentRef.current) {
            const extraSpaceToAccommodateGoogleTranslateToolbar = this.props.googleTranslateStatus ? 38 : 0;
            const topSize =
                position > HEADER_HEIGHT ? position : HEADER_HEIGHT + extraSpaceToAccommodateGoogleTranslateToolbar;
            this.postFilterContainerRef.current.style.setProperty('top', topSize + 'px', 'important');
            this.toggleFilterVisibilityButtonRef.current.style.setProperty('top', topSize + 45 + 'px', 'important');
        }
    };

    disableTrendsGraphCloseOnScroll = (isTrendsExpanded) => {
        this.setState({ showTrendsGraphTemporarily: !isTrendsExpanded, ignoreScrollingGraphVisibilityCheck: true });
    }

    toggleShowTrendsGraphTemporarily = () => {
        this.setState({ showTrendsGraphTemporarily: true });
    }

    handleTrendsGraphClose = () => {
        const stickyToolbarElement = document.getElementsByClassName("notranslate sticky");
        const toolbarLimit = 280;

        const stickyToolbarPosition = stickyToolbarElement.length > 0 ? stickyToolbarElement[0].getBoundingClientRect().top : null;
        const hasToolbarReachedTop = HEADER_HEIGHT === stickyToolbarPosition;

        if(!this.state.ignoreScrollingGraphVisibilityCheck && hasToolbarReachedTop) {
            this.setState({ showTrendsGraphTemporarily: false });
        }
        else if (this.props.isTrendsExpanded && stickyToolbarPosition>toolbarLimit) {
            this.setState({ showTrendsGraphTemporarily: true });
        }
        else {
            this.setState({ ignoreScrollingGraphVisibilityCheck: false});
        }
    }

    componentDidMount() {
        document.title = RESULTS_PAGE_TITLE;
        if (this.props.prevPath === '/manage-alerts') {
            document.title = EDIT_ALERT_SEARCH_PAGE_TITLE;
        }
        const { isSnapshotVisible, articleType: categoryName, router, route } = this.props;
        const categoryData = this.props.searchResults[categoryName];
        const { filteredFields } = this.props.location.query;
        const isSearchRerun = this.isSearchRerun();

        if (categoryUtils.isVisitedCategory(categoryData, this.props.investigation)) {
            this.props.investigationEventsProviderProps.sendInvestigationEvent({
                type: investigationEvents.EVENT_SUBTYPES.revisited,
                payload: { categoryData },
            });
        } else {
            this.props.toggleDocumentsLoadingStatus(true);
            this.props.investigationEventsProviderProps.sendInvestigationEvent({
                type: ACTION_TYPES.loadDocuments,
                payload: { categoryName, filteredFields, isSnapshotVisible, isSearchRerun },
            });
            AlertsApi.getMyAlertsCount(true).then((count) => this.props.setActiveAlertsCount(count));
        }

        this.setBreadcrumbs(this.props.articleType);
        window.addEventListener('scroll', this.updateContainerPositionWhenScroll);
        this.updateContainerPositionWhenScroll();
        window.scroll(0, this.props.previousScrollY);
        window.addEventListener('scroll', this.handleTrendsGraphClose);

        if (this.props.prevPath !== '/document') {
            this.clearAllArticleSelection();
            this.props.clearSelectedDuplicates(values(CATEGORY_NAMES));
            this.props.clearSelectedArticle();
        }

        if (route) {
            this.setState({
                unregisterLeaveHook: router.setRouteLeaveHook(route, this.handleBeforeUnload),
            });
        }
        // comparing if any of postfilters are changed is an expensive function executed on each component update, a debounce should help in this case
        this.debouncedCheckPostFiltersChanged = debounce(this.checkPostFiltersChanged, 300);
    }

    componentDidUpdate(nextProps: Props) {
        const {
            articleType,
            searchState: { launchedFrom },
        } = nextProps;
        const categoryName = articleType;
        const categoryData = nextProps.searchResults[categoryName];

        this.updateContainerPositionWhenScroll();

        // If results list was reloaded, re-set the category and prevPath
        if (this.props.prevPath === '/' && this.props.articleType && this.props.location?.query?.searchFrom === LAUNCHED_SEARCH_FROM.SCREENING_PAGE) {
            this.props.updatePrevPath({
                ...this.props.location,
                pathname: BREADCRUMBS_PREV_PATH.SCREENING_PAGE,
            });
            reduxStore.dispatch(searchParamsActions.setCategory(this.props.articleType));
        }

        if (this.props.preferences.language !== nextProps.preferences.language) {
            if (hasChangedAfterCategoryLoaded(nextProps)) {
                searchUtils.updateFilterData(categoryData);
            }
        }

        this.setBreadcrumbs(nextProps.articleType);

        if (
            this.props.searchParams.query &&
            nextProps.searchParams.query &&
            this.props.searchParams.query !== nextProps.searchParams.query
        ) {
            this.props.toggleDocumentsLoadingStatus(true);
            this.clearAllArticleSelection();
            this.props.clearSelectedDuplicates(values(CATEGORY_NAMES));
            this.handleChangeSortingOnSource(nextProps.sort);
        }

        if (launchedFrom === LAUNCHED_SEARCH_FROM.SCREENING_PAGE) {
            const { searchResults, areDocumentsLoading, isAddingToEntityView } = nextProps;
            const everyAvailbleCategory = Object.keys(searchResults).filter((key) => searchResults[key].available);
            const everyCategoryLoaded = everyAvailbleCategory.every((key) => searchResults[key].loaded);
            const isLoaded = everyCategoryLoaded && !areDocumentsLoading && !isAddingToEntityView;

            if (isLoaded && this.props.isBatchReportsEnabled) {
                if (this.state.initialPostFilters) {
                    // check if postfilters have been changed
                    this.debouncedCheckPostFiltersChanged();
                } else {
                    // save initial postfilters
                    this.saveCurrentPostFilters();
                }
            }
        }
    }

    checkPostFiltersChanged() {
        const { initialPostFilters } = this.state;
        const { arePostFiltersChanged, setPostFiltersChanged } = this.props;
        const currentPostFilters = this.getAllPostFilters();
        const postFiltersChanged = !isEqual(initialPostFilters, currentPostFilters);

        if (arePostFiltersChanged !== postFiltersChanged) {
            setPostFiltersChanged(postFiltersChanged);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.updateContainerPositionWhenScroll);
        this.props.setScrollY(PAGE_NAMES.SEARCH_PAGE, window.scrollY);
        this.setFiltersAppliedFlag();
        this.debouncedCheckPostFiltersChanged.cancel();
        this.props.setPostFiltersChanged(false);
        this.state.unregisterLeaveHook && this.state.unregisterLeaveHook();
    }

    loadDocumentsAndEmitEvents: Function = async ({ investigationEventType, categoryData, categoryName }) => {
        this.props.markCategoryAsVisited(categoryName);

        const shouldUpdateSort = categoryData.type !== CATEGORY_TYPE_UBO_CHILD && this.wasSortChanged(categoryData);

        const searchData = await this.loadDocuments({
            currentCategory: categoryName,
            selectedPage: 0,
            updateSort: shouldUpdateSort,
        });

        if (searchData) {
            this.props.investigationEventsProviderProps.sendInvestigationEvent({
                type: investigationEventType,
                payload: {
                    categoryData: searchData.category,
                    searchPayload: searchData.payload,
                    eventSubType: null,
                },
            });
            this.props.historyEventsProviderProps.updateHistoryEntry(
                categoryName,
                this.props.location.query.filteredFields
            );
        }

        return searchData;
    };

    checkFilters: Function = async () => {
        const { searchResults, articleType, searchState, viewId } = this.props;
        const postFilters = cloneDeep(searchResults[articleType]?.postFilters);

        try {
            const { postFilters: defaultPostFilters } = await searchUtils.getPostFiltersForEntity(searchState.entityId, viewId);
            const defaultPostFilterForCategory = defaultPostFilters.find(({ category }) =>
                [articleType, categoryUtils.getParent(articleType)].includes(category)
            );

            if (defaultPostFilterForCategory && postFilters) {
                const filterKeys = [
                    'dateRange',
                    'proximity',
                    'negativityLevels',
                    'similarNames',
                    'suggestedNames',
                    'language',
                    'sourceName',
                    'publicationName',
                    'publicationType',
                    'company',
                    'subject',
                    'industry',
                    'geography',
                    'esgFactors',
                    'fuzzyOn',
                    'dunsFilter',
                    'includeTerms',
                    'excludeTerms',
                    'terms',
                    'companyMentions',
                    'personMentions',
                    'docketStatus',
                ];

                return filterKeys.some((key) => {
                    if (postFilters[key] === undefined || postFilters[key] === null || postFilters[key].length === 0) return false;
                    return !isEqual(defaultPostFilterForCategory[key], postFilters[key]);
                });
            }
        } catch (error) {
            console.log('Error fetching post filters: ', error);
        }

        return false;
    };

    checkAdHocFilters: Function = () => {
        const categorySettings = this.getPostFilterData();
        const postFilters = this.props.searchResults[this.props.articleType].postFilters;

        if (categorySettings && postFilters) {
            let showDateRangeResetButton = false;
            let showProximityResetButton = false;
            let showNegativityLevelsResetButton = false;

            if (
                categorySettings[POSTFILTER_TYPE.DATE_RANGE] &&
                categorySettings[POSTFILTER_TYPE.DATE_RANGE].length > 0
            ) {
                if (
                    categorySettings.defaultDateRange &&
                    utils.getDateFromString(categorySettings.defaultDateRange.split(';')[0], CALENDAR_DATE_FORMAT_BE)
                ) {
                    showDateRangeResetButton =
                        categorySettings[POSTFILTER_TYPE.DATE_RANGE][0].name !== FILTER_BAR_DATE_RANGE_CUSTOM_LABEL;
                } else {
                    showDateRangeResetButton =
                        utils.dateRangeToText(categorySettings.defaultDateRange) !==
                        categorySettings[POSTFILTER_TYPE.DATE_RANGE][0].name;
                }
            }

            if (
                categorySettings[POSTFILTER_TYPE.PROXIMITY_NEGATIVE_TERMS] &&
                categorySettings[POSTFILTER_TYPE.PROXIMITY_NEGATIVE_TERMS].length > 0
            ) {
                showProximityResetButton =
                    utils.proximityToText(categorySettings.defaultProximityNegativeTerms) !==
                    categorySettings[POSTFILTER_TYPE.PROXIMITY_NEGATIVE_TERMS][0].name;
            }

            const defaultNegativityLevels =
                this.props.searchParams.searchType === PERSON_SEARCH
                    ? this.props.personNegativityLevel
                    : this.props.companyNegativityLevel;

            if (categorySettings[POSTFILTER_TYPE.NEGATIVITY_LEVELS] && defaultNegativityLevels) {
                let hasSameItems = false;

                if (categorySettings[POSTFILTER_TYPE.NEGATIVITY_LEVELS].length === 0) {
                    hasSameItems = true;
                } else if (
                    categorySettings[POSTFILTER_TYPE.NEGATIVITY_LEVELS].length === defaultNegativityLevels.length
                ) {
                    hasSameItems = categorySettings[POSTFILTER_TYPE.NEGATIVITY_LEVELS].every(
                        (level) => defaultNegativityLevels.indexOf(level.name) > -1
                    );
                }

                showNegativityLevelsResetButton = !hasSameItems;
            }

            return (
                showDateRangeResetButton ||
                showProximityResetButton ||
                showNegativityLevelsResetButton ||
                categorySettings.defaultSimilarNames ||
                categorySettings.defaultSuggestedNames ||
                categorySettings.defaultLanguage ||
                categorySettings.defaultSource ||
                categorySettings.defaultSourceName ||
                categorySettings.defaultSourceType ||
                categorySettings.defaultCompany ||
                categorySettings.defaultSubject ||
                categorySettings.defaultIndustry ||
                categorySettings.defaultGeography ||
                categorySettings.defaultEsgFactors ||
                (categorySettings[POSTFILTER_TYPE.DUNS_FILTER] &&
                    categorySettings[POSTFILTER_TYPE.DUNS_FILTER][0] &&
                    categorySettings[POSTFILTER_TYPE.DUNS_FILTER][0].length) ||
                (postFilters.includeTerms && postFilters.includeTerms.length > 0) ||
                (postFilters.excludeTerms && postFilters.excludeTerms.length > 0) ||
                (postFilters.terms && postFilters.terms.length > 0) ||
                (categorySettings[POSTFILTER_TYPE.COMPANY_MENTIONS] &&
                    categorySettings[POSTFILTER_TYPE.COMPANY_MENTIONS].length > 0) ||
                (categorySettings[POSTFILTER_TYPE.PERSON_MENTIONS] &&
                    categorySettings[POSTFILTER_TYPE.PERSON_MENTIONS].length > 0) ||
                (categorySettings[POSTFILTER_TYPE.DOCKET_STATUS] &&
                    categorySettings[POSTFILTER_TYPE.DOCKET_STATUS].length === 1)
            );
        }
    };

    setFiltersAppliedFlag: Function = async () => {
        const { searchState, articleType, setFiltersAppliedForCategory } = this.props;

        let hasFiltersApplied = false;

        if (searchState.launchedFrom === LAUNCHED_SEARCH_FROM.SCREENING_PAGE) {
            hasFiltersApplied = await this.checkFilters();
        } else {
            hasFiltersApplied = this.checkAdHocFilters();
        }

        setFiltersAppliedForCategory(articleType, hasFiltersApplied);
    };

    loadDocuments: Function = async ({ currentCategory, selectedPage, updateSort }) => {
        this.setFiltersAppliedFlag();
        const searchData = await searchUtils.loadMoreDocumentsForAvailableCategories(
            currentCategory,
            selectedPage,
            this.isSearchRerun(),
            this.props.useNewResearchSummary
        );

        if (searchData) {
            this.props.checkPreferencesAreObsolete();

            if (updateSort) {
                this.props.setLastSort(currentCategory, this.props.sort);
            }
        }

        return searchData;
    };

    searchCategory: Function = ({ categoryName, investigationEventType, investigationSubType, additionalData }) => {
        this.setFiltersAppliedFlag();

        searchUtils
            .searchForCategory(categoryName, this.props.useNewResearchSummary)
            .then((searchData) => {
                const data = {
                    categoryData: searchData.category,
                    searchPayload: searchData.payload,
                    eventType: investigationEventType,
                    eventSubType: investigationSubType,
                    ...additionalData,
                };

                this.props.investigationEventsProviderProps.sendInvestigationEvent({
                    type: investigationEventType,
                    payload: { ...data },
                });

                this.props.markCategoryAsVisited(searchData.category.name);
                this.props.historyEventsProviderProps.updateHistoryEntry(
                    searchData.category.name,
                    this.props.location.query.filteredFields
                );
                this.props.checkPreferencesAreObsolete();

                return searchData;
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'General_CoreFunctionality_Error_error.searchCategoryError',
                    messageType: 'system-error',
                });
            });
    };

    setBreadcrumbs: Function = (articleType: string) => {
        const searchFrom = this.props.location?.query?.searchFrom;
        let breadcrumbs = new BreadCrumbsModel.Builder(BREADCRUMBS.RESULTS)
            .setProperty('articleType', articleType)
            .setProperty('searchQuery', utils.sanitizeSearchStringInput(this.props.searchParams.query))
            .setProperty('searchQueryType', this.props.searchParams.searchType)
            .setProperty('category', this.props.searchParams.category)
            .setProperty('isSnapshotVisible', this.props.isSnapshotVisible)
            .setProperty('prevPath', this.props.prevPath)
            .setProperty('searchFrom', searchFrom)
            .setProperty('launchedFrom', this.props.searchState.launchedFrom)
            .build().breadcrumbs;
        this.props.updateBreadcrumbs(breadcrumbs);
    };

    isSearchRerun: Function = () => {
        let fromHistory =
            this.props.historyCategory &&
            this.props.historyCategory.categoryName &&
            this.props.historyCategory.categoryName.length > 0;
        let fromAlerts = !!this.props.params.alertId;

        return fromHistory || fromAlerts;
    };

    isHistoryCategoryCurrentOne: Function = () => {
        return (
            this.props.historyCategory &&
            this.props.historyCategory.categoryName &&
            this.props.historyCategory.categoryName.length > 0 &&
            this.props.articleType === this.props.historyCategory.categoryName
        );
    };

    updatePostFilterTreeData: Function = (searchFieldName) => {
        searchUtils.updateFilterData(this.props.searchResults[this.props.articleType], searchFieldName, true);
    };

    getPostFilterData: Function = () => {
        let postFiltersConfig = cloneDeep(this.props.postFiltersConfig);
        let postFilterData = {};
        let checkedPostFilterValue;

        if (postFiltersConfig) {
            let postFilters = postFiltersConfig[this.props.articleType] || {};
            let category = this.props.articleType;
            postFilterData = {};

            Object.keys(postFilters).forEach((filter) => {
                postFilterData[filter] = [];

                switch (postFilters[filter].componentType) {
                    case POSTFILTER_COMPONENT_TYPE.CHECKBOX_LIST:
                        let allChecked = true;
                        postFilters[filter].values.forEach((option) => {
                            if (option.checked === true) {
                                postFilterData[filter].push({
                                    name:
                                        option.label === 'missingUnclassified'
                                            ? formatRichMessage(
                                                  { id: 'General.postFilters.unclassified' },
                                                  this.props.intl
                                              )
                                            : option.label,
                                });
                            } else {
                                allChecked = false;
                            }
                        });

                        postFilterData['default' + filter] = !allChecked;
                        break;
                    case POSTFILTER_COMPONENT_TYPE.RADIO_LIST:
                        checkedPostFilterValue = postFilters[filter].values.find((option) => {
                            return option.checked;
                        });

                        if (checkedPostFilterValue) {
                            if (filter === POSTFILTER_TYPE.FUZZY_SEARCH) {
                                postFilterData[filter].push({
                                    count: checkedPostFilterValue.count,
                                    name: checkedPostFilterValue.label,
                                });
                            } else {
                                postFilterData[filter].push({
                                    ...(checkedPostFilterValue.count ? { count: checkedPostFilterValue.count } : {}),
                                    name: checkedPostFilterValue.label,
                                });
                            }

                            postFilterData['default' + filter] = this.getDefaultPostFiltersValues(filter, category);
                        }

                        break;
                    case POSTFILTER_COMPONENT_TYPE.DATE_RADIO_LIST:
                        checkedPostFilterValue = postFilters[filter].values.find((option) => {
                            return option.checked === true;
                        });
                        if (checkedPostFilterValue) {
                            postFilterData[filter] = this.getDate(checkedPostFilterValue.count);
                            postFilterData.defaultDateRange = this.getDefaultPostFiltersValues(filter, category);
                        }
                        break;
                    case POSTFILTER_COMPONENT_TYPE.GEOGRAPHY:
                    case POSTFILTER_COMPONENT_TYPE.NESTED_CHECKBOX_LIST:
                        const areAllChecked = postFilters[filter].values.filter((item) => !item.checked).length === 0;
                        const tree = utils.convertFlatOptions(postFilters[filter].values);
                        const treeValues = utils.flattenTreeValues(tree, false).filter((value) => value.checked);

                        if (treeValues) {
                            postFilterData[filter] = treeValues.map((value) => ({ name: value.label }));
                            postFilterData['default' + filter] = !areAllChecked;
                        }
                        break;
                    case POSTFILTER_COMPONENT_TYPE.TEXT_FILTER:
                        postFilterData[filter] = postFilters[filter].values || [''];
                        postFilterData['default' + filter] = [''];
                        break;
                }
            });
        }
        return postFilterData;
    };

    getDefaultPostFiltersValues: Function = (filter, category) => {
        let defaultValue;
        let { adHocSearch, generalSettings, personCheck, companyCheck } = this.props;
        const searchType = this.props.searchParams.searchType;

        switch (filter) {
            case POSTFILTER_TYPE.PROXIMITY_NEGATIVE_TERMS:
                defaultValue = generalSettings.proximity;
                break;

            case POSTFILTER_TYPE.FUZZY_SEARCH:
                defaultValue = personCheck.fuzzyOn;
                break;

            case POSTFILTER_TYPE.DATE_RANGE:
                let newsDateRange = adHocSearch.newsDateRange
                    ? adHocSearch.newsDateRange.range
                    : generalSettings.newsSources.dateRange;
                let companyDateRange = adHocSearch.companyDateRange
                    ? adHocSearch.companyDateRange.range
                    : generalSettings.companySources.dateRange;
                let legalDateRange = adHocSearch.legalDateRange
                    ? adHocSearch.legalDateRange.range
                    : generalSettings.legalSources.dateRange;

                switch (category) {
                    case CATEGORY_NAMES.NEWS:
                    case CATEGORY_NAMES.NEGATIVE_NEWS:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_EN:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_FR:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_NL:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_DE:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_ES:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_TR:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_SV:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_MS:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_PT:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_JA:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_IT:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_AR:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_ZH:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_RU:
                    case CATEGORY_NAMES.NEGATIVE_NEWS_PL:
                    case CATEGORY_NAMES.CUSTOM_NEWS:
                    case CATEGORY_NAMES.CUSTOM_NEWS1:
                    case CATEGORY_NAMES.CUSTOM_NEWS2:
                    case CATEGORY_NAMES.CUSTOM_NEWS3:
                    case CATEGORY_NAMES.LN_CUSTOM_NEWS1:
                    case CATEGORY_NAMES.ADMIN_CUSTOM_NEWS1:
                    case CATEGORY_NAMES.ADMIN_CUSTOM_NEWS2:
                    case CATEGORY_NAMES.ADMIN_CUSTOM_NEWS3:
                        defaultValue = newsDateRange;
                        break;
                    case CATEGORY_NAMES.COMPANY_RESOURCES:
                        defaultValue = companyDateRange;
                        break;
                    case CATEGORY_NAMES.AGENCY_DECISION:
                    case CATEGORY_NAMES.STATE_DOCKETS:
                    case CATEGORY_NAMES.FEDERAL_DOCKETS:
                    case CATEGORY_NAMES.LAW_REVIEWS:
                    case CATEGORY_NAMES.CASES:
                    case CATEGORY_NAMES.VERDICTS:
                        defaultValue = legalDateRange;
                        break;
                    case CATEGORY_NAMES.PEPS:
                    case CATEGORY_NAMES.SANCTIONS_WATCHLIST:
                    case CATEGORY_NAMES.FINANCIAL_REPORT:
                    case CATEGORY_NAMES.BIOGRAPHICAL:
                        defaultValue = determineDateRange(adHocSearch);
                        break;
                }
                break;

            case POSTFILTER_TYPE.NEGATIVITY_LEVELS:
                defaultValue =
                    searchType === PERSON_SEARCH ? personCheck.negativityLevel : companyCheck.negativityLevel;
                break;
        }
        return defaultValue;
    };

    getDate: Function = (postFilerDateValue) => {
        if (postFilerDateValue) {
            let date = [];
            if (utils.getDateFromString(postFilerDateValue.split(';')[0], CALENDAR_DATE_FORMAT_BE)) {
                date.push({ name: FILTER_BAR_DATE_RANGE_CUSTOM_LABEL, value: postFilerDateValue });
            } else {
                date.push({ name: utils.dateRangeToText(postFilerDateValue), value: postFilerDateValue });
            }

            return date;
        }
    };

    changeSorting: Function = (event) => {
        let sort = event.target.value;
        let articleType = event.target.name;
        this.handleChangeSortingOnSource(sort);
        this.props.setLastSort(articleType, sort);

        if (!utils.isValidSortOption(sort)) {
            utils.showNotificationsMessage({
                messageText: 'General_CoreFunctionality_Error_error.invalidSortOption',
                messageType: 'system-error',
            });
            return;
        }

        this.searchCategory({
            categoryName: articleType,
            investigationEventType: investigationEvents.EVENT_SUBTYPES.update,
        });

        //save sortType to preferences
        userPreferencesUtils.saveSortTypeToPreferences(sort);
    };

    toggleFilterSectionVisibility: Function = (isFilterSectionCollapsed) => {
        this.props.updateFilterSectionVisibility(isFilterSectionCollapsed);

        userPreferenceApi
            .updateUserPreference({
                userPreferences: {
                    generalSettings: {
                        isFilterSectionCollapsed,
                    },
                },
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'General_CoreFunctionality_Error_general.saveError',
                    messageType: 'system-error',
                });
            });
    };

    handleChangeSortingOnSource: Function = (sortOption) => {
        Object.keys(this.props.searchResults).forEach((categoryKey) => {
            this.props.setSortOption(categoryKey, sortOption);
            this.props.clearCategoryTerms(categoryKey);
        });
    };

    handleBillingEvent: Function = (articleType) => {
        const visitedContentTypes = this.props.investigation.visitedContentTypes;

        if (visitedContentTypes && !visitedContentTypes[articleType]) {
            let billingId = this.props.billingId,
                costCode = costCodeUtils.getCostCode();

            searchUtils.executeBillingSearchEvent(articleType, billingId, costCode);
        }
    };

    handleUpdateLocation: Function = (articleType) => {
        const location = this.props.location;
        location.query.category = articleType;
        hashHistory.push(location);
    };

    wasPageChanged: Function = (category) => {
        return this.props.pageSize !== category.pageSize && category.count > 0;
    };

    wasSortChanged: Function = (category) => {
        return this.props.sort !== category.lastSort;
    };

    handleSelectCategory: Function = async (event) => {
        window.scrollTo(0,0);
        const articleType = event.target.value;
        const categoryName = categoryUtils.hasChildren(articleType)
            ? withSearchResultsFilter(this.props.searchResults).getFirstChild(articleType).key
            : articleType;
        const categoryData = this.props.searchResults[categoryName];

        this.handleBillingEvent(categoryName);
        this.handleUpdateLocation(categoryName);

        if (
            !categoryUtils.isVisitedCategory(categoryData, this.props.investigation) ||
            (categoryData.type !== CATEGORY_TYPE_UBO_CHILD && this.wasSortChanged(categoryData)) ||
            this.wasPageChanged(categoryData)
        ) {
            const investigationEventType = !categoryUtils.isVisitedCategory(categoryData, this.props.investigation)
                ? investigationEvents.EVENT_SUBTYPES.firstTime
                : investigationEvents.EVENT_SUBTYPES.revisited;

            await this.loadDocumentsAndEmitEvents({
                investigationEventType,
                categoryData,
                categoryName,
            });
        } else {
            this.props.investigationEventsProviderProps.sendInvestigationEvent({
                type: investigationEvents.EVENT_SUBTYPES.revisited,
                payload: { categoryData },
            });

            const shouldUpdateSort = categoryData.type !== CATEGORY_TYPE_UBO_CHILD && this.wasSortChanged(categoryData);

            await this.loadDocuments({
                currentCategory: categoryName,
                selectedPage: 0,
                updateSort: shouldUpdateSort,
            });
        }
    };

    showSingleArticle: Function = (article) => {
        let researchSummary = ArticlesUtils.getReportSummary(),
            articleType = this.props.articleType,
            proximity = this.props.searchResults[articleType].postFilters.proximity || '',
            includeTerms = this.props.searchResults[articleType].postFilters.includeTerms || [],
            costCode = costCodeUtils.getCostCode(),
            singleSource = utils.isSingleSourceSearch(this.props.postFiltersConfig[this.props.articleType]),
            terms = this.props.searchResults[articleType].postFilters.terms || [];

        const fuzzyThreshold = this.props.searchResults[articleType].postFilters[POST_FILTER_FUZZY_SEARCH]
            ? this.props.searchResults[articleType].postFilters[POST_FILTER_FUZZY_THRESHOLD] ||
              this.props.personCheck.fuzzyThreshold
            : '';
        const suggestedNamesForCategory = this.props.searchResults[articleType].postFilters.suggestedNames;
        const suggestedNames = suggestedNamesForCategory
            ? utils.getFuzzyNameList(suggestedNamesForCategory)
            : utils.getFuzzyNameList(this.props.suggestedNamesObject);

        article.reportSnippetType = articleType;
        article.articleType = articleType;
        this.props.setSelectedArticle(
            article,
            researchSummary,
            utils.sanitizeSearchStringInput(this.props.searchParams.query),
            this.props.searchParams.searchType
        );
        // Initializing navigation with current search results
        // add empty elements in list if article is accessed from second page and forth, keeping the indexes
        const prependList = fill(
            Array(this.props.articleList.currentPageIndex * this.props.articleList.pageSize),
            null
        );
        this.props.initArticleNavigation({
            index: article.index,
            article,
            list: [...prependList, ...this.props.articleList.articles],
            startPage: this.props.articleList.currentPageIndex,
            pageSize: this.props.articleList.pageSize,
            totalCount: this.getTotalArticleCount().count,
            params: {
                researchSummary,
                query: utils.sanitizeSearchStringInput(this.props.searchParams.query),
                category: articleType,
                searchType: this.props.searchParams.searchType,
                prefilterQuery: this.props.searchParams.prefilterQuery,
                proximity,
                includeTerms,
                costCode,
                singleSource,
                fuzzyThreshold,
                suggestedNames,
                terms,
            },
        });
    };

    loadMoreArticles: Function = (page) => {
        callMatomoEvent({
            pageName: MATOMO.pageName.resultsList,
            component: MATOMO.component.searchResultsList,
            subComponent: MATOMO.subComponent.pagination,
            event: MATOMO.event.resultslistPagination,
            value: page.selected + 1,
            action: MATOMO.action.click,
        });

        window.scroll(0, 0);
        this.loadDocuments({
            currentCategory: this.props.articleType,
            selectedPage: page.selected,
            investigationEventType: investigationEvents.EVENT_SUBTYPES.update,
        });
    };

    getAllVisibleArticles: Function = () => {
        const singleSource = utils.isSingleSourceSearch(this.props.postFiltersConfig[this.props.articleType]);
        const updatedArticleList = this.props.articleList.articles.map((article) => {
            return { ...article, singleSource };
        });
        let articlesList = [];

        updatedArticleList.forEach((article) => {
            let duplicates = this.state.duplicates[article.duplicateGroupId];
            if (duplicates) {
                duplicates.forEach((entity) => {
                    if (this.state.visibleDuplicatesIds.indexOf(entity.id) > -1 || entity.id === article.id) {
                        articlesList.push(entity);
                    }
                });
            } else {
                articlesList.push(article);
            }
        });
        return articlesList;
    };

    checkAllArticleHandler: Function = (event) => {
        let articleShouldBeAdded = event.target.checked;
        let selectedArticles = this.getSelectedArticles(this.props.selectedArticles, this.props.articleType);
        let articlesList = this.getAllVisibleArticles();
        let duplicates = [];

        const articles = differenceBy(articlesList, selectedArticles, (item) => item.id);
        const articlesIndex = articlesList.map((article) => {
            return {
                documentRank: articlesList.indexOf(article) + 1,
                pcsi: article.pcsi,
                lni: article.id,
            };
        });

        if (articleShouldBeAdded) {
            this.props.setSelectedArticles(articles, this.props.articleType, articleShouldBeAdded);
            if (this.state.visibleDuplicatesIds.length > 0) {
                articlesList.forEach((article) => {
                    if (this.state.visibleDuplicatesIds.indexOf(article.id) > -1) {
                        duplicates.push(article);
                    }
                });
            }
            this.props.setSelectedDuplicates(duplicates, this.props.articleType, articleShouldBeAdded);
            this.props.setSelectedArticlesInfo([], false);
            this.props.setSelectedArticlesInfo(articlesIndex, true);
        } else {
            this.props.clearSelectedArticles([this.props.articleType]);
            this.props.clearSelectedDuplicates([this.props.articleType]);
            this.props.setSelectedArticlesInfo([], false);
        }
    };

    getSelectedArticles: Function = (selectedArticlesFromProps, articleType) => {
        return (selectedArticlesFromProps && selectedArticlesFromProps[articleType]) || [];
    };

    getSelectCheckboxState: Function = () => {
        if (this.props.articleList.hasError === true || !this.props.articleList.loaded) {
            return false;
        } else {
            let allAreSelected =
                this.props.articleList.articles.length > 0 &&
                this.props.articleList.articles.every((article) => {
                    return find(this.props.selectedArticles[this.props.articleType], (selectedArticle) => {
                        return selectedArticle.id === article.id;
                    });
                });

            if (allAreSelected) {
                if (this.state.visibleDuplicatesIds.length > 0) {
                    allAreSelected = this.state.visibleDuplicatesIds.every((id) => {
                        return find(this.props.selectedArticles[this.props.articleType], (selectedArticle) => {
                            return selectedArticle.id === id;
                        });
                    });
                }
            }
            return allAreSelected;
        }
    };

    getSelectedArticlesCount: Function = () => {
        return this.getSelectedArticles(this.props.selectedArticles, this.props.articleType).length;
    };

    getTotalArticleCount: Function = () => {
        return {
            count: this.props.articleList.count || 0,
            formatted: utils.formatNumbersByUserLocale(this.props.language, this.props.articleList.count),
        };
    };

    setVisibleDuplicates: Function = (duplicatesIds, areDuplicatesVisible) => {
        let visibleDuplicatesIds = [...this.state.visibleDuplicatesIds];

        if (areDuplicatesVisible) {
            visibleDuplicatesIds.push(...duplicatesIds);
        } else {
            duplicatesIds.forEach((id) => {
                let index = visibleDuplicatesIds.indexOf(id);
                if (index > -1) {
                    visibleDuplicatesIds.splice(index, 1);
                }
            });
        }
        this.setState({
            visibleDuplicatesIds,
        });
    };

    setDuplicatesForArticle: Function = (duplicateGroupId, duplicatesList) => {
        let duplicates = this.state.duplicates;
        duplicates[duplicateGroupId] = duplicatesList;

        this.setState({
            duplicates,
        });
    };

    removeFilter: Function = (event) => {
        let includeTerm = event.target.value;
        let filterType = event.target.name;
        let category = this.props.articleType;

        if (includeTerm === REMOVE_ALL_INCLUDE_TERMS) {
            this.props.clearCategoryTerms(category);
            if (
                this.props.searchResults[category] &&
                this.props.searchResults[category].postFilters &&
                this.props.searchResults[category].postFilters[TERMS]
            ) {
                this.props.clearCategoryNegativeTerms(category);
            }
            this.resetFilter(event);
        } else if (filterType === INCLUDE || filterType === EXCLUDE || filterType === TERMS) {
            this.props.removeTerms(includeTerm, filterType, category);
            if (this.props.onSearchWhithinChange) {
                this.props.onSearchWhithinChange(category, filterType);
            }
            const postFilterData = {
                postFilterType: filterType,
                options: [includeTerm],
            };

            this.searchWithin(investigationEvents.EVENT_TYPES.filterRemoved, postFilterData);
        }
    };

    setDuplicatesSectionState: Function = (sectionId, show) => {
        let showDuplicatesState = Object.assign({}, this.state.showDuplicatesState);
        showDuplicatesState[sectionId] = show;
        this.setState({
            showDuplicatesState,
        });
    };

    resetFilter: Function = async (event: SyntheticInputEvent<>) => {
        let filterType = event.target.value;
        let { articleType, context } = this.props;
        let postFiltersConfig = cloneDeep(this.props.postFiltersConfig);
        let postFilterConfig = postFiltersConfig[articleType];

        this.clearAllArticleSelection();
        this.props.clearSelectedDuplicates(values(CATEGORY_NAMES));

        if (filterType === REMOVE_ALL_INCLUDE_TERMS) {
            this.props.resetToDefaultAllPostFiltersConfigValues(articleType, postFilterConfig);
            await this.props.resetAllPostFiltersToDefaultValues(articleType, postFilterConfig);

            if (context && context.editingAlert) {
                this.props.resetAllFilters(articleType);
            }

            this.searchCategory({
                categoryName: articleType,
                investigationEventType: investigationEvents.EVENT_TYPES.filterReset,
                investigationSubType: investigationEvents.EVENT_SUBTYPES.all,
            });
        } else {
            let postFilterFieldName = postFilterConfig[filterType].searchFieldName;
            let postFilterConfigValues = postFilterConfig[filterType].values;
            if (filterType === POSTFILTER_TYPE.DUNS_FILTER) {
                postFilterConfigValues = [''];
            }

            if (Object.keys(POST_FILTERS_DEFAULT_SORT).indexOf(filterType) > -1) {
                this.props.updatePostFiltersSort(articleType, filterType, POST_FILTERS_DEFAULT_SORT[filterType]); //reset to default
            }

            await this.props.resetPostFilterConfigValues(articleType, filterType, postFilterConfigValues);
            if (context && context.editingAlert) {
                this.props.onPostfilterChange(articleType, filterType);
            }

            if (filterType && postFilterFieldName) {
                if (filterType === POSTFILTER_TYPE.DATE_RANGE) {
                    postFilterFieldName = FILTER_INFO.DATE_RANGE;
                }
                this.props.resetPostFilterToDefaultValues(
                    articleType,
                    filterType,
                    postFilterFieldName,
                    postFilterConfigValues
                );

                this.searchCategory({
                    categoryName: articleType,
                    investigationEventType: investigationEvents.EVENT_TYPES.filterReset,
                    investigationSubType: null,
                    additionalData: {
                        postFilterType: filterType,
                        options: postFilterConfigValues,
                    },
                });
            } else {
                console.log('missing info for postFilterType %s or postFilterInfo %s', filterType, postFilterFieldName);
            }
        }
    };

    searchWithin: Function = (investigationEventType, postFilterData) => {
        this.clearAllArticleSelection();
        this.props.clearSelectedDuplicates(values(CATEGORY_NAMES));

        this.searchCategory({
            categoryName: this.props.articleType,
            investigationEventType: investigationEventType,
            investigationSubType: null,
            additionalData: postFilterData,
        });
    };

    handlePageSizeChange: Function = (event) => {
        let newPageSize = event.target.value;

        if (this.props.context.isUserMIP) {
            this.loadDocumentsForPageSize(newPageSize);
        } else {
            userPreferenceApi
                .updateUserPreference({
                    userPreferences: {
                        generalSettings: {
                            pageSize: newPageSize,
                        },
                    },
                })
                .then((response) => {
                    this.loadDocumentsForPageSize(newPageSize);

                    return response;
                })
                .catch(() => {
                    return userPreferenceApi
                        .getUserPreferences()
                        .then((response) => {
                            utils.showNotificationsMessage({
                                messageText: 'UserPreferences_update_page_size',
                                messageType: 'system-error',
                            });

                            let pageSize = response.body.userPreferences.generalSettings.pageSize;
                            reduxStore.dispatch(userPreferencesActions.updatePageSize(pageSize));
                        })
                        .catch(() => {
                            hashHistory.push('/error');
                        });
                });
        }
    };

    loadDocumentsForPageSize: Function = (newPageSize) => {
        this.props.updatePageSize(newPageSize);
        let categoryName = this.props.articleType;
        reduxStore.dispatch(searchResultsActions.updateCategoryProperty(categoryName, LOADED, false));

        this.loadDocuments({
            currentCategory: categoryName,
            selectedPage: 0,
            investigationEventType: investigationEvents.EVENT_SUBTYPES.update,
        });
    };

    getLanguagesForCustomNews: Function = (categoryKey) => {
        let { adHocSearch, preferences, searchParams } = this.props;
        if (
            categoryKey === CATEGORY_NAMES.NEWS ||
            categoryKey === CATEGORY_NAMES.CUSTOM_NEWS ||
            categoryUtils.getParent(categoryKey) === CATEGORY_NAMES.CUSTOM_NEWS
        ) {
            let newsSource = find(NEGATIVE_NEWS_SELECTED_LANGUAGE, [
                'name',
                adHocSearch.newsSearchSources ||
                    preferences[PREFERENCES_KEY[searchParams.searchType]].newsSearchSources,
            ]);
            let languages = null;
            switch (newsSource.name) {
                case NEGATIVE_NEWS_SELECTED_LANGUAGE.allNonEnglishLanguageNews.name:
                    languages = newsSource.contentLanguage;
                    break;
                case NEGATIVE_NEWS_SELECTED_LANGUAGE.allNewsAllLanguages.name:
                    languages = null;
                    break;
                default:
                    languages = newsSource.values;
            }

            return languages ? languages[0] : null;
        }
    };

    getArticleInfo: Function = (article, documentViewType) => {
        const billingId = this.props.billingId;
        const { query, searchType, prefilterQuery } = this.props.searchParams;
        const includeTerms = this.props.searchResults[this.props.articleType].postFilters.includeTerms;
        const proximity = this.props.searchResults[this.props.articleType].postFilters.proximity;
        const costCode = costCodeUtils.getCostCode();
        const singleSource = utils.isSingleSourceSearch(this.props.postFiltersConfig[this.props.articleType]);
        const fuzzyThreshold = this.props.searchResults[this.props.articleType].postFilters[POST_FILTER_FUZZY_SEARCH]
            ? this.props.searchResults[this.props.articleType].postFilters[POST_FILTER_FUZZY_THRESHOLD] ||
              this.props.personCheck.fuzzyThreshold
            : '';
        const suggestedNamesForCategory = this.props.searchResults[this.props.articleType].postFilters.suggestedNames;
        const suggestedNames = suggestedNamesForCategory
            ? utils.getFuzzyNameList(suggestedNamesForCategory)
            : utils.getFuzzyNameList(this.props.suggestedNamesObject);
        const terms = this.props.searchResults[this.props.articleType].postFilters.terms || [];

        return {
            id: article.id,
            searchQuery: utils.sanitizeSearchStringInput(query),
            searchQueryType: searchType,
            prefilterQuery,
            category: this.props.articleType,
            billingId,
            includeTerms,
            proximity,
            costCode,
            documentViewType,
            singleSource,
            fuzzyThreshold,
            suggestedNames,
            terms,
        };
    };

    getDocument: Function = (params) => {
        return MainSearchApi.getElasticDocument(params);
    };

    searchDocAccessEvent: Function = (params) => {
        const data = {
            ...params,
            docType: DOC_TYPE_SEARCH_EVENT_REQUEST.DOC,
        };

        this.props.searchDocAccessEvent(data);
    };

    handlePreviewArticle: Function = (e, article, articleType) => {
        const modalProps = {
            showLoader: true,
            hasError: false,
            title: article ? article.title : '',
            headers: [article ? article.source : '', article ? article.date : ''],
            content: undefined,
            publicationType: undefined,
            headerButtons: undefined,
            logo: undefined,
        };

        this.props.onSlideModal(modalProps, () => {});

        const params = this.getArticleInfo(article, DOCUMENT_VIEW_TYPE_PREVIEW);

        this.getDocument(params)
            .then((document) => {
                const documentHasOwnLogo = Object.prototype.hasOwnProperty.call(document, 'logo');
                modalProps.hasError = false;
                modalProps.showLoader = false;
                modalProps.content = document.content;
                modalProps.id = document.id;
                modalProps.publicationType = document.publicationType;
                modalProps.headerButtons = [
                    <AddToReportBtnElastic article={article} articleType={articleType} key="add-to-report" />,
                ];
                modalProps.logo = documentHasOwnLogo ? document.logo : null;

                this.props.onUpdateModalInfo(modalProps, () => {});

                if (document.bovOrganization) {
                    this.searchDocAccessEvent(params);
                }
            })
            .catch(() => {
                modalProps.hasError = true;
                modalProps.showLoader = false;

                this.props.onUpdateModalInfo(modalProps, () => {});
            });
    };

    clearAllArticleSelection: Function = () => {
        const categoriesWithSelection = Object.keys(this.props.selectedArticles);
        categoriesWithSelection.length && this.props.clearSelectedArticles(categoriesWithSelection);
    };

    getDateRangeValues: Function = (articleType, option) => {
        let configDateRangeValues = [];
        const postFiltersConfig = cloneDeep(this.props.postFiltersConfig);

        if (postFiltersConfig && articleType && option) {
            configDateRangeValues = postFiltersConfig[articleType][POSTFILTER_TYPE.DATE_RANGE].values;

            if (configDateRangeValues && configDateRangeValues.length) {
                configDateRangeValues.forEach((value) => {
                    if (value.checked && value.label !== CUSTOM_DATES_LABEL) {
                        value.checked = false;
                    } else if (value.label === CUSTOM_DATES_LABEL) {
                        value.checked = true;
                        value.show = true;
                        value.count = option;
                    }
                });
            }
        }

        return configDateRangeValues;
    };

    updateCustomDatesBasedOnNNV: Function = (shouldUpdate: boolean) => {
        this.setState({
            shouldUpdateCustomDatesState: shouldUpdate,
        });
    };

    onNegativeNewsTimelineChange: Function = (option) => {
        if (!option) {
            return;
        }

        const { articleType: categoryName, context } = this.props;
        const configDateRangeValues = this.getDateRangeValues(categoryName, option);

        // Update postFilterConfig
        this.props.updatePostFilterConfig(categoryName, POSTFILTER_TYPE.DATE_RANGE, configDateRangeValues);
        // Update postFilter with selected value
        this.props.updatePostFilterForCategory(categoryName, POST_FILTER_DATE_RANGE, option);

        // Update the post-filter value when editing an alert
        if (context && context.editingAlert) {
            this.props.onPostfilterChange(categoryName, POSTFILTER_TYPE.DATE_RANGE, configDateRangeValues);
        }

        // Do search, update history and research summary
        this.searchCategory({
            categoryName,
            investigationEventType: EVENT_TYPES.filterApplied,
            investigationSubType: null,
            additionalData: {
                options: configDateRangeValues,
                option: {
                    checked: true,
                    count: option,
                    label: CUSTOM_DATES_LABEL,
                    show: true,
                },
                postFilterFieldName: POST_FILTER_DATE_RANGE,
                postFilterType: POSTFILTER_TYPE.DATE_RANGE,
            },
        });

        // Update PostFilterDateRangeSelect component, to have the custom dates applied to its state
        this.updateCustomDatesBasedOnNNV(true);
    };

    getNegativityLevelsValues: Function = (articleType: string, level: string) => {
        let configValues: Array<Object> = [];
        const postFiltersConfig = cloneDeep(this.props.postFiltersConfig);

        if (postFiltersConfig && articleType && level) {
            configValues = postFiltersConfig[articleType][POSTFILTER_TYPE.NEGATIVITY_LEVELS]
                ? postFiltersConfig[articleType][POSTFILTER_TYPE.NEGATIVITY_LEVELS].values
                : [];

            if (configValues && configValues.length) {
                configValues.forEach((value) => {
                    if (value.label === level) {
                        value.checked = !value.checked;
                    }
                });
            }
        }

        return configValues;
    };

    onNegativityLevelChange: Function = (level: string) => {
        if (!level) {
            return;
        }

        const { articleType: categoryName, context } = this.props;
        const configValues = this.getNegativityLevelsValues(categoryName, level);
        const selectedNegativityLevels = configValues.filter((value) => value.checked).map((value) => value.label);
        const isLevelChecked = selectedNegativityLevels.indexOf(level) > -1;

        if (selectedNegativityLevels && selectedNegativityLevels.length) {
            // Update postFilterConfig
            this.props.updatePostFilterConfig(categoryName, POSTFILTER_TYPE.NEGATIVITY_LEVELS, configValues);
            // Update postFilter with selected value
            this.props.updatePostFilterForCategory(
                categoryName,
                POST_FILTER_NEGATIVITY_LEVELS,
                selectedNegativityLevels
            );

            // Update the post-filter value when editing an alert
            if (context && context.editingAlert) {
                this.props.onPostfilterChange(categoryName, POSTFILTER_TYPE.NEGATIVITY_LEVELS, configValues);
            }

            //Do search, update history and research summary
            this.searchCategory({
                categoryName,
                investigationEventType: isLevelChecked ? EVENT_TYPES.filterApplied : EVENT_TYPES.filterRemoved,
                investigationSubType: null,
                additionalData: {
                    options: configValues,
                    postFilterFieldName: POST_FILTER_NEGATIVITY_LEVELS,
                    postFilterType: POSTFILTER_TYPE.NEGATIVITY_LEVELS,
                },
            });
        } else {
            this.setState({
                confirmationModalTitle: 'UserPreferences.negativeTerms.negativityLevels.popup.header',
                confirmationModalDescription: 'UserPreferences.negativeTerms.negativityLevels.popup.body',
                showEditModal: true,
            });
        }
    };

    closeModal: Function = () => {
        this.setState({
            showEditModal: false,
            confirmationModalTitle: '',
            confirmationModalDescription: '',
        });
    };

    getNegativeTerms: Function = (articleType: string, term: string) => {
        let terms: Array<string> = [];
        const searchResults = cloneDeep(this.props.searchResults);

        if (searchResults && articleType && term) {
            terms =
                searchResults[articleType] &&
                searchResults[articleType].postFilters &&
                searchResults[articleType].postFilters.terms
                    ? searchResults[articleType].postFilters.terms
                    : [];

            terms.push(term);
        }

        return terms;
    };

    onNegativeTermsClick: Function = (term: string) => {
        if (!term) {
            return;
        }

        const { articleType: categoryName, context } = this.props;
        const terms = this.getNegativeTerms(categoryName, term);
        const termArray = term.split();

        // Update postFilter with selected value
        this.props.updatePostFilterForCategory(categoryName, POST_FILTER_TERMS, terms);

        // Update the post-filter value when editing an alert
        if (context && context.editingAlert) {
            this.props.onPostfilterChange(categoryName, POSTFILTER_TYPE.TERMS, terms);
        }

        // Do search, update history and research summary
        this.searchCategory({
            categoryName,
            investigationEventType: EVENT_TYPES.filterApplied,
            investigationSubType: null,
            additionalData: {
                options: termArray,
                postFilterFieldName: POST_FILTER_TERMS,
                postFilterType: TERMS,
            },
        });
    };

    saveSearchAction: () => void = () => {
        const dataForPayload = this.getDataFromProps();
        const requestPayload = searchUtils.computePayload(dataForPayload);
        const { searchState } = this.props;

        const finishCallback = () => {
            this.saveCurrentPostFilters();
            this.pushRedirectAfter();
        };

        return searchUtils.saveSearch(searchState.entityId, requestPayload, finishCallback);
    };

    openSaveSearchPopup: () => void = () => {
        const data = {
            popupType: MODAL_TYPE.SAVE_SEARCH,
            acceptAction: () => this.saveSearchAction(),
        };
        reduxStore.dispatch(mainActions.searchLaunchedFrom(LAUNCHED_SEARCH_FROM.RESULTS_PAGE));

        this.openPopup(data);
    };

    addEntityToEntityView: () => void = () => {
        const dataForPayload = this.getDataFromProps();
        const requestPayload = searchUtils.buildPayloadForCreatingEntity(dataForPayload);

        searchUtils.createEntityFromSearch(requestPayload);
    };

    openPopup = (data) => {
        const popupModel: PopupModelType = new PopupModel.Builder(data.popupType)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('acceptAction', data.acceptAction)
            .build();

        reduxStore.dispatch(popupModelActions.setPopupModel(popupModel));
    };

    handleBeforeUnload = (nextLocation) => {
        const { initialPostFilters } = this.state;

        if (isEmpty(initialPostFilters)) {
            return true;
        }

        if (!this.state.showLeaveModal || this.props.showSaveSearchModalBeforeLeave) {
            if (
                this.props.isBatchReportsEnabled &&
                this.props.searchState.launchedFrom === LAUNCHED_SEARCH_FROM.SCREENING_PAGE
            ) {
                const currentPostFilters = this.getAllPostFilters();
                const postFilterEqual = isEqual(initialPostFilters, currentPostFilters);

                if (!postFilterEqual) {
                    this.setState({
                        showLeaveModal: true,
                        leaveNextLocation: nextLocation,
                    });
                    return false;
                }
            }
        }
        return true;
    };

    saveChanges = () => {
        this.setState({
            showLeaveModal: false,
        });
        this.saveSearchAction();
    };

    discardChanges = () => {
        this.setState({
            showLeaveModal: false,
        });
        this.pushRedirectAfter();
    };

    pushRedirectAfter = () => {
        let locationNeedsUpdates = [
            BREADCRUMBS_PREV_PATH.HISTORY_PAGE,
            ROUTES.USER_PREFERENCES,
            ROUTES.ADMIN_PREFERENCES,
        ];

        if (this.state.leaveNextLocation) {
            this.props.showSaveSearchModalBeforeLeave &&
                reduxStore.dispatch(searchStatusActions.showSaveSearchModal(false));

            if (locationNeedsUpdates.indexOf(this.state.leaveNextLocation.pathname) > -1) {
                this.props.updatePrevPath(this.props.location);
                this.props.resetLaunchedFrom();
            }
            hashHistory.push(this.state.leaveNextLocation);

            this.setState({
                leaveNextLocation: null,
            });
        }

        // logOut was clicked
        if (this.props.showSaveSearchModalBeforeLeave === SAVE_SEARCH_LEAVE_ACTION.logout) {
            let queue = DilQueue();
            const logoutArgs = {
                adminPermissionChanged: this.props.adminPermissionChanged,
            };

            queue.abortAllTasks();
            if (this.state.unregisterLeaveHook) this.state.unregisterLeaveHook();
            commonApi.userLogOut(logoutArgs);
            this.props.updateBreadcrumbs([]);
        }

        if (this.props.showSaveSearchModalBeforeLeave === SAVE_SEARCH_LEAVE_ACTION.searchBarHeader) {
            this.saveCurrentPostFilters();
        }

        this.props.setPostFiltersChanged(false);
        reduxStore.dispatch(searchStatusActions.showSaveSearchModal(false));
    };

    closeLeaveModal: Function = () => {
        this.setState({
            showLeaveModal: false,
            leaveNextLocation: null,
        });
        this.props.showSaveSearchModalBeforeLeave && reduxStore.dispatch(searchStatusActions.showSaveSearchModal(null));
    };

    getDataFromProps() {
        return {
            user: this.props.user,
            investigation: this.props.investigation,
            useNewResearchSummary: this.props.useNewResearchSummary,
            contentTypes: this.props.contentTypes,
            searchResults: this.props.searchResults,
            postFiltersConfig: this.props.searchResults,
            searchParams: this.props.searchParams,
            popupModel: this.props.popupModel,
            viewId: this.props.viewId
        };
    }

    getAllPostFilters() {
        const { searchResults } = this.props;
        const postFilters = {};
        Object.keys(searchResults).forEach(function (key) {
            postFilters[key] = searchResults[key].postFilters;
        });

        return postFilters;
    }

    saveCurrentPostFilters() {
        this.setState({
            initialPostFilters: this.getAllPostFilters(),
        });
    }

    render(): React$Node {
        const {
            searchResults,
            articleType,
            searchParams,
            adHocNewsSource,
            preferencesNewsSource,
            personCheck,
            userLocks,
            enforceAlertsLimit,
            activeAlertsCount,
            activeAlertsLimit,
            intl,
            personNegativityLevel,
            companyNegativityLevel,
            selectedArticles,
            uploadingDocuments,
            language,
            isBatchReportsEnabled,
            areDocumentsLoading,
            isSavingSearch,
            isAddingToEntityView,
            showSaveSearchModalBeforeLeave,
            searchState: { launchedFrom },
        } = this.props;

        let postFilters = searchResults[articleType].postFilters;
        let categorySettings = this.getPostFilterData();
        let mergedQuery = utils.mergeQueryAndBoolean(utils.sanitizeSearchStringInput(searchParams.query), searchParams.prefilterQuery);
        let contentLanguage;
        if (categoryUtils.isChildOf(CATEGORY_NAMES.NEGATIVE_NEWS, articleType)) {
            contentLanguage = categoryUtils.getCategory(articleType).extendConfig().contentLanguage;
        } else {
            contentLanguage = this.getLanguagesForCustomNews(articleType);
        }

        const newsSource = adHocNewsSource || preferencesNewsSource;
        const { fuzzyOn, fuzzyThreshold } = Object.prototype.hasOwnProperty.call(searchResults, articleType)
            ? searchResults[articleType].postFilters
            : personCheck;
        const { fuzzyThresholdPerson, masterLockNewsSearchesPerson } = userLocks;
        const isFuzzyThresholdLocked = fuzzyThresholdPerson || masterLockNewsSearchesPerson;
        const isSearchRerun = this.isSearchRerun();
        const isHistoryCategoryCurrentOne = this.isHistoryCategoryCurrentOne();
        const alertsLimitReached = enforceAlertsLimit && activeAlertsCount >= activeAlertsLimit;

        let buttons = [
            {
                text: formatRichMessage({ id: 'General_CoreFunctionality_UIText_general.ok' }, intl),
                primary: true,
                click: this.closeModal,
            },
        ];
        const defaultNegativityLevels =
            searchParams.searchType === PERSON_SEARCH ? personNegativityLevel : companyNegativityLevel;
        const selectedArticlesList = this.getSelectedArticles(selectedArticles, articleType);
        const everyAvailbleCategory = Object.keys(searchResults).filter((key) => searchResults[key].available);
        const everyCategoryLoaded = everyAvailbleCategory.every((key) => searchResults[key].loaded);
        const isLoaded = everyCategoryLoaded && !areDocumentsLoading && !isAddingToEntityView;

        const leaveModalButtons = [
            {
                text: formatRichMessage({ id: 'General.label.saveChanges' }, intl),
                primary: true,
                click: this.saveChanges,
            },
            {
                text: formatRichMessage({ id: 'PopupBuilder.buttonLabel.discardChanges' }, intl),
                primary: false,
                click: this.discardChanges,
            },
        ];

        const shouldShowLeaveModal = this.state.showLeaveModal || showSaveSearchModalBeforeLeave;
        const shouldDisplaySaveSearchButton = utils.shouldDisplaySaveSearchButton(
            isBatchReportsEnabled,
            launchedFrom,
            LAUNCHED_SEARCH_FROM.RESULTS_PAGE
        );

        return (
            <div className="results-list-container">
                {this.state.showEditModal && (
                    <ConfirmationModal closeButtonHandler={this.closeModal} buttons={buttons}>
                        <h2 className="modal-header">
                            <FormattedMessage id={this.state.confirmationModalTitle} />
                        </h2>
                        <div className="modal-description font-body">
                            <FormattedMessage id={this.state.confirmationModalDescription} />
                        </div>
                    </ConfirmationModal>
                )}
                {shouldShowLeaveModal && (
                    <ConfirmationModal
                        closeButtonHandler={this.closeLeaveModal}
                        buttons={leaveModalButtons}
                        className="modal-leave-search"
                    >
                        <h2 className="modal-header">
                            <FormattedMessage id="General.label.saveChanges" />
                        </h2>
                        <div className="modal-description font-body">
                            <FormattedMessage id="PopupBuilder.description.saveSearchFromScreening" />
                        </div>
                    </ConfirmationModal>
                )}
                <ResultsListHeader
                    searchType={searchParams.searchType}
                    articleType={articleType}
                    searchedQueryString={mergedQuery}
                    totalArticleCount={this.getTotalArticleCount()}
                    uploadingDocuments={uploadingDocuments}
                    language={language}
                    alertsLimitReached={alertsLimitReached}
                    activeAlertsLimit={activeAlertsLimit}
                    openSaveSearchPopup={this.openSaveSearchPopup}
                    isSavingSearch={isSavingSearch}
                    isSaveSearchButtonDisabled={!searchResults[articleType].loaded || areDocumentsLoading}
                    prevPath={this.props.prevPath}
                    updatePrevPath={this.props.updatePrevPath}
                    location={this.props.location}
                    shouldAddToEntityViewButtonDisplay={isBatchReportsEnabled}
                    shouldDisplaySaveSearchButton={shouldDisplaySaveSearchButton}
                    addEntityToEntityView={this.addEntityToEntityView}
                    isAddToEntityViewButtonDisabled={
                        !this.props.searchResults[this.props.articleType].loaded || this.props.areDocumentsLoading
                    }
                    isSearchComplete={this.props.isSearchComplete}
                />
                <div className="results-list-subcontainer">
                    {!isLoaded && <OverlaySpinner className="category-overlay" size="massive" />}
                    <div className="search-filter-column">
                        <SearchTypeFilter
                            articleType={this.props.articleType}
                            searchType={this.props.searchParams.searchType}
                            includeTerms={postFilters.includeTerms}
                            excludeTerms={postFilters.excludeTerms}
                            searchResults={this.props.searchResults}
                            handleSelectCategory={this.handleSelectCategory}
                            onPostfilterChange={this.props.onPostfilterChange}
                            onSearchWhithinChange={this.props.onSearchWhithinChange}
                            searchWithin={this.searchWithin}
                            isOpenLegalCategory={this.state.isOpenLegalCategory}
                            singleArticle={this.props.singleArticle}
                            postFiltersConfig={this.props.postFiltersConfig}
                            showFilters={this.props.showFilters}
                            generalSettings={this.props.generalSettings}
                            language={this.props.language}
                            adHocSearch={this.props.adHocSearch}
                            contentLanguage={contentLanguage}
                            addTerms={this.props.addTerms}
                            updatePostFilterConfig={this.props.updatePostFilterConfig}
                            updatePostFilterConfigValue={this.props.updatePostFilterConfigValue}
                            onSortPostFilters={this.props.updatePostFiltersSort}
                            resetPostFilterConfigValues={this.props.resetPostFilterConfigValues}
                            updatePostFilterForCategory={this.props.updatePostFilterForCategory}
                            resetPostFilterToDefaultValues={this.props.resetPostFilterToDefaultValues}
                            updateCategoryFlag={this.props.updateCategoryFlag}
                            isSearchRerun={isSearchRerun}
                            historyCategory={this.props.historyCategory}
                            useNewResearchSummary={this.props.useNewResearchSummary}
                            searchCategory={this.searchCategory}
                            updatePostFilterTreeData={this.updatePostFilterTreeData}
                            user={this.props.user}
                            postFilterContainerRef={this.postFilterContainerRef}
                            shouldUpdateCustomDatesState={this.state.shouldUpdateCustomDatesState}
                            updateCustomDatesBasedOnNNV={this.updateCustomDatesBasedOnNNV}
                            isFilterSectionCollapsed={this.props.isFilterSectionCollapsed}
                            toggleFilterSectionVisibility={this.toggleFilterSectionVisibility}
                            toggleFilterVisibilityButtonRef={this.toggleFilterVisibilityButtonRef}
                            expandedFiltersSection={this.props.expandedFiltersSection}
                            updateExpandedFiltersSection={this.props.updateExpandedFiltersSection}
                        />
                    </div>
                    <div
                        className={`results-content-column ${
                            this.props.isFilterSectionCollapsed ? 'expanded-view' : ''
                        }`}
                        ref={this.resultsListContentRef}
                    >
                        <ActiveFilterList
                            searchType={this.props.searchParams.searchType}
                            articleType={this.props.articleType}
                            report={this.props.report}
                            searchResults={this.props.searchResults}
                            includeTerms={postFilters.includeTerms}
                            excludeTerms={postFilters.excludeTerms}
                            removeFilter={this.removeFilter}
                            resetFilter={this.resetFilter}
                            isArticleListLoaded={this.props.articleList.loaded}
                            contentLanguage={contentLanguage}
                            similarNames={categorySettings ? categorySettings[POSTFILTER_TYPE.SIMILAR_NAMES] : null}
                            defaultSimilarNames={categorySettings ? categorySettings.defaultSimilarNames : null}
                            suggestedNames={categorySettings ? categorySettings[POSTFILTER_TYPE.SUGGESTED_NAMES] : null}
                            defaultSuggestedNames={categorySettings ? categorySettings.defaultSuggestedNames : null}
                            dateRange={categorySettings ? categorySettings[POSTFILTER_TYPE.DATE_RANGE] : null}
                            defaultDateRange={categorySettings ? categorySettings.defaultDateRange : null}
                            proximity={
                                categorySettings ? categorySettings[POSTFILTER_TYPE.PROXIMITY_NEGATIVE_TERMS] : null
                            }
                            defaultProximity={categorySettings ? categorySettings.defaultProximityNegativeTerms : null}
                            company={categorySettings ? categorySettings[POSTFILTER_TYPE.COMPANY] : null}
                            defaultCompany={categorySettings ? categorySettings.defaultCompany : null}
                            companyMentions={
                                categorySettings ? categorySettings[POSTFILTER_TYPE.COMPANY_MENTIONS] : null
                            }
                            personMentions={categorySettings ? categorySettings[POSTFILTER_TYPE.PERSON_MENTIONS] : null}
                            source={categorySettings ? categorySettings[POSTFILTER_TYPE.SOURCE] : null}
                            defaultSource={categorySettings ? categorySettings.defaultSource : null}
                            sourceName={categorySettings ? categorySettings[POSTFILTER_TYPE.SOURCE_NAME] : null}
                            defaultSourceName={categorySettings ? categorySettings.defaultSourceName : null}
                            sourceType={categorySettings ? categorySettings[POSTFILTER_TYPE.SOURCE_TYPE] : null}
                            defaultSourceType={categorySettings ? categorySettings.defaultSourceType : null}
                            language={categorySettings ? categorySettings[POSTFILTER_TYPE.LANGUAGE] : null}
                            defaultLanguage={categorySettings ? categorySettings.defaultLanguage : null}
                            geography={categorySettings ? categorySettings[POSTFILTER_TYPE.GEOGRAPHY] : null}
                            defaultGeography={categorySettings ? categorySettings.defaultGeography : null}
                            docketStatus={categorySettings ? categorySettings[POSTFILTER_TYPE.DOCKET_STATUS] : null}
                            dunsFilter={categorySettings ? categorySettings[POSTFILTER_TYPE.DUNS_FILTER] : null}
                            subject={categorySettings ? categorySettings[POSTFILTER_TYPE.SUBJECT] : null}
                            defaultSubject={categorySettings ? categorySettings.defaultSubject : null}
                            industry={categorySettings ? categorySettings[POSTFILTER_TYPE.INDUSTRY] : null}
                            defaultIndustry={categorySettings ? categorySettings.defaultIndustry : null}
                            fuzzySearch={categorySettings ? categorySettings[POSTFILTER_TYPE.FUZZY_SEARCH] : null}
                            fuzzyThreshold={fuzzyThreshold}
                            fuzzyOn={fuzzyOn}
                            fuzzyThresholdPersonLock={isFuzzyThresholdLocked}
                            newFuzzyEnabled={this.props.newFuzzyEnabled}
                            suggestedNamesEnabled={this.props.suggestedNamesEnabled}
                            uniqueId={this.props.uniqueId}
                            negativityLevels={
                                categorySettings ? categorySettings[POSTFILTER_TYPE.NEGATIVITY_LEVELS] : null
                            }
                            defaultNegativityLevels={defaultNegativityLevels}
                            terms={postFilters.terms}
                            esgFactors={categorySettings ? categorySettings[POSTFILTER_TYPE.ESG_FACTORS] : null}
                            defaultEsgFactors={categorySettings ? categorySettings.defaultEsgFactors : null}
                        />
                        <div className="results-list-content">
                            <ArticleActions
                                {...this.props}
                                selectAllCheckboxState={this.getSelectCheckboxState()}
                                changeSorting={this.changeSorting}
                                checkAllArticleHandler={this.checkAllArticleHandler}
                                selectedArticlesCount={this.getSelectedArticlesCount()}
                                totalArticleCount={this.getTotalArticleCount()}
                                sort={postFilters.sort}
                                contentsOptions={this.props.contentsOptions}
                                showDeliveryOptions={this.props.showDeliveryOptions}
                                newsSource={newsSource}
                                onNegativeNewsTimelineChange={this.onNegativeNewsTimelineChange}
                                onNegativityLevelChange={this.onNegativityLevelChange}
                                personNegativityLevel={this.props.personNegativityLevel}
                                companyNegativityLevel={this.props.companyNegativityLevel}
                                isHistoryCategoryCurrentOne={isHistoryCategoryCurrentOne}
                                onNegativeTermsClick={this.onNegativeTermsClick}
                                prevPath={this.props.prevPath}
                                updatePrevPath={this.props.updatePrevPath}
                                showTrendsGraphTemporarily={this.state.showTrendsGraphTemporarily}
                                disableTrendsGraphCloseOnScroll={this.disableTrendsGraphCloseOnScroll}
                                toggleShowTrendsGraphTemporarily={this.toggleShowTrendsGraphTemporarily}
                            />
                            <div className="result-list-sidebar-main-content">
                                {isLoaded && (
                                    <div className="article-results">
                                        {(this.props.articleList.loaded && isUboCategory(this.props.articleList)) ||
                                        this.props.articleType === CATEGORY_NAMES.ESG_RATINGS ? (
                                            <CustomDocumentsList
                                                {...this.props}
                                                showSingleArticle={this.showSingleArticle}
                                                loadMoreArticles={this.loadMoreArticles}
                                                pageSize={this.props.pageSize}
                                                handlePageSizeChange={this.handlePageSizeChange}
                                                selectedArticlesList={selectedArticlesList}
                                                setSelectedArticlesInfo={this.props.setSelectedArticlesInfo}
                                            />
                                        ) : (
                                            <ElasticDocumentsList
                                                {...this.props}
                                                showSingleArticle={this.showSingleArticle}
                                                loadMoreArticles={this.loadMoreArticles}
                                                pageSize={this.props.pageSize}
                                                handlePageSizeChange={this.handlePageSizeChange}
                                                setVisibleDuplicates={this.setVisibleDuplicates}
                                                setSelectedDuplicates={this.props.setSelectedDuplicates}
                                                showDuplicatesState={this.state.showDuplicatesState}
                                                setDuplicatesSectionState={this.setDuplicatesSectionState}
                                                setDuplicatesForArticle={this.setDuplicatesForArticle}
                                                searchType={this.props.searchParams.searchType}
                                                duplicateArticles={this.state.duplicates}
                                                handlePreviewArticle={this.handlePreviewArticle}
                                                singleSource={utils.isSingleSourceSearch(
                                                    this.props.postFiltersConfig[this.props.articleType]
                                                )}
                                                newFuzzyEnabled={this.props.newFuzzyEnabled}
                                                personCheck={this.props.personCheck}
                                                selectedArticlesList={selectedArticlesList}
                                                language={this.props.language}
                                                setSelectedArticlesInfo={this.props.setSelectedArticlesInfo}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state, ownProps) {
    return {
        searchResults: { ...state.searchResults, ...(ownProps.searchResults || {}) },
        isSearchComplete: state.searchStatus.isSearchComplete,
        searchParams: { ...state.searchParams, ...(ownProps.searchParams || {}) },
        report: state.currentReport,
        uploadingDocuments: state.articlesManager.uploadingDocuments,
        selectedArticles: state.articlesManager.selectedArticles,
        selectedDuplicates: state.articlesManager.selectedDuplicates,
        previousScrollY: state.scrollingPage.searchPage,
        isSnapshotVisible: state.user.preferences.generalSettings.showSnapshot,
        pageSize: state.user.preferences.generalSettings.pageSize,
        sort: state.user.preferences.generalSettings.sort,
        fuzzyNames: state.fuzzyNames.list,
        suggestedNames: state.suggestedNames.list,
        suggestedNamesObject: state.suggestedNames,
        postFiltersConfig: state.postFilterConfiguration,
        generalSettings: state.user.preferences.generalSettings,
        language: state.user.preferences.language,
        adHocSearch: state.adHocSearch,
        preferences: state.user.preferences,
        billingId: state.investigation.billingId,
        singleArticle: state.reportBuilder.singleArticle,
        includeTerms: state.searchParams.includeTerms,
        excludeTerms: state.searchParams.excludeTerms,
        historyCategory: state.historyCategory,
        showFilters: true || state.user.preferences.generalSettings.showFilters,
        prevPath: state.breadcrumbs.prevPath,
        contentsOptions: state.user.preferences.resultsContentOptions,
        showDeliveryOptions: state.user.preferences.resultsListShowDeliveryOptions,
        adHocNewsSource: state.adHocSearch.newsSearchSources,
        preferencesNewsSource: state.user.preferences[PREFERENCES_KEY[state.searchParams.searchType]].newsSearchSources,
        useNewResearchSummary: state.user.useNewResearchSummary,
        investigation: state.investigation,
        personCheck: state.user.preferences.personCheck,
        userLocks: state.user.preferences.userLocks,
        newFuzzyEnabled: state.user.newFuzzyEnabled,
        suggestedNamesEnabled: state.user.suggestedNamesEnabled,
        user: state.user,
        viewId: state.user.preferences.screeningEntity.lastSelectedView,
        useNegativeNewsVisualisationsFeature: state.user.useNegativeNewsVisualisationsFeature,
        isTrendsExpanded: state.user.preferences.generalSettings.isTrendsExpanded,
        personNegativityLevel: state.user.preferences.personCheck.negativityLevel,
        companyNegativityLevel: state.user.preferences.companyCheck.negativityLevel,
        isFilterSectionCollapsed: state.user.preferences.generalSettings.isFilterSectionCollapsed,
        areDocumentsLoading: state.searchStatus.areDocumentsLoading,
        enforceAlertsLimit: state.user.enforceAlertsLimit,
        activeAlertsLimit: state.user.preferences.generalSettings.activeAlertsLimit,
        activeAlertsCount: state.alerts.activeAlertsCount,
        popupModel: state.popupModel,
        isBatchReportsEnabled: state.user.appSettings.isBatchReportsEnabled,
        isSavingSearch: state.searchStatus.isSavingSearch,
        isAddingToEntityView: state.searchStatus.isAddingToEntityView,
        showSaveSearchModalBeforeLeave: state.searchStatus.showSaveSearchModalBeforeLeave,
        contentTypes: state.user.preferences.generalSettings.contentTypes,
        searchState: state.searchState,
        adminPermissionChanged: state.user.adminPermissionChanged,
        arePostFiltersChanged: state.searchStatus.arePostFiltersChanged,
        googleTranslateStatus: state.user.preferences.googleTranslateStatus,
        expandedFiltersSection: state.user.preferences.resultListPage.expandedFiltersSection
    };
};

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(
        {
            initArticleNavigation: ArticleNavigationActions.initArticleNavigation,
            setSelectedArticle: articlesManagerActions.selectArticle,
            setSortOption: searchResultsActions.setSortOption,
            setLastSort: searchResultsActions.setLastSort,
            setSelectedArticles: articlesManagerActions.selectArticles,
            setSelectedDuplicates: articlesManagerActions.selectDuplicates,
            clearSelectedArticles: articlesManagerActions.clearSelectedArticles,
            clearSelectedArticle: articlesManagerActions.clearSelectedArticle,
            clearSelectedDuplicates: articlesManagerActions.clearSelectedDuplicates,
            setUploadState: articlesManagerActions.setUploadState,
            setCurrentReport: currentReportActions.setCurrentReport,
            updateBreadcrumbs: breadcrumbsActions.updateBreadcrumbs,
            removeTerms: searchResultsActions.removeTerms,
            clearCategoryTerms: searchResultsActions.clearCategoryTerms,
            setScrollY: scrollingPageActions.setScrollY,
            updatePageSize: userPreferencesActions.updatePageSize,
            updateGeneralSort: userPreferencesActions.updateSort,
            updateFilterSectionVisibility: userPreferencesActions.updateFilterSectionVisibility,
            resetPostFilterConfigValues: postFilterConfigActions.resetPostFilterConfigValues,
            resetToDefaultAllPostFiltersConfigValues: postFilterConfigActions.resetToDefaultAllPostFiltersConfigValues,
            resetPostFilterToDefaultValues: searchResultsActions.resetPostFilterToDefaultValues,
            resetAllPostFiltersToDefaultValues: searchResultsActions.resetAllPostFiltersToDefaultValues,
            addTerms: searchResultsActions.addTerms,
            updatePostFilterConfig: postFilterConfigActions.updatePostFilterConfigValues,
            updatePostFilterConfigValue: postFilterConfigActions.updatePostFilterConfigDefaultValue,
            updatePostFiltersSort: postFilterConfigActions.updatePostFiltersSort,
            updatePostFilterForCategory: searchResultsActions.updatePostFilterForCategory,
            updateCategoryFlag: searchResultsActions.updateCategoryProperty,
            searchDocAccessEvent: articleNavigationActions.searchDocAccessEvent,
            clearCategoryNegativeTerms: searchResultsActions.clearCategoryNegativeTerms,
            markCategoryAsVisited: investigationActions.markVisitedCategories,
            setFiltersAppliedForCategory: searchResultsActions.setFiltersAppliedForCategory,
            toggleDocumentsLoadingStatus: searchStatusActions.toggleDocumentsLoadingStatus,
            setActiveAlertsCount: manageAlertsActions.setActiveAlertsCount,
            updatePrevPath: breadcrumbsActions.updatePrevPath,
            resetLaunchedFrom: mainActions.resetLaunchFrom,
            setSelectedArticlesInfo: articlesManagerActions.setSelectedArticlesInfo,
            setPostFiltersChanged: searchStatusActions.setPostFiltersChanged,
            updateExpandedFiltersSection: userPreferencesActions.updateExpandedFiltersSection,
        },
        dispatch
    );
};

export { ResultsList as TestResultsList };
export default (compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withAppContext,
    injectIntl,
    withPreferenceRefresh,
    withSliderAnimation,
    withHistoryEvents(HistoryEventsProvider),
    withInvestigationEvents(InvestigationEventsProvider)
)(ResultsList): React.AbstractComponent<Props, State>);
