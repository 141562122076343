import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import formatRichMessage from 'scripts/utils/formatRichMessage';

const UNKNOWN = 'Unknown';

export class SanctionsRiskTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hoveredRow: null
        };
    }

    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text === UNKNOWN ? <FormattedMessage id="SanctionsAndWatchlists.risk.table.unknown" defaultMessage={UNKNOWN} /> : uncertainCell.text;
        return { ...uncertainCell, text };
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    handleMouseEnter = (rowIndex) => {
        this.setState({ hoveredRow: rowIndex });
    }

    handleMouseLeave = () => {
        this.setState({ hoveredRow: null });
    }

    render(cell) {
        const { text, rowIndex } = cell;
        const { content, onClick } = text;
        const riskLevel = content;
        const isHovered = this.state.hoveredRow === rowIndex;

        return (
            <div>
                { riskLevel && (
                    <ReactTooltip
                        id={`sanctions-risk-tooltip ${rowIndex}`}
                        event="mouseenter"
                        eventOff="mouseleave"
                        type="light"
                        border={true}
                        effect="solid"
                        place="top"
                        multiline={true}
                        html={true}
                        className="tooltips"
                    />
                )}

                <div
                    className={`sanctions-table-risk${isHovered ? ' hovered' : ''}`}
                    onClick={onClick}
                    onMouseEnter={() => this.handleMouseEnter(rowIndex)}
                    onMouseLeave={this.handleMouseLeave}
                    title={riskLevel}
                >
                    <div className="risk-score-container">
                        <div className={`doughnut ${riskLevel.toLowerCase()}`} />
                        <p
                            className="risk-score"
                            data-testid={`sw-table-risk-${rowIndex}`}
                            data-for={`sanctions-risk-tooltip-${rowIndex}`}
                            data-tip={formatRichMessage({
                                id: `SanctionsSnapshot.label.${riskLevel.toLowerCase()}`,
                            })}
                        >
                            <FormattedMessage id={`SanctionsSnapshot.label.${riskLevel.toLowerCase()}`} />
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
