import React, { Fragment } from 'react';
import ElasticDocumentItem from './ElasticDocumentItem';
import NoResultsDocumentItem from './NoResultsDocumentItem';
import ResultListError from './ResultListError';
import ReactPaginate from 'react-paginate';
import PageSizeSelector from '@reusable/PageSizeSelector/PageSizeSelector';
import { PROMISE_CANCELED } from '@utils/promiseQueue';
import errorUtils from '@utils/errors/error-utils';
import { POST_FILTER_FUZZY_SEARCH, POST_FILTER_FUZZY_THRESHOLD } from '@constants';

const ElasticDocumentsList = (props) => {

    let showErrorMessage = props.articleList.hasError && props.articleList.errorMessage !== PROMISE_CANCELED;
    let errorCode = errorUtils.failedCategoriesCollector.getCategoryKeyErrorCode(props.articleType);
    let noArticlesLoaded =
        props.articleList &&
        props.articleList.count === 0 &&
        props.articleList.loaded === true &&
        props.articleList.hasError !== true;
    if (noArticlesLoaded) {
        return (
            <NoResultsDocumentItem
                article={props.articleList.articles[0] || {}}
                articleType={props.articleType}
                searchType={props.searchType}
            />
        );
    } else {
        let pageNumbers = Math.ceil(props.articleList.count / props.pageSize),
            articleType = props.articleType;

        const fuzzyThreshold = props.searchResults[articleType].postFilters[POST_FILTER_FUZZY_SEARCH]
            ? props.searchResults[articleType].postFilters[POST_FILTER_FUZZY_THRESHOLD] ||
              props.personCheck.fuzzyThreshold
            : '';

        const articleList = props.articleList.articles.map((article, index) => {
            const isArticleItemSelected = props.selectedArticlesList.some(
                (articleElement) => articleElement.id === article.id
            );

            return (
                <ElasticDocumentItem
                    key={articleType + index}
                    searchParams={props.searchParams}
                    selectedArticles={props.selectedArticles || []}
                    selectedDuplicates={props.selectedDuplicates || []}
                    setSelectedArticles={props.setSelectedArticles}
                    setSelectedDuplicates={props.setSelectedDuplicates}
                    index={index + props.pageSize * props.articleList.currentPageIndex}
                    article={article}
                    articleType={articleType}
                    report={props.report}
                    showSingleArticle={props.showSingleArticle}
                    setVisibleDuplicates={props.setVisibleDuplicates}
                    showDuplicatesState={props.showDuplicatesState}
                    setDuplicatesSectionState={props.setDuplicatesSectionState}
                    setDuplicatesForArticle={props.setDuplicatesForArticle}
                    duplicateArticles={props.duplicateArticles}
                    proximity={props.searchResults[articleType].postFilters.proximity || ''}
                    includeTerms={props.searchResults[articleType].postFilters.includeTerms || []}
                    handlePreviewArticle={props.handlePreviewArticle}
                    singleSource={props.singleSource}
                    fuzzyThreshold={fuzzyThreshold}
                    newFuzzyEnabled={props.newFuzzyEnabled}
                    terms={props.searchResults[articleType].postFilters.terms || []}
                    selectedArticlesList={props.selectedArticlesList}
                    isArticleItemSelected={isArticleItemSelected}
                    language={props.language}
                    setSelectedArticlesInfo={props.setSelectedArticlesInfo}
                />
            );
        });

        return (
            <Fragment>
                <ol className="article-list">{articleList}</ol>
                {errorCode && showErrorMessage && (
                    <ResultListError category={props.articleType} errorCode={errorCode} />
                )}
                {props.articleList.loaded && (
                    <div className="pagination-container">
                        <ReactPaginate
                            previousLabel={''}
                            nextLabel={''}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            forcePage={props.articleList.currentPageIndex}
                            pageCount={pageNumbers}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={4}
                            onPageChange={props.loadMoreArticles}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                            nextLinkClassName={'next'}
                        />
                        <PageSizeSelector pageSize={props.pageSize} handlePageSizeChange={props.handlePageSizeChange} />
                    </div>
                )}
            </Fragment>
        );
    }
};

export default ElasticDocumentsList;
