import {useEffect, useState} from "react";

const useNotificationDisplayOrder = (
    createNotificationHidden,
    copyNotificationHidden,
    deleteNotificationHidden,
    refreshNotificationHidden,
    uploadNotificationHidden,
    downloadNotificationHidden,
) => {
    //this is the order in which the notifications will be displayed
    const [notificationDisplayOrder, setNotificationDisplayOrder] = useState([
        'createAlertsStatus',
        'copyEntitiesStatus',
        'deleteEntitiesStatus',
        'uploadEntitiesStatus',
        'refreshEntitiesStatus',
    ]);

    const handleAddedNotification = (addedNotification) => {
        const filteredArray = notificationDisplayOrder.filter((notification) => notification !== addedNotification);
        const updatedArray = [addedNotification, ...filteredArray];
        setNotificationDisplayOrder(updatedArray);
    }

    //These useEffect hooks update the notificationDisplayOrder list based on the latest notification that was added
    useEffect(() => {handleAddedNotification( 'createAlertsStatus');} , [createNotificationHidden]);
    useEffect(() => {handleAddedNotification('copyEntitiesStatus');}, [copyNotificationHidden]);
    useEffect(() => {handleAddedNotification('deleteEntitiesStatus');}, [deleteNotificationHidden]);
    useEffect(() => {handleAddedNotification('refreshEntitiesStatus');}, [refreshNotificationHidden]);
    useEffect(() => {handleAddedNotification('uploadEntitiesStatus');}, [uploadNotificationHidden]);
    useEffect(() => {handleAddedNotification('downloadEntitiesStatus');}, [downloadNotificationHidden]);

    return notificationDisplayOrder;
};

export default useNotificationDisplayOrder;