import React from 'react';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import ReportBuilderApi from '@pages/ReportBuilder/ReportBuilderMain.api';
import reduxStore from '@reduxStore';
import { batch } from 'react-redux';
import backgroundActions from '@reusable/BackgroundMessage/redux/BackgroundMessages.actions';
import popupActions from '@reusable/MessagePopup/redux/MessagePopup.actions';
import {
    ACTION_TYPES,
    BACKGROUND_MESSAGE_TYPE_DOWNLOAD,
    MESSAGE_TIMEOUT,
    POLLING_TIMEOUT,
    POLLING_BATCH_TIMEOUT,
    REPORT_DELIVERY_STATUSES,
    POLLING_BATCH_SCREENING_TIMEOUT,
    POLLING_STATUS,
    POLLING_ENTITIES_UPLOAD,
    POLLING_ENTITIES_REFRESH,
    NOTIFICATION_STORE_KEYS,
    EMPTY_POLLING_CHECK_LIMIT,
} from '@constants';
import reportBuilderActions from '@pages/ReportBuilder/redux/ReportBuilder.action';
import * as printUtils from '../../printUtils';
import utils from '@utils/utilities';
import EntityViewApi from '@pages/EntityView/api/EntityViewApi';
import AlertApi from 'scripts/pages/Alerts/AlertsApi.api';
import mainActions from '@pages/Main/Main.actions';
import manageAlertsActions from 'scripts/pages/ManageAlerts/redux/ManageAlerts.actions';
import { cloneDeep } from 'lodash';

let pollEntityAlertsInterval, pollEntitiesCountsInterval, pollInProgressEntitiesDataInterval;

const notificationService = {
    pollReport(reportId, buildId, reportTitle, dispatch = reduxStore.dispatch) {
        const intervalTimeId = setInterval(async () => {
            const [buildStatus, error] = await ReportBuilderApi.getReportStatus(reportId, buildId);

            if (buildStatus) {
                dispatch(reportBuilderActions.updateReportProperty(reportId, 'buildStatus', buildStatus.status));

                switch (buildStatus.status) {
                    case REPORT_DELIVERY_STATUSES.FINISHED:
                        clearInterval(intervalTimeId);
                        dispatch(reportBuilderActions.updateReportDelivery(reportId));
                        dispatch(
                            backgroundActions.setSuccessBackgroundMessages({
                                title: 'DeliveryService.downloadReady',
                                message: 'DynamicMessage',
                                messageParameters: reportTitle,
                                parameters: { reportId },
                                link: '/api/reportdelivery/report/download/' + reportId + '/' + buildId,
                            })
                        );
                        break;

                    case REPORT_DELIVERY_STATUSES.ERROR_REPORT_TOO_BIG:
                        clearInterval(intervalTimeId);
                        dispatch(reportBuilderActions.updateReportDelivery(reportId));
                        dispatch(
                            backgroundActions.setFailureBackgroundMessages({
                                title: 'DeliveryService.downloadArticleFail.title',
                                message: 'DeliveryService.downloadReport.description.reportToBig',
                                actionLink: '/report-builder' + '?reportId=' + reportId,
                                buttons: true,
                            })
                        );
                        break;

                    case REPORT_DELIVERY_STATUSES.ERROR:
                    case REPORT_DELIVERY_STATUSES.FAILED_RETRY:
                        clearInterval(intervalTimeId);
                        dispatch(reportBuilderActions.updateReportDelivery(reportId));
                        dispatch(
                            backgroundActions.setFailureBackgroundMessages({
                                title: 'DeliveryService.downloadArticleFail.title',
                                message: 'DeliveryService.downloadArticleFail.description2',
                            })
                        );
                        break;
                }
            }

            if (error) {
                dispatch(
                    backgroundActions.setFailureBackgroundMessages({
                        title: 'DeliveryService.downloadArticleFail.title',
                        message: 'DeliveryService.downloadArticleFail.description2',
                    })
                );
            }
        }, POLLING_TIMEOUT);
    },

    pollBatchReport(reportId, buildId, reportTitle, dispatch = reduxStore.dispatch) {
        const intervalTimeId = setInterval(async () => {
            const [buildStatus, error] = await ReportBuilderApi.getReportStatus(reportId, buildId);
            if (buildStatus) {
                dispatch(reportBuilderActions.updateReportProperty(reportId, 'buildStatus', buildStatus.status));

                switch (buildStatus.status) {
                    case REPORT_DELIVERY_STATUSES.ZIP_GENERATED:
                    case REPORT_DELIVERY_STATUSES.ZIP_GENERATED_WITH_ERRORS:
                        clearInterval(intervalTimeId);
                        dispatch(reportBuilderActions.updateReportDelivery(reportId));
                        dispatch(reportBuilderActions.removeBatchReportFromPooling(reportId));
                        utils.showNotificationsMessage({
                            messageText: 'BatchUpload.notification.reportReadyMessage',
                            messageType: 'success',
                            terms: {
                                reportTitle,
                                navLink: (
                                    <Link
                                        className="navigation-link"
                                        to="/report-builder"
                                        onClick={popupActions.removeMessage}
                                    >
                                        <FormattedMessage id="BatchUpload.notification.navigationLink" />
                                    </Link>
                                ),
                            },
                        });
                        break;
                    case REPORT_DELIVERY_STATUSES.ZIP_NOT_GENERATED:
                        clearInterval(intervalTimeId);
                        dispatch(reportBuilderActions.updateReportDelivery(reportId));
                        dispatch(reportBuilderActions.removeBatchReportFromPooling(reportId));
                        utils.showNotificationsMessage({
                            messageText: 'BatchUpload.notification.allFinishedWithErrors',
                            messageType: 'system-error',
                        });
                        break;

                    case REPORT_DELIVERY_STATUSES.ERROR_REPORT_TOO_BIG:
                        clearInterval(intervalTimeId);
                        dispatch(reportBuilderActions.updateReportDelivery(reportId));
                        dispatch(reportBuilderActions.removeBatchReportFromPooling(reportId));
                        dispatch(
                            backgroundActions.setFailureBackgroundMessages({
                                title: 'DeliveryService.downloadArticleFail.title',
                                message: 'DeliveryService.downloadReport.description.reportToBig',
                                actionLink: '/report-builder' + '?reportId=' + reportId,
                                buttons: true,
                            })
                        );
                        break;

                    case REPORT_DELIVERY_STATUSES.ERROR:
                    case REPORT_DELIVERY_STATUSES.FAILED_RETRY:
                        clearInterval(intervalTimeId);
                        dispatch(reportBuilderActions.updateReportDelivery(reportId));
                        dispatch(reportBuilderActions.removeBatchReportFromPooling(reportId));
                        dispatch(
                            backgroundActions.setFailureBackgroundMessages({
                                title: 'DeliveryService.downloadArticleFail.title',
                                message: 'DeliveryService.downloadArticleFail.description2',
                            })
                        );
                        break;
                }
            }

            if (error) {
                dispatch(reportBuilderActions.removeBatchReportFromPooling(reportId));
                dispatch(
                    backgroundActions.setFailureBackgroundMessages({
                        title: 'DeliveryService.downloadArticleFail.title',
                        message: 'DeliveryService.downloadArticleFail.description2',
                    })
                );
            }
        }, POLLING_BATCH_TIMEOUT);
    },

    isPollingBlocked(responseQueue, referenceValue) {
        // we consider polling blocked if last maxNumberOfConsecutiveCallsForPolling responses have the same value
        const maxNumberOfConsecutiveCallsForPolling = reduxStore.getState().user.maxNumberOfConsecutiveCallsForPolling;

        responseQueue.push(referenceValue);

        if (responseQueue.length > maxNumberOfConsecutiveCallsForPolling) {
            responseQueue.shift();
        }
        const isPollingBlocked =
            responseQueue.length === maxNumberOfConsecutiveCallsForPolling &&
            responseQueue.every((response) => response === responseQueue[0]);

        return isPollingBlocked;
    },

    updateFinishedBatchIds(batchIdsFromResponse, state) {
        /*  
            Once the data processing is complete or gets blocked, update the store with the correct batchId, setting processing to false, in order to dismiss the notification
            Because the request's response is an empty array, this means that we have to check the store for the batchIds
            that are not in the response and have the processing set to true
        */
        const hasBatchIdsInTheState = Object.keys(state.batchIdsList).length;
        let readyBatchIds = [];
        let finishedBatchIdList = {};

        if(hasBatchIdsInTheState) {
            const batchIds = Object.keys(state.batchIdsList);
            if(!batchIdsFromResponse.length) {
                readyBatchIds = batchIds;
            } else {
                //get the batchIds that are in the store but not in the response, those batchIds are ready
                readyBatchIds = batchIds.filter(id => !batchIdsFromResponse.find(({batchId}) => id === batchId));
            }

            readyBatchIds.forEach(batchId => {
                if(state.batchIdsList[batchId]?.processing) {
                    finishedBatchIdList[batchId] = {
                        ...state.batchIdsList[batchId],
                        processing: false,
                        readyCount: state.batchIdsList[batchId].totalCount,
                        totalCount: state.batchIdsList[batchId].totalCount,
                    }
                }
            });
        }

        return finishedBatchIdList;
    },

    //endpoint used for receiving the proccessed entities from the server when entities are copied, uploaded or added from ManageAlerts page
    pollUploadEntityView({ batchId, onFinished, route = '' }, v2 = false, dispatch = reduxStore.dispatch, state = null) {
        const responseQueue = [];
        let readyCount = 0;
        let lastReadyCount = 0;
        let endPolling = false;
        let isNotificationHidden = false;

        const takeActionsToFinish = ({error = false, resetNotification = true}) => {

            onFinished();
            clearInterval(intervalTimeId);

            if (error) {
                utils.showNotificationsMessage({
                    messageText: 'BatchUpload.submit.errorMessage',
                    messageType: 'system-error',
                });
            }

            /*
                if the notification is still open and it's on the page where it's supposed to show the status of the user's actions,
                we don't want to reset the store from here. This is because we want to display the complete text before the notification
                is automatically closed, allowing the user to know that the action they initiated has been completed
            */
            if(error || resetNotification) dispatch(mainActions.resetNotification({keyInTheStore: NOTIFICATION_STORE_KEYS.COPY}));
        }

        const intervalTimeId = setInterval(async () => {
            const [respose, error] = await EntityViewApi.entityViewUploadPolling(batchId, v2);
            const currentRoute = window.location.hash.split('#')[1];

            if (!error) {
                const { actionType, processedCount, totalCount, finished, uploaded } = respose;
                if(actionType === ACTION_TYPES.UPLOAD || actionType === ACTION_TYPES.ADD_FROM_ALERTS) {
                    // don't reset the notification status for the copy action 
                    takeActionsToFinish({resetNotification: false});
                    return;
                }
                const store = state ?? reduxStore.getState();
                let uploadFinished = false;
                let notificationData = {};
                if(v2) {
                    readyCount = processedCount
                    uploadFinished = readyCount === totalCount
                 } else {
                    readyCount = finished;
                    uploadFinished = readyCount === uploaded;
                 }

                endPolling = uploadFinished || this.isPollingBlocked(responseQueue, readyCount);
                isNotificationHidden = store.notificationStatus?.copyEntitiesStatus.hidden;

                //if the user is on the same page with the notification we have
                //to update the notification data in order to display the correct text and correct icon
                if(currentRoute === route) {
                    if(endPolling) {
                        //the batchId with the initial data is already in the store, it was set in the EntityViewTable when copy button was clicked
                        notificationData = {
                            processing: false,
                            processedCount: processedCount,
                            readyCount: totalCount,
                            totalCount,
                        }
                        // Once the data processing is complete or gets blocked, update the store with the correct batchId,
                        // setting processing to false, in order to dismiss the notification
                        dispatch(mainActions.updateBatchId({batchId, data: notificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.COPY}));

                        takeActionsToFinish({resetNotification: isNotificationHidden});
                    } else if(!isNotificationHidden && lastReadyCount !== readyCount) {
                        /*
                        to reduce the number of store updates,
                            - update the snackbar data in the store only when the snackbar is displayed
                            - only if the processedCount is different from the last one
                        the requests are still being made in the background
                        */
                        lastReadyCount = readyCount;
                        notificationData = {
                            processing: true,
                            processedCount,
                            readyCount,
                            totalCount,
                        }
                        dispatch(mainActions.updateBatchId({batchId, data: notificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.COPY}));
                    }
                }

                if(currentRoute !== route && endPolling) {
                    takeActionsToFinish({resetNotification: true});
                }
            } else {
                takeActionsToFinish({error});
            }
        }, POLLING_ENTITIES_UPLOAD);
    },

    pollDownloadEntityViewReports(batchId, onFinished) {
        const responseQueue = [];

        const intervalTimeId = setInterval(async () => {
            let endPolling = false;
            const [data, error] = await EntityViewApi.entityViewDownloadReportsPolling(batchId);

            if (!error) {
                const { processedCount, totalCount } = data;
                const uploadFinished = processedCount === totalCount;
                const isBlocked = this.isPollingBlocked(responseQueue, processedCount);
                endPolling = uploadFinished || isBlocked;
            }

            if (endPolling || error) {
                onFinished();
                clearInterval(intervalTimeId);
            }
        }, POLLING_ENTITIES_UPLOAD);
    },

    pollRefreshEntityView({ batchId, isForAll, onFinished }) {
        // This method is initializing the refresh polling mecanism 
        // and only after it finishes on the onFinish() method the actual polling requests are being made
        // which is declare inside the pollBatchEntitiesUpdated() method
        const responseQueue = [];

        const intervalTimeId = setInterval(async () => {
            let endPolling = false;
            const [data, error] = await EntityViewApi.refreshPolling(batchId);

            if (!error) {
                const { processedCount, totalCount } = data;

                endPolling = processedCount === totalCount || this.isPollingBlocked(responseQueue, processedCount);
                //If "isForAll" request is true, clear out previous batch polling ids, as the refresh creates a
                //new batch to poll for all the entities
                isForAll && utils.clearBatchQueue();
            }
            
            if (endPolling || error) {
                onFinished();
                clearInterval(intervalTimeId);
            }
        }, POLLING_ENTITIES_REFRESH);
    },

    pollDeleteEntityView({ batchId, onFinished, numberOfEntities, route = '' }, dispatch = reduxStore.dispatch, state = null) {
        const responseQueue = [];
        let lastReadyCount = 0;
        let readyCount = 0;
        let endPolling = false;
        let isBlocked;
        let isNotificationHidden = false;

        const takeActionsToFinish = ({error = false, resetNotification = true}) => {

           onFinished();
           clearInterval(intervalTimeId);

           if(error) {
               utils.showNotificationsMessage({
                   messageText: 'BatchScreening.pollingError.pollingBlocked',
                   messageType: 'system-error',
                });
            }

            if(error || resetNotification) dispatch(mainActions.resetNotification({keyInTheStore: NOTIFICATION_STORE_KEYS.DELETE}));
        }

        const intervalTimeId = setInterval(async () => {
            isBlocked = false;
            const [data, error] = await EntityViewApi.deletePolling(batchId);
            const currentRoute = window.location.hash.split('#')[1];
            if (!error) {
                const { processedCount, totalCount } = data;
                let notificationData = {};
                const store = state ?? reduxStore.getState();
                isNotificationHidden = store.notificationStatus?.deleteEntitiesStatus.hidden;
                readyCount = processedCount;
                isBlocked = this.isPollingBlocked(responseQueue, readyCount);
                endPolling = readyCount === totalCount || isBlocked;

                //if the user is on the same page with the notification we have
                //to update the notification data in order to display the correct text and correct icon
                if(currentRoute === route){
                    if(endPolling) {

                        //the batchId with the initial data is already in the store, it was set in the EntityViewTable when delete button was clicked
                        notificationData = {
                            processing: false,
                            processedCount: processedCount,
                            readyCount: totalCount ?? numberOfEntities,
                            totalCount: totalCount ?? numberOfEntities,
                        }
                        // Once the data processing is complete or gets blocked, update the store with the correct batchId, setting processing to false, in order to dismiss the notification
                        dispatch(mainActions.updateBatchId({batchId, data: notificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.DELETE}));

                        /*
                            if the notification is still open and it's on the page where it's supposed to show the status of the user's actions,
                            we don't want to reset the store from here. This is because we want to display the complete text before the notification
                            is automatically closed, allowing the user to know that the action they initiated has been completed
                        */
                        takeActionsToFinish({ error: !!isBlocked ,resetNotification: isNotificationHidden})
                    } else if(!isNotificationHidden && lastReadyCount !== readyCount) {
                        /*
                        to reduce the number of store updates,
                            - update the snackbar data in the store only when the snackbar is displayed
                            - only if the processedCount is different from the last one
                        the requests are still being made in the background
                        */
                        lastReadyCount = processedCount;
                        notificationData = {
                            processing: true,
                            processedCount,
                            readyCount,
                            totalCount: totalCount ?? numberOfEntities,
                        }
                        dispatch(mainActions.updateBatchId({batchId, data: notificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.DELETE}));
                    }
                }

                if(currentRoute !== route && endPolling) {
                    takeActionsToFinish({resetNotification : true});
                }
            } else {
                takeActionsToFinish({error});
            }
        }, POLLING_ENTITIES_REFRESH);    
    },

    pollCreateView({ batchId, onFinished }) {
        const responseQueue = [];
        const intervalTimeId = setInterval(async () => {
            let endPolling = false;

            const [data, error] = await EntityViewApi.createViewPolling(batchId);

            if (data) {
                const { processedCount, totalCount } = data;

                endPolling = processedCount === totalCount || this.isPollingBlocked(responseQueue, processedCount);
            }

            if (endPolling || error) {
                onFinished();
                clearInterval(intervalTimeId);
            }
        }, POLLING_ENTITIES_REFRESH);
    },

    pollEntitiesCounts({
        unreadyEntityIds = [],
        dispatch = reduxStore.dispatch,
        handleEntityViewApiError = undefined
    }) {
        // Cancel the on-going requests when new entityIds are added
        if (pollEntitiesCountsInterval) clearInterval(pollEntitiesCountsInterval);
        
        let entitiesIdQueue = cloneDeep(reduxStore.getState().entityViewInformation.entitiesIdQueue);

        // Exit early if there are no items to process
        if (!entitiesIdQueue.length && !unreadyEntityIds.length) return;

        entitiesIdQueue = [...new Set(entitiesIdQueue.concat(unreadyEntityIds))];

        let endPolling = false;
        let isRequestPending = false;
        let remainingToPollUnreadyEntities = [...entitiesIdQueue];
        const selectedViewId = reduxStore.getState().user.preferences.screeningEntity.lastSelectedView;
        const { IN_PROGRESS, FINISHED } = POLLING_STATUS;
        const { updateEntitiesIdQueue, updateEntityViewInformation } = mainActions;
        const { updatePollingStatus, updateLastBatchReportProperty } = reportBuilderActions;

        dispatch(updateEntitiesIdQueue(entitiesIdQueue));
        dispatch(updatePollingStatus(IN_PROGRESS));

        pollEntitiesCountsInterval = setInterval(async () => {
            if (isRequestPending) return;
            
            isRequestPending = true;
            
            let processingEntities = [];
            const [response, error] = await EntityViewApi.getInProgressEntitiesIds({screeningEntityIds: remainingToPollUnreadyEntities})
            isRequestPending = false;

            if (response?.length) {

                dispatch(updateEntityViewInformation(response));

                processingEntities = response.filter(({ isReady }) => isReady === false);
                endPolling = processingEntities?.length === 0;
                
                if(!endPolling) {
                    remainingToPollUnreadyEntities = processingEntities?.flatMap(({id}) => id);
                 } else {
                     dispatch(updatePollingStatus(FINISHED));
                     dispatch(updateLastBatchReportProperty('uploadedFinishedStatus', true));

                    // Once all entities are ready end polling
                    dispatch(updateEntitiesIdQueue([]));
                    clearInterval(pollEntitiesCountsInterval);
                    return;
                 }
            }

            if (error) {
                dispatch(updatePollingStatus(FINISHED));
                dispatch(updateEntitiesIdQueue([]));
                clearInterval(pollEntitiesCountsInterval);
                if (handleEntityViewApiError) {
                    handleEntityViewApiError(error, { viewId: selectedViewId });
                } else {
                    utils.showNotificationsMessage({
                        messageText: 'BatchUpload.submit.errorMessage',
                        messageType: 'system-error',
                    });
                }
            }
        }, POLLING_BATCH_SCREENING_TIMEOUT);
    },

    pollInProgressEntitiesData({ dispatch = reduxStore.dispatch, handleEntityViewApiError = undefined, state = null, route = '' }) {
        // Cancel the on-going requests when new entityIds are added
        if (pollInProgressEntitiesDataInterval) clearInterval(pollInProgressEntitiesDataInterval);

        const takeActionsToFinish = ({error = false, resetRefreshNotification = true, resetUploadNotification = true}) => {

            clearInterval(pollInProgressEntitiesDataInterval);
            batch(() => {
                if (!error) {
                    dispatch(reportBuilderActions.updateLastBatchReportProperty('uploadedFinishedStatus', true));
                }

                if(error || resetRefreshNotification ) dispatch(mainActions.resetNotification({keyInTheStore: NOTIFICATION_STORE_KEYS.REFRESH}));
                if(error || resetUploadNotification) dispatch(mainActions.resetNotification({keyInTheStore: NOTIFICATION_STORE_KEYS.UPLOAD}));

                dispatch(reportBuilderActions.updatePollingStatus(POLLING_STATUS.FINISHED));
                dispatch(mainActions.updateBatchQueue([]));
            })
        };

        // Create a response queue array for each batch and store it in an object
        let responseQueue;
        let endPolling = false;
        let isRequestPending = false;
        let refreshNotificationDataBatchList = {};
        let uploadNotificationDataBatchList = {};
        let isRefreshNotificationHidden = false;
        let isUploadNotificationHidden = false;
        let uploadEmptyChecks = 0;
        let refreshEmptyChecks = 0;

        const { updateNotificationStatusThunk } = mainActions;

        dispatch(reportBuilderActions.updatePollingStatus(POLLING_STATUS.IN_PROGRESS));
        pollInProgressEntitiesDataInterval = setInterval(async () => {
            
            if (isRequestPending) return;
            
            isRequestPending = true;
            let batchQueue = cloneDeep(reduxStore.getState().entityViewInformation.batchQueue);
            const [response, error] = await EntityViewApi.getActionsInProgress();
            const currentRoute = window.location.hash.split('#')[1];
            isRequestPending = false;
            
            if(!error) {
                const store = state ? state.getState() : reduxStore.getState();
                //at each iteration re-initialize the upload and refresh object that needs to be send to update the store
                refreshNotificationDataBatchList = {};
                uploadNotificationDataBatchList = {};

                let refreshEntitiesStatus = cloneDeep(store.notificationStatus?.refreshEntitiesStatus);
                let uploadEntitiesStatus = cloneDeep(store.notificationStatus?.uploadEntitiesStatus);
                /* 
                    If the user refreshes the page while the counts are still loading,
                    initialize the notification data for the refresh and upload entities                
                */
                if(response.length && !Object.keys(refreshEntitiesStatus?.batchIdsList).length && !Object.keys(uploadEntitiesStatus?.batchIdsList).length) {
                    let readyCount = 0;
                    response.forEach(({ actionType, batchId, processedCount, totalCount, errorCount }) => {
                        readyCount = processedCount + errorCount;
                        if(actionType === ACTION_TYPES.UPLOAD || actionType === ACTION_TYPES.ADD_FROM_ALERTS) {
                            uploadNotificationDataBatchList[batchId] = {
                                actionType: ACTION_TYPES.UPLOAD,
                                processing: true,
                                processedCount,
                                readyCount,
                                totalCount,
                            };
                        }
                        if(actionType === ACTION_TYPES.REFRESH || actionType === ACTION_TYPES.RESET_USER_PREFERENCES) {
                            refreshNotificationDataBatchList[batchId] = {
                                actionType,
                                processing: true,
                                processedCount,
                                readyCount,
                                totalCount,
                            };
                        }
                    });
                    //to avoid clonning the store in the setInterval function add the notification initial data to uploadEntitiesStatus and refreshEntitiesStatus
                    uploadEntitiesStatus.batchIdsList = uploadNotificationDataBatchList;
                    refreshEntitiesStatus.batchIdsList = refreshNotificationDataBatchList;

                    dispatch(updateNotificationStatusThunk({addNewRefreshBatchIdsList: refreshNotificationDataBatchList, addNewUploadBatchIdsList: uploadNotificationDataBatchList}));
                    if(!response.length) {
                        takeActionsToFinish({});
                    }
                } else {
                    /*
                        we use the 'hidden' property from the store to determine if the notification was manually hidden before the polling was finished
                        therefore we need it to know to not dispatch any action to update the store
                        this can happen while the polling is in progress therefore we constantly need to check the store for the hidden property
                    */

                    isRefreshNotificationHidden = store.notificationStatus.refreshEntitiesStatus.hidden;
                    isUploadNotificationHidden = store.notificationStatus.uploadEntitiesStatus.hidden;

                    endPolling = response.length === 0;
                    //if the user is on the same page with the notification we have
                    //to update the notification data in order to display the correct text and correct icon
                    if(currentRoute === route) {
                        if(endPolling) {
                            uploadNotificationDataBatchList = this.updateFinishedBatchIds(response, uploadEntitiesStatus);
                            refreshNotificationDataBatchList = this.updateFinishedBatchIds(response, refreshEntitiesStatus);

                            dispatch(updateNotificationStatusThunk({refreshBatchList: refreshNotificationDataBatchList, uploadBatchList: uploadNotificationDataBatchList}));

                             //take actions to finish the polling
                             takeActionsToFinish({resetRefreshNotification: isRefreshNotificationHidden, resetUploadNotification: isUploadNotificationHidden});
                        } else {
                            const responseBatchIds = response.map(({ batchId }) => batchId);
                            const refreshBatchIds = response.filter(({ actionType }) => actionType === ACTION_TYPES.REFRESH || actionType === ACTION_TYPES.RESET_USER_PREFERENCES);
                            const uploadBatchIds = response.filter(({ actionType }) => actionType === ACTION_TYPES.UPLOAD || actionType === ACTION_TYPES.ADD_FROM_ALERTS);

                            //if aren't any refreshBatchIds in the response, but there are some in the store, update the store with the correct data of reset it if the notification is hidden
                            if(!refreshBatchIds.length) {
                                if(Object.keys(refreshEntitiesStatus?.batchIdsList).length){
                                    if(isRefreshNotificationHidden) {
                                        dispatch(mainActions.resetNotification({keyInTheStore: NOTIFICATION_STORE_KEYS.REFRESH}));
                                    } else {
                                        refreshEmptyChecks++;
                                        refreshNotificationDataBatchList = refreshEmptyChecks === EMPTY_POLLING_CHECK_LIMIT ? this.updateFinishedBatchIds(refreshBatchIds, refreshEntitiesStatus) : {...refreshNotificationDataBatchList};
                                    }
                                }
                            } else {
                                refreshEmptyChecks = 0;
                                refreshBatchIds.forEach(({ batchId, processedCount, totalCount, errorCount }) => {
                                    let readyCount = processedCount + errorCount;
                                    if(!batchQueue.includes(batchId)) {
                                        /*
                                            If a new batchId is added, pop it in the queue
                                            If not, just process the existing items in queue
                                        */
                                        batchQueue = [...new Set(batchQueue.concat(batchId))];
                                        dispatch(mainActions.updateBatchQueue(batchQueue));
                                    }
    
                                    responseQueue = batchQueue.reduce((acc, key) => {
                                        acc[key] = [];
                                        return acc;
                                    }, {});
    
                                    const batchResponseQueue = responseQueue[batchId] || [];
                                    const isBlocked = this.isPollingBlocked(batchResponseQueue, readyCount);
                                    if(isBlocked) {
                                        refreshNotificationDataBatchList[batchId] = {
                                            errorCount,
                                            processing: false,
                                            processedCount,
                                            readyCount: totalCount,
                                            totalCount,
                                        }
                                        utils.removeBatchFromQueue(batchId);
                                    } else {
                                        // update the store with the correct data if the notification is displayed
                                        let lastReadyCount = refreshEntitiesStatus.batchIdsList[batchId]?.readyCount;
                                        if(!isRefreshNotificationHidden && lastReadyCount !== readyCount) {
                                            lastReadyCount = readyCount;
                                            refreshNotificationDataBatchList[batchId] = {
                                                errorCount,
                                                processing: true,
                                                processedCount,
                                                readyCount,
                                                totalCount,
                                            }
                                        }
                                    }
                                });
                            }

                            if(!uploadBatchIds.length) {
                                if(Object.keys(uploadEntitiesStatus?.batchIdsList).length) {
                                    if(isUploadNotificationHidden) {
                                        dispatch(mainActions.resetNotification({keyInTheStore: NOTIFICATION_STORE_KEYS.UPLOAD}));
                                    } else {
                                        uploadEmptyChecks++;
                                        uploadNotificationDataBatchList = uploadEmptyChecks === EMPTY_POLLING_CHECK_LIMIT ? this.updateFinishedBatchIds(uploadBatchIds, uploadEntitiesStatus) : {...uploadNotificationDataBatchList};
                                    }
                                }
                            } else {
                                uploadEmptyChecks = 0;
                                uploadBatchIds.forEach(({ batchId, processedCount, totalCount, errorCount }) => { 
                                    let readyCount = processedCount + errorCount;
                                    if(!batchQueue.includes(batchId)) {
                                        batchQueue = [...new Set(batchQueue.concat(batchId))];
                                        dispatch(mainActions.updateBatchQueue(batchQueue));
                                    }
    
                                    responseQueue = batchQueue.reduce((acc, key) => {
                                        acc[key] = [];
                                        return acc;
                                    }
                                    , {});
    
                                    const batchResponseQueue = responseQueue[batchId] || [];
                                    const isBlocked = this.isPollingBlocked(batchResponseQueue, readyCount);
                                    if(isBlocked) {
                                        uploadNotificationDataBatchList[batchId] = {
                                            errorCount,
                                            processing: false,
                                            processedCount,
                                            readyCount: totalCount,
                                            totalCount,
                                        }
                                        utils.removeBatchFromQueue(batchId);
                                    } else {
                                        // update the store with the correct data if the notification is displayed
                                        let lastReadyCount = uploadEntitiesStatus.batchIdsList[batchId]?.readyCount;
                                        if(!isUploadNotificationHidden && lastReadyCount !== readyCount) {
                                            lastReadyCount = readyCount;
                                            uploadNotificationDataBatchList[batchId] = {
                                                errorCount,
                                                processing: true,
                                                processedCount,
                                                readyCount,
                                                totalCount,
                                            }
                                        }
                                    }
                                });
                            }

                            dispatch(updateNotificationStatusThunk({refreshBatchList: refreshNotificationDataBatchList, uploadBatchList: uploadNotificationDataBatchList}));

                            batchQueue.forEach(batchId => {
                                if(!responseBatchIds.includes(batchId)) {
                                    // Remove batch from queue once it's processed
                                    // Retrigger polling with the remaining batches
                                    utils.removeBatchFromQueue(batchId);
                                }
                            });
                        }
                    }

                    if(currentRoute !== route && endPolling) {
                        takeActionsToFinish({resetRefreshNotification: true, resetUploadNotification: true});
                    }
                }
            } else {
                const selectedViewId = reduxStore.getState().user.preferences.screeningEntity.lastSelectedView;

                takeActionsToFinish({error});
 
                if (handleEntityViewApiError) {
                    handleEntityViewApiError(error, { viewId: selectedViewId });
                } else {
                    utils.showNotificationsMessage({
                        messageText: 'BatchUpload.submit.errorMessage',
                        messageType: 'system-error',
                    });
                }
            }
        }, POLLING_BATCH_SCREENING_TIMEOUT)
    },

    pollEntityAlertsCreation({dispatch = reduxStore.dispatch, state, route = '', onFinished}) {
        if (pollEntityAlertsInterval) clearInterval(pollEntityAlertsInterval);
        dispatch(manageAlertsActions.updatePollingStatus(POLLING_STATUS.IN_PROGRESS));

        const responseQueue = [];
        let lastReadyCount = 0;
        let readyCount = 0;
        let endPolling = false;
        let isNotificationHidden = false;
        let createEmptyChecks = 0;
        let createAlertNotificationDataBatchList = {};
        const takeActionsToFinish = ({error = false, resetNotification = true}) => {
            clearInterval(pollEntityAlertsInterval);
            onFinished && onFinished();

            batch(() => {
                if(error || resetNotification){
                    dispatch(mainActions.resetNotification({keyInTheStore: NOTIFICATION_STORE_KEYS.CREATE}));
                }
                dispatch(manageAlertsActions.updatePollingStatus(POLLING_STATUS.FINISHED));
            });
        }

        pollEntityAlertsInterval = setInterval(async () => {
            const [response, error] = await AlertApi.getActionsInProgress();
            const currentRoute = window.location.hash.split('#')[1];

            if (!error) {
                const store = state ? state.getState() : reduxStore.getState();

                createAlertNotificationDataBatchList = {};
                let createEntityAlertStatus = cloneDeep(store.notificationStatus?.createAlertsStatus);

                if (response.length && !Object.keys(createEntityAlertStatus?.batchIdsList).length) {
                    response.forEach(({ alertAction, batchId, processedCount, totalCount, errorCount }) => {
                        readyCount = processedCount + errorCount;
                        if(alertAction === ACTION_TYPES.CREATE) {
                            createAlertNotificationDataBatchList[batchId] = {
                                actionType: alertAction,
                                processing: true,
                                processedCount,
                                readyCount,
                                totalCount,
                            }; 
                        }
                    });

                    createEntityAlertStatus.batchIdsList = createAlertNotificationDataBatchList;
                    dispatch(mainActions.updateNotificationStatusThunk({addNewCreateAlertBatchIdsList: createAlertNotificationDataBatchList}));

                    if(!response.length) {
                        takeActionsToFinish({});
                    }
                } else {

                    isNotificationHidden = store.notificationStatus.createAlertsStatus.hidden;

                    endPolling = response.length === 0;

                    if(currentRoute === route) {
                        if(endPolling) {
                            createAlertNotificationDataBatchList = this.updateFinishedBatchIds(response, createEntityAlertStatus);
                            dispatch(mainActions.updateNotificationStatusThunk({createAlertsBatchList: createAlertNotificationDataBatchList}));
                            takeActionsToFinish({resetNotification: isNotificationHidden});
                        } else {
                            const createAlertBatchIds = response.filter(({ alertAction }) => alertAction === ACTION_TYPES.CREATE);

                            if(!createAlertBatchIds.length) {
                                if(Object.keys(createEntityAlertStatus?.batchIdsList).length) {
                                    if(isNotificationHidden) {
                                        dispatch(mainActions.resetNotification({keyInTheStore: NOTIFICATION_STORE_KEYS.CREATE}));
                                    } else {
                                        createEmptyChecks++;
                                        createAlertNotificationDataBatchList = createEmptyChecks === EMPTY_POLLING_CHECK_LIMIT ? this.updateFinishedBatchIds(createAlertBatchIds, createEntityAlertStatus) : {...createAlertNotificationDataBatchList};
                                    }
                                }
                            } else {
                                createEmptyChecks = 0;
                                createAlertBatchIds.forEach(({ batchId, processedCount, totalCount, errorCount }) => {
                                    let readyCount = processedCount + errorCount;
                                    const isBlocked = this.isPollingBlocked(responseQueue, readyCount);
                                    if(isBlocked) {
                                        createAlertNotificationDataBatchList[batchId] = {
                                            errorCount,
                                            processing: false,
                                            processedCount,
                                            readyCount: totalCount,
                                            totalCount,
                                        }
                                    } else {
                                        lastReadyCount = createEntityAlertStatus.batchIdsList[batchId]?.readyCount;
                                        if(!isNotificationHidden && lastReadyCount !== readyCount) {
                                            lastReadyCount = readyCount;
                                            createAlertNotificationDataBatchList[batchId] = {
                                                errorCount,
                                                processing: true,
                                                processedCount,
                                                readyCount,
                                                totalCount,
                                            }
                                        }
                                    }
                                });
                            }

                            dispatch(mainActions.updateNotificationStatusThunk({createAlertsBatchList: createAlertNotificationDataBatchList}));
                        }
                    }

                    if(currentRoute !== route && endPolling) {
                        takeActionsToFinish({resetNotification: true});
                    }
                }
            } else {
                takeActionsToFinish({error});
            }
        }, POLLING_BATCH_SCREENING_TIMEOUT);
    },

    pollDeleteAlert({ batchId, route = '', onFinished, dispatch = reduxStore.dispatch, state}) {
        const responseQueue = [];
        let lastReadyCount = 0;
        let readyCount = 0;
        let endPolling = false;
        let isNotificationHidden = false;

        const takeActionsToFinish = ({error = false, resetNotification = true}) => {

            onFinished();
            clearInterval(intervalTimeId);
            error && console.error(error);

            if(error || resetNotification) {
                dispatch(mainActions.resetNotification({keyInTheStore: NOTIFICATION_STORE_KEYS.DELETE_ALERT}));
            }
        }

        const intervalTimeId = setInterval(async () => {
            const [response, error] = await AlertApi.getBatchAlertsStatus(batchId);
            const currentRoute = window.location.hash.split('#')[1];

            if (!error) {
                const { processedCount, totalCount, errorCount } = response;
                const store = state ?? reduxStore.getState();
                let deleteAlertsNotificationData = {};
                isNotificationHidden = store.notificationStatus.deleteAlertsStatus.hidden;

                readyCount = processedCount + errorCount;
                endPolling = readyCount  === totalCount || this.isPollingBlocked(responseQueue, processedCount);

                //if the user is on the same page with the notification we have
                //to update the notification data in order to display the correct text and correct icon
                if(currentRoute === route) {
                    if(endPolling) {

                        //the batchId with the initial data is already in the store, it was set in the EntityViewTable when delete alert button was clicked
                        deleteAlertsNotificationData = {
                            errorCount,
                            processing: false,
                            processedCount: processedCount,
                            readyCount: readyCount,
                            totalCount
                        }
                        // Once the data processing is complete or gets blocked, update the store with the correct batchId, setting processing to false, in order to dismiss the notification
                        dispatch(mainActions.updateBatchId({batchId, data: deleteAlertsNotificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.DELETE_ALERT}));

                        /*
                            if the notification is still open and it's on the page where it's supposed to show the status of the user's actions,
                            we don't want to reset the store from here. This is because we want to display the complete text before the notification
                            is automatically closed, allowing the user to know that the action they initiated has been completed
                        */
                        takeActionsToFinish({resetNotification: isNotificationHidden})
    
                    } else if(!isNotificationHidden && lastReadyCount !== readyCount) {
                        /*
                        to reduce the number of store updates,
                            - update the snackbar data in the store only when the snackbar is displayed
                            - only if the readyCount is different from the last one
                        the requests are still being made in the background
                        */
                        lastReadyCount = readyCount;
                        deleteAlertsNotificationData = {
                            errorCount,
                            processing: true,
                            processedCount,
                            readyCount,
                            totalCount,
                        }
                        dispatch(mainActions.updateBatchId({batchId, data: deleteAlertsNotificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.DELETE_ALERT}));
                    }
                }
                if(currentRoute !== route && endPolling) {
                    takeActionsToFinish({resetNotification: true});
                }
            } else {
                takeActionsToFinish({error});
            }
        }, POLLING_TIMEOUT);
    },

    pollPrintReport(reportId, buildId, dispatch = reduxStore.dispatch) {
        const intervalTimeId = setInterval(async () => {
            const buildStatus = await ReportBuilderApi.getPrintStatus(reportId, buildId);

            switch (buildStatus?.status) {
                case REPORT_DELIVERY_STATUSES.FINISHED: {
                    clearInterval(intervalTimeId);
                    const html = await ReportBuilderApi.getPrintDownload(reportId, buildId);

                    if (!html) {
                        dispatch(
                            backgroundActions.setFailureBackgroundMessages({
                                title: 'DeliveryService.printArticleFail.title',
                                message: 'DeliveryService.printArticleFail.description',
                            })
                        );
                        break;
                    }

                    dispatch(
                        backgroundActions.setSuccessBackgroundMessages({
                            title: 'DeliveryService.printReady',
                            link: () => printUtils.printStuff(html),
                            message: 'DeliveryService.printReady.singleFile',
                        })
                    );
                    break;
                }

                case REPORT_DELIVERY_STATUSES.ERROR:
                case REPORT_DELIVERY_STATUSES.FAILED_RETRY:
                case REPORT_DELIVERY_STATUSES.ERROR_REPORT_TOO_BIG: {
                    clearInterval(intervalTimeId);
                    dispatch(
                        backgroundActions.setFailureBackgroundMessages({
                            title: 'DeliveryService.printArticleFail.title',
                            message: 'DeliveryService.printArticleFail.description',
                        })
                    );
                    break;
                }
            }
        }, POLLING_TIMEOUT);
    },

    pollEmailReports(emailDeliveryId, dispatch = reduxStore.dispatch) {
        const intervalTimeId = setInterval(async () => {
            const [buildStatus, error] = await ReportBuilderApi.getEmailReportStatus(emailDeliveryId);

            switch (buildStatus?.emailStatus) {
                case REPORT_DELIVERY_STATUSES.STARTED:
                    dispatch(
                        backgroundActions.setBackgroundMessages({
                            title: 'DeliveryService.sending',
                            message: 'DeliveryService.emailSingleArticles',
                        })
                    );
                    break;

                case REPORT_DELIVERY_STATUSES.FINISHED:
                    dispatch(
                        backgroundActions.setSuccessBackgroundMessages({
                            message: 'DeliveryService.emailSent',
                        })
                    );
                    setTimeout(() => {
                        dispatch(backgroundActions.clearBackgroundMessages());
                    }, MESSAGE_TIMEOUT);
                    clearInterval(intervalTimeId);
                    break;

                case REPORT_DELIVERY_STATUSES.ERROR:
                case REPORT_DELIVERY_STATUSES.ERROR_REPORT_TOO_BIG:
                case REPORT_DELIVERY_STATUSES.FAILED_RETRY:
                    clearInterval(intervalTimeId);
                    dispatch(
                        backgroundActions.setFailureBackgroundMessages({
                            title: 'DeliveryService.emailFailedToSend',
                        })
                    );
                    break;
            }

            if (error) {
                clearInterval(intervalTimeId);
                dispatch(
                    backgroundActions.setFailureBackgroundMessages({
                        title: 'DeliveryService.emailFailedToSend',
                    })
                );
            }
        }, POLLING_TIMEOUT);
    },

    pollReportDownload(reportId, buildId, message = null, dispatch = reduxStore.dispatch) {
        const intervalTimeId = setInterval(async () => {
            const [buildStatus, error] = await ReportBuilderApi.getReportStatus(reportId, buildId);

            switch (buildStatus?.status) {
                case REPORT_DELIVERY_STATUSES.FINISHED:
                    clearInterval(intervalTimeId);
                    dispatch(
                        backgroundActions.setSuccessBackgroundMessages({
                            title: 'DeliveryService.downloadReady',
                            message: message ? message : 'DeliveryService.reportReadyToDownload',
                            type: BACKGROUND_MESSAGE_TYPE_DOWNLOAD,
                            link: '/api/reportdelivery/report/download/' + reportId + '/' + buildId,
                        })
                    );
                    break;

                case REPORT_DELIVERY_STATUSES.ERROR_REPORT_TOO_BIG:
                    clearInterval(intervalTimeId);
                    dispatch(
                        backgroundActions.setFailureBackgroundMessages({
                            title: 'DeliveryService.downloadArticleFail.title',
                            message: 'DeliveryService.downloadReport.description.reportToBig',
                            actionLink: '/report-builder' + '?reportId=' + reportId,
                            buttons: true,
                        })
                    );
                    break;

                case REPORT_DELIVERY_STATUSES.ERROR:
                case REPORT_DELIVERY_STATUSES.FAILED_RETRY:
                    clearInterval(intervalTimeId);
                    dispatch(
                        backgroundActions.setFailureBackgroundMessages({
                            title: 'DeliveryService.downloadArticleFail.title',
                            message: 'DeliveryService.downloadArticleFail.description2',
                        })
                    );
                    break;
            }

            if (error) {
                dispatch(
                    backgroundActions.setFailureBackgroundMessages({
                        title: 'DeliveryService.downloadArticleFail.title',
                        message: 'DeliveryService.downloadArticleFail.description2',
                    })
                );
            }
        }, POLLING_TIMEOUT);
    },

    pollDownloadEntities(excelDeliveryId, filename, dispatch = reduxStore.dispatch) {
        const intervalTimeId = setInterval(async () => {
            const [response, error] = await ReportBuilderApi.getDownloadEntitiesStatus(excelDeliveryId);
            let notificationData = {};

            const takeActionsToFinish = ({error = false, resetNotification = true}) => {

                clearInterval(intervalTimeId);
                error && console.error(error);

                if(error || resetNotification) {
                    dispatch(mainActions.resetNotification({keyInTheStore: NOTIFICATION_STORE_KEYS.DOWNLOAD}));
                }
            }

            if (response?.isReady && !response.failed) {
                notificationData = {
                    processing: false,
                }
                dispatch(mainActions.updateBatchId({batchId: excelDeliveryId, data: notificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.DOWNLOAD}));
                takeActionsToFinish({});
                reduxStore.dispatch(backgroundActions.clearBackgroundMessages());
                reduxStore.dispatch(
                    backgroundActions.setSuccessBackgroundMessages({
                        title: 'DeliveryService.downloadReady',
                        message: 'DynamicMessage',
                        messageParameters: filename,
                        type: BACKGROUND_MESSAGE_TYPE_DOWNLOAD,
                        link: `/api/screening-entity/export/${excelDeliveryId}`,
                        isVisible: true,
                    })
                );
            } else if (response?.failed || error) {
                notificationData = {
                    processing: false,
                }
                dispatch(mainActions.updateBatchId({batchId: excelDeliveryId, data: notificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.DOWNLOAD}));
                takeActionsToFinish({error: true});
                utils.showNotificationsMessage({
                    messageText: 'BatchScreening.generalError.message',
                    messageType: 'system-error',
                });
            } else {
                notificationData = {
                    processing: true,
                }
                dispatch(mainActions.updateBatchId({batchId: excelDeliveryId, data: notificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.DOWNLOAD}));
            }
        }, POLLING_TIMEOUT);
    },

};

export default notificationService;
