import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

export class SanctionsDocumentMetadataTemplate extends Component {
    LINE_LENGTH = 65;

    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text;
        return { ...uncertainCell, text };
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    tooltipText(text) {
        return text && text.replace(/\s+/g, ' ').trim();
    }

    render(cell) {
        const { text, rowIndex } = cell;
        const { content, onClick, onMouseEnter, onMouseLeave } = text;
        const { authority, list, source } = content;
        const isContentAvailable = content !== '-' && ((authority && list) || source);
        const onClickHandler = () => isContentAvailable && onClick();

        const onMouseEnterHandler = () => {
            ReactTooltip.rebuild();
            onMouseEnter();
        }

        return (
            <div
                className={`sanctions-table-cell${isContentAvailable ? '' : ' disabled'} ${this.props.hoveredRow === rowIndex ? ' hovered' : ''}`}
                onClick={onClickHandler}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeave}
            >
                {isContentAvailable && (
                    <ReactTooltip
                        event="mouseenter"
                        eventOff="mouseleave"
                        type="light"
                        border={true}
                        effect="solid"
                        place="top"
                        multiline={true}
                        html={true}
                        className="tooltips"
                        id='sanctions-metadata-tooltip'
                    />
                )}

                <div className='sanctions-document-metadata-outer-container'>
                    {authority && <div className='sanctions-document-metadata-container'
                        data-tip={this.tooltipText(authority)}
                        data-for="sanctions-metadata-tooltip">
                        <p className='sanctions-document-metadata-content'>{authority}</p>
                    </div>}
                    {list && <div className='sanctions-document-metadata-container' data-tip={this.tooltipText(list)} data-for="sanctions-metadata-tooltip">
                        <p className='sanctions-document-metadata-content'>{list}</p>
                    </div>}
                    {source && <div className='sanctions-document-metadata-container' data-tip={this.tooltipText(source)} data-for="sanctions-metadata-tooltip">
                        <p className='sanctions-document-metadata-content'>{source}</p>
                    </div>}
                </div>
            </div>
        );
    }
}