import React from "react";

const SortComponent = ({ sortInfo, columnKey } ) => {
    const sortBy = sortInfo ? sortInfo.sortBy :'';
    const sortDirection = sortInfo ? sortInfo.direction :'';

    return (
        <span className="sort-button-container">
            <span className="sort-button-top">
              <svg className="sort-button-icon-container" viewBox="-2 -2 10.67 10.67">
                  <path
                      className={`sort-button-icon ${(sortDirection === 'asc' && columnKey === sortBy) ? 'filled' : ''}`}
                      d="M3.33 0 L6.67 5.77 L0 5.77 Z"/>
              </svg>
            </span>
            <span className="sort-button-bottom">
              <svg className="sort-button-icon-container" viewBox="-2 -2 10.67 10.67">
                  <path
                      className={`sort-button-icon ${(sortDirection === 'desc' && columnKey === sortBy) ? 'filled' : ''}`}
                      d="M3.33 6.67 L6.67 0.89 L0 0.89 Z"/>
              </svg>
            </span>
        </span>
    )
}

export default SortComponent;