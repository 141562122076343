//@flow
import {
    ALL_ARTICLES_REPORT,
    BACKGROUND_MESSAGE_TYPE_DOWNLOAD,
    BACKGROUND_MESSAGE_TYPE_PRINT,
    CATEGORIES,
    CATEGORY_NAMES,
    COST_CODE,
    COST_CODE_KEY,
    CUSTOM_NEWS_QUERY_TYPES_ID,
    DEFAULT_DELIVERY_OPTIONS,
    DOCUMENT_DATA,
    ERRORS,
    FUZZY_NAMES,
    LEGAL_SOURCES_TITLES,
    MODAL_TYPE,
    NO_DOCUMENTS_FOUND,
    NO_DOCUMENTS_FOUND_DOC_ID,
    NO_DOCUMENTS_OF_INTEREST,
    POST_FILTER_EXCLUDE_NEWS_WIRES,
    POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS,
    POST_FILTERS_KEY,
    PREFERENCES_KEY,
    SUGGESTED_NAMES,
    UBO_MAIN_CATEGORY,
    ALERT_ACCESS_TYPE,
    DOWNLOAD_ENTITIES_FORMAT_TYPE, ACTION_TYPES, NOTIFICATION_STORE_KEYS,
} from '@constants';
import ReportBuilderApi from '@pages/ReportBuilder/ReportBuilderMain.api';
import EntityViewApi from '@pages/EntityView/api/EntityViewApi';
import utils, { withContentSourceFilter } from './utilities';
import notificationService from './notificationService';
import { cloneDeep, differenceWith, isEmpty } from 'lodash';
import ReportBuilderUtils from '@pages/ReportBuilder/utils/ReportBuilderUtils';
import reduxStore from '@reduxStore';
import userPreferencesActions from '@pages/UserPreferances/redux/UserPreferences.actions';
import backgroundActions from '@reusable/BackgroundMessage/redux/BackgroundMessages.actions';
import reportBuilderActions from '@pages/ReportBuilder/redux/ReportBuilder.action';
import userPreferenceApi from '@pages/UserPreferances/UserPreferencesApi.api';
import currentReportActions from '@pages/ReportBuilder/redux/CurrentReport.actions';
import alertsManagerActions from '@pages/ManageAlerts/redux/ManageAlerts.actions';
import categoryUtils, { withCustomNewsTranslation, withSearchResultsFilter } from './categoryUtils';
import adHocSearchActions from '@reusable/AdHocSearch/redux/AdHocSearch.actions';
import costCodeUtils from './costCodeUtils';
import ReportBuilderService from '@pages/ReportBuilder/ReportBuilderService';
import editSearchActions from '@pages/StartPage/redux/EditSearch.actions';
import type {
    EmailInfoType,
    ModelType,
    PostFilterType,
    PrintInfoType,
    SearchQueryRequestType,
    DownloadDataType,
    PrintDataType,
    AdditionalDataForAllArticlesDownloadType,
    CategorySearchResultsType,
    DownloadInfoType,
    ArticleType,
} from './flow/deliveryService.type.guards';
import type { PopupModalStateType } from '@reusable/PopupModal/flow/PopupModal.type.guards';
import type { UboPersistedNodeType } from '@pages/UboDocoument/redux/flow/UboDocument.type.guards';
import { DOC_TYPE_SEARCH_EVENT_REQUEST } from '@scripts/constants';
import type { AlertFrequencyType } from '@pages/Alerts/typeGuards/AlertTypeGuards';
import SnapshotDeliveryUtils from '@utils/snapshotDeliveryUtils';
import mainActions from '@scripts/pages/Main/Main.actions';
import snackbarUtils from "@reusable/SnackbarWithAutohide/snackbarUtils";

interface ErrorResponse<S, T> {
    status: S;
    statusText: T;
}

interface JSONResponse<S, J> {
    status: S;
    json(): Promise<J>;
}

export function buildEmailInfo(model: ModelType): EmailInfoType {
    return {
        emailAddresses: [model.emailAddress],
        subject: model.emailSubject,
        comments: model.emailComments,
        filetype: model.docType,
    };
}

export function buildSearchQueryRequest(model: ModelType): SearchQueryRequestType {
    let articleType = model.articleType;
    if (model.resultListType === ALL_ARTICLES_REPORT) {
        if (!Array.isArray(articleType)) {
            articleType = [articleType];
        }
    }
    return {
        searchQuery: model.searchQuery,
        searchQueryType: model.searchQueryType,
        prefilterQuery: model.prefilterQuery,
        category: articleType,
        sort: model.sortStrategy,
        noDocuments: model.totalArticlesCount === 0,
    };
}

export const buildPostFilters = (model: ModelType): PostFilterType => {
    let postFilters = {};

    if (model.postFilters) {
        Object.keys(model.postFilters).forEach((key) => {
            if (key === FUZZY_NAMES || key === SUGGESTED_NAMES) {
                postFilters[key] = utils.getFuzzyNameList(model.postFilters[key]);
            } else {
                postFilters[key] = model.postFilters[key];
            }
        });
    }

    return postFilters;
};

export function buildPrintData(
    model: ModelType,
    timezone: string,
    language: string,
    startEachArticleOnNewPage: boolean
): PrintDataType {
    return {
        category: model.reportCategory,
        printInfo: {
            comments: model.comments,
        },
        reportId: model.reportId,
        timezone,
        language,
        contentsOptions: model.contentsOptions,
        startEachArticleOnNewPage,
    };
}

export function buildDownloadData(model: ModelType): DownloadDataType {
    const { timezone, interfaceLanguage, startEachArticleOnNewPage } = model.deliveryData;
    return {
        downloadInfo: {
            comments: model.comments,
            fileName: model.fileName,
            fileType: model.docType,
        },
        reportIds: model.reportListIds,
        reportType: model.reportType,
        timezone,
        // Get the interface language, replace character according to the db naming
        language: getMongoLanguageKey(interfaceLanguage),
        contentsOptions: model.contentsOptions,
        startEachArticleOnNewPage,
    };
}

export function buildDownloadCategoryData(
    model: ModelType,
    timezone: string,
    language: string,
    startEachArticleOnNewPage: boolean
): Object {
    return {
        downloadInfo: {
            comments: model.comments,
            fileName: model.fileName,
            fileType: model.docType,
        },
        reportId: model.reportId,
        category: model.reportCategory,
        timezone,
        language,
        contentsOptions: model.contentsOptions,
        startEachArticleOnNewPage,
    };
}

function dispatchSuccessMessage(message) {
    reduxStore.dispatch(backgroundActions.clearBackgroundMessages());
    reduxStore.dispatch(backgroundActions.setBackgroundMessages(message));
}

function dispatchErrorMessage(message) {
    reduxStore.dispatch(backgroundActions.setFailureBackgroundMessages(message));
}

export function addEsgAccessType(documentIdsObj: Object, articleType: string, store: Object) {
    if (articleType === CATEGORY_NAMES.ESG_RATINGS) {
        documentIdsObj.documentAccessType = store.articleNavigation.isShowMoreActive ? 'EXPANDED' : 'SIMPLE';
    }
}

//********* email **************//
function emailElasticArticlesDelivery(
    emailInfo: EmailInfoType,
    searchQueryRequest: SearchQueryRequestType,
    model: ModelType,
    postFilters: PostFilterType
) {
    const store = reduxStore.getState();
    const billingId = store.investigation.billingId;
    const investigationId = store.investigation.id;
    const costCode = costCodeUtils.getCostCode();
    const timezone = store.user.timezone;
    const searchResults = store.searchResults;
    const additionalPostFilterData = getAdditionalDataForPostFilters(model.articleType, model, searchResults, store);
    const language = getMongoLanguageKey(store.user.preferences.language);
    const startEachArticleOnNewPage = store.user.preferences.startEachArticleOnNewPage;
    const maxNumberOfArticles = store.user.maxNumberOfArticles;
    const isCustomFuzzy = store.searchParams.isCustomFuzzy;

    if (model.resultListType === ALL_ARTICLES_REPORT) {
        const { totalArticlesCount, researchSummary, categoryOrder, singleSource, contentsOptions } = model;
        let message = 'DeliveryService.processingDocuments.withCount';

        if (totalArticlesCount === 1) {
            message = 'DeliveryService.processingDocument.withCount';
        } else if (totalArticlesCount > maxNumberOfArticles) {
            message = 'DeliveryService.processingDocuments.withoutCount';
        }

        dispatchSuccessMessage({
            message,
            messageParameters: totalArticlesCount,
        });

        const { searchQuery, searchQueryType, prefilterQuery } = searchQueryRequest;

        let payload = Object.assign(
            {},
            {
                emailInfo,
                searchQuery,
                searchQueryType,
                prefilterQuery,
                costCode,
                researchSummary,
                categoryOrder,
                fuzzyNames: postFilters.fuzzyNames,
                suggestedNames: postFilters.suggestedNames,
                postFilters: [
                    restructurePostFiltersForAllDocs(postFilters, additionalPostFilterData, model.originalArticleType),
                ],
                billingId,
                investigationId,
                timezone,
                language,
                isCustomFuzzy,
                contentsOptions: model.contentsOptions,
                startEachArticleOnNewPage,
                singleSource,
            }
        );

        payload.postFilters = renameUboCategoryInPostFilters(payload.postFilters);
        payload = { ...payload, ...addUboDataForDelivery(model, searchResults) };

        SnapshotDeliveryUtils.addSnapshotToPayload(store, contentsOptions, payload);

        ReportBuilderApi.emailElasticAllArticles(payload)
            .then((response) => {
                notificationService.pollEmailReports(response.id);
                return response;
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'ReportBuilderPage_Errors.sendingEmails',
                    messageType: 'system-error',
                });
            });
    } else {
        const {
            researchSummary,
            categoryOrder,
            articles,
            searchQuery,
            searchQueryType,
            originalArticleType,
            useNewResearchSummary,
            contentsOptions,
        } = model;
        const documentsToBeAdded = articles.map((article) => {
            return {
                id: article?.id,
                title: article?.title,
                uboTradeUp: article?.isBranch,
                persistedNodes: article?.persistedNodes,
                singleSource: article?.singleSource,
                documentAccessType: 'SIMPLE',
            };
        });

        let message =
            model.articles && model.articles.length === 1
                ? 'DeliveryService.processingDocument.withCount'
                : 'DeliveryService.processingDocuments.withCount';

        dispatchSuccessMessage({
            message,
            messageParameters: model.articles.length,
        });

        let payload = Object.assign(
            {},
            {
                fuzzyNames: postFilters.fuzzyNames,
                suggestedNames: postFilters.suggestedNames,
                postFilters: restructurePostFilters(postFilters, additionalPostFilterData, originalArticleType),
                documentIds: documentsToBeAdded,
                emailInfo,
                researchSummary: !useNewResearchSummary ? researchSummary : undefined,
                categoryOrder,
                searchQuery,
                searchQueryType,
                costCode,
                billingId,
                investigationId,
                timezone,
                language,
                contentsOptions: model.contentsOptions,
                startEachArticleOnNewPage,
            }
        );

        payload.postFilters = renameUboCategoryInPostFilters(payload.postFilters);
        payload = { ...payload, ...addUboTableInfo(model) };

        SnapshotDeliveryUtils.addSnapshotToPayload(store, contentsOptions, payload);

        ReportBuilderApi.emailElasticSelectedArticles(payload)
            .then((response) => {
                notificationService.pollEmailReports(response.id);
                return response;
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'ReportBuilderPage_Errors.sendingEmails',
                    messageType: 'system-error',
                });
            });
    }
}

export function emailElasticDocumentDelivery(emailInfo: EmailInfoType, model: ModelType, postFilters: PostFilterType) {
    dispatchSuccessMessage({
        message: 'DeliveryService.processingDocument.withCount',
    });

    const {
        articleType,
        researchSummary,
        categoryOrder,
        articles,
        searchQuery,
        searchQueryType,
        singleSource,
        originalArticleType,
    } = model;
    const documentId = getDocumentData(articles, 'id');
    const documentTitle = getDocumentData(articles, 'title');
    const documentTradeUp = getDocumentData(articles, 'tradeUp');
    const documentPersistedNodes = getDocumentData(articles, 'persistedNodes');
    const costCode = costCodeUtils.getCostCode();
    const store = reduxStore.getState();
    const billingId = store.investigation.billingId;
    const investigationId = store.investigation.id;
    const timezone = store.user.timezone;
    const searchResults = store.searchResults;
    const additionalPostFilterData = getAdditionalDataForPostFilters(articleType, model, searchResults, store);
    const language = getMongoLanguageKey(store.user.preferences.language);

    const documentIdsObj = {
        id: documentId,
        title: documentTitle,
        uboTradeUp: documentTradeUp,
        persistedNodes: documentPersistedNodes,
        singleSource,
        documentAccessType: 'SIMPLE',
    };
    addEsgAccessType(documentIdsObj, articleType, store);

    let payload = Object.assign(
        {},
        {
            documentIds: [documentIdsObj],
            postFilters: restructurePostFilters(postFilters, additionalPostFilterData, originalArticleType),
            fuzzyNames: postFilters.fuzzyNames,
            suggestedNames: postFilters.suggestedNames,
            emailInfo,
            searchQuery,
            searchQueryType,
            researchSummary,
            categoryOrder,
            costCode,
            billingId,
            investigationId,
            timezone,
            language,
            contentsOptions: DEFAULT_DELIVERY_OPTIONS.documentViewDeliveries,
        }
    );

    payload.postFilters = renameUboCategoryInPostFilters(payload.postFilters);
    payload = { ...payload, ...addUboTableInfo(model) };

    ReportBuilderApi.emailElasticSelectedArticles(payload)
        .then((response) => {
            notificationService.pollEmailReports(response.id);
            return response;
        })
        .catch(() => {
            utils.showNotificationsMessage({
                messageText: 'ReportBuilderPage_Errors.sendingEmails',
                messageType: 'system-error',
            });
        });
}

//********* end email **************//

function buildPostFiltersForDownloadAll({ postFilters, model, searchResults, state }): Array<PostFilterType> {
    let restructuredPostFilters = cloneDeep(postFilters);
    let postFiltersForDownload = [];

    delete restructuredPostFilters.fuzzyNames;
    delete restructuredPostFilters.suggestedNames;
    delete restructuredPostFilters.searchQuery;
    delete restructuredPostFilters.searchQueryType;
    delete restructuredPostFilters.prefilterQuery;

    if (Array.isArray(model.articleType)) {
        model.articleType.forEach((category) => {
            if (categoryUtils.isNewsSource(category)) {
                if (withSearchResultsFilter(searchResults).hasChildren(category)) {
                    const subcategories = withSearchResultsFilter(searchResults).getCategoryKeys(category);
                    subcategories.forEach((subcategory) => {
                        postFiltersForDownload.push(getPostFilters(subcategory, searchResults, model, state));
                    });
                } else {
                    postFiltersForDownload.push(getPostFilters(category, searchResults, model, state));
                }
            } else {
                let additionalArticlesDownloadData = getAdditionalDataForAllArticlesDownload(searchResults[category]),
                    legalSourcesKeys = Object.keys(LEGAL_SOURCES_TITLES),
                    isLegalSource = legalSourcesKeys.indexOf(category) !== -1;

                if (isLegalSource) {
                    additionalArticlesDownloadData.searchNamePartyEntity =
                        searchResults[category].postFilters.searchNamePartyEntity;
                }

                postFiltersForDownload.push({
                    ...additionalArticlesDownloadData,
                    category,
                    results: searchResults[category].count,
                });
            }
        });
    } else if (
        withSearchResultsFilter(searchResults).hasChildren(model.articleType) &&
        model.contentLanguage === undefined
    ) {
        const subcategories = withSearchResultsFilter(searchResults).getCategoryKeys(model.articleType);
        subcategories.forEach((subcategory) => {
            postFiltersForDownload.push(getPostFilters(subcategory, searchResults, model, state));
        });
    } else {
        let categoryKey = model.articleType;
        if (CATEGORIES[model.articleType]) {
            let subcategories = CATEGORIES[model.articleType].children;
            let keys = categoryUtils.getConfigKeys(categoryKey);
            if (subcategories && keys) {
                let subcategory = categoryUtils.getSubcategory(
                    subcategories,
                    categoryKey,
                    model[keys.matchConfigKey],
                    keys.matchConfigKey
                );
                if (subcategory) {
                    categoryKey = subcategory.key;
                }
            }
        }

        let additionalArticlesDownloadData = getAdditionalDataForAllArticlesDownload(searchResults[categoryKey]),
            isCategoryWithSearchSpecifically =
                [CATEGORY_NAMES.FINANCIAL_REPORT, CATEGORY_NAMES.COMPANY_RESOURCES].indexOf(categoryKey) !== -1;
        const isNewsSource = categoryUtils.isNewsSource(categoryKey);

        if (isCategoryWithSearchSpecifically) {
            additionalArticlesDownloadData.searchSpecifically =
                searchResults[categoryKey].postFilters.searchSpecifically;
        }

        if (state.user.newsExcludeTogglesEnabled && isNewsSource) {
            additionalArticlesDownloadData[POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS] =
                searchResults[categoryKey].postFilters[POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS];
            additionalArticlesDownloadData[POST_FILTER_EXCLUDE_NEWS_WIRES] =
                searchResults[categoryKey].postFilters[POST_FILTER_EXCLUDE_NEWS_WIRES];
        }

        postFiltersForDownload.push(
            Object.assign(
                {},
                restructuredPostFilters,
                withContentSourceFilter().extendConfig(categoryKey, {
                    ...additionalArticlesDownloadData,
                    ...getAdditionalDataForPostFilters(categoryKey, model, searchResults, state),
                })
            )
        );
    }

    return postFiltersForDownload;
}

//********* download documents ******//
function downloadElasticArticles(data) {
    const {
        searchQueryRequest,
        downloadInfo,
        model,
        postFilters,
        billingId,
        investigationId,
        costCode,
        contentsOptions,
        maxNumberOfArticles,
    } = data;

    const state = reduxStore.getState();
    const timezone = state.user.timezone;
    const searchResults = state.searchResults;
    const isCustomFuzzy = state.searchParams.isCustomFuzzy;
    const startEachArticleOnNewPage = state.user.preferences.startEachArticleOnNewPage;
    const language = getMongoLanguageKey(state.user.preferences.language);

    if (model.resultListType === ALL_ARTICLES_REPORT) {
        const { totalArticlesCount, researchSummary, categoryOrder, singleSource } = model;
        let message = 'DeliveryService.processingDocuments.withCount';

        if (totalArticlesCount === 1) {
            message = 'DeliveryService.processingDocument.withCount';
        } else if (totalArticlesCount > maxNumberOfArticles) {
            message = 'DeliveryService.processingDocuments.withoutCount';
        }

        dispatchSuccessMessage({
            message,
            messageParameters: totalArticlesCount,
            type: BACKGROUND_MESSAGE_TYPE_DOWNLOAD,
        });
        let { searchQuery, searchQueryType, prefilterQuery } = searchQueryRequest;

        const postFiltersForDownload = buildPostFiltersForDownloadAll({ postFilters, model, searchResults, state });

        let payload = Object.assign(
            {},
            {
                searchQuery,
                searchQueryType,
                prefilterQuery,
                downloadInfo,
                costCode,
                billingId: billingId,
                investigationId: investigationId,
                researchSummary,
                categoryOrder,
                fuzzyNames: postFilters.fuzzyNames,
                suggestedNames: postFilters.suggestedNames,
                postFilters: postFiltersForDownload,
                timezone,
                language,
                isCustomFuzzy,
                contentsOptions: contentsOptions,
                startEachArticleOnNewPage,
                singleSource,
            }
        );

        payload.postFilters = renameUboCategoryInPostFilters(payload.postFilters);
        payload = { ...payload, ...addUboDataForDelivery(model, searchResults) };

        SnapshotDeliveryUtils.addSnapshotToPayload(state, contentsOptions, payload);

        ReportBuilderApi.downloadElasticAllArticles(payload)
            .then((response) => {
                notificationService.pollReportDownload(response.reportId, response.id);
                return response;
            })
            .catch(() => {
                dispatchErrorMessage({
                    title: 'DeliveryService.downloadArticleFail.title',
                    message: 'DeliveryService.downloadArticleFail.description',
                });
            });
    } else {
        const {
            articleType,
            researchSummary,
            categoryOrder,
            articles,
            searchQuery,
            searchQueryType,
            originalArticleType,
            useNewResearchSummary,
        } = model;
        const additionalPostFilterData = getAdditionalDataForPostFilters(articleType, model, searchResults, state);
        const documentAccessType =
            articleType === CATEGORY_NAMES.ESG_RATINGS ? DOC_TYPE_SEARCH_EVENT_REQUEST.SIMPLE : undefined;
        const documentsToBeAdded = articles.map((article) => {
            return {
                id: article.id,
                title: article.title,
                uboTradeUp: article.isBranch,
                persistedNodes: article.persistedNodes,
                singleSource: article.singleSource,
                documentAccessType,
            };
        });

        let message =
            model.articles && model.articles.length === 1
                ? 'DeliveryService.processingDocument.withCount'
                : 'DeliveryService.processingDocuments.withCount';

        dispatchSuccessMessage({
            message,
            messageParameters: model.articles.length,
            type: BACKGROUND_MESSAGE_TYPE_DOWNLOAD,
        });

        let payload = Object.assign(
            {},
            {
                fuzzyNames: postFilters.fuzzyNames,
                suggestedNames: postFilters.suggestedNames,
                postFilters: restructurePostFilters(postFilters, additionalPostFilterData, originalArticleType),
                documentIds: documentsToBeAdded,
                downloadInfo,
                researchSummary: !useNewResearchSummary ? researchSummary : undefined,
                categoryOrder,
                searchQuery,
                costCode,
                billingId: billingId,
                investigationId: investigationId,
                searchQueryType,
                timezone,
                language,
                contentsOptions: model.contentsOptions,
                startEachArticleOnNewPage,
            }
        );

        payload.postFilters = renameUboCategoryInPostFilters(payload.postFilters);
        payload = { ...payload, ...addUboTableInfo(model) };

        SnapshotDeliveryUtils.addSnapshotToPayload(state, contentsOptions, payload);

        ReportBuilderApi.downloadElasticSelectedArticles(payload)
            .then((response) => {
                notificationService.pollReportDownload(response.reportId, response.id);
                return response;
            })
            .catch(() => {
                dispatchErrorMessage({
                    title: 'DeliveryService.downloadArticleFail.title',
                    message: 'DeliveryService.downloadArticleFail.description',
                });
            });
    }
}

export function downloadElasticDocument(downloadInfo: DownloadInfoType, model: ModelType, postFilters: PostFilterType) {
    dispatchSuccessMessage({
        message: 'DeliveryService.processingDocument.withCount',
        type: BACKGROUND_MESSAGE_TYPE_DOWNLOAD,
    });

    const {
        researchSummary,
        categoryOrder,
        articles,
        searchQuery,
        searchQueryType,
        singleSource,
        originalArticleType,
    } = model;
    const documentId = getDocumentData(articles, 'id');
    const documentTitle = getDocumentData(articles, 'title');
    const documentTradeUp = getDocumentData(articles, 'tradeUp');
    const documentPersistedNodes = getDocumentData(articles, 'persistedNodes');

    const store = reduxStore.getState();
    const billingId = store.investigation.billingId;
    const investigationId = store.investigation.id;
    const costCode = costCodeUtils.getCostCode();
    const timezone = store.user.timezone;
    const searchResults = store.searchResults;
    const language = getMongoLanguageKey(store.user.preferences.language);

    let categoryKey = model.articleType;
    if (CATEGORIES[model.articleType]) {
        let subcategories = CATEGORIES[model.articleType].children;
        let keys = categoryUtils.getConfigKeys(categoryKey);
        if (subcategories && keys) {
            let subcategory = categoryUtils.getSubcategory(
                subcategories,
                categoryKey,
                model[keys.matchConfigKey],
                keys.matchConfigKey
            );
            if (subcategory) {
                categoryKey = subcategory.key;
            }
        }
    }
    const additionalPostFilterData = getAdditionalDataForPostFilters(categoryKey, model, searchResults, store);

    const documentIdsObj = {
        id: documentId,
        title: documentTitle,
        uboTradeUp: documentTradeUp,
        persistedNodes: documentPersistedNodes,
        singleSource,
        documentAccessType: 'SIMPLE',
    };
    addEsgAccessType(documentIdsObj, model.articleType, store);

    let payload = Object.assign(
        {},
        {
            documentIds: [documentIdsObj],
            postFilters: restructurePostFilters(postFilters, additionalPostFilterData, originalArticleType),
            downloadInfo,
            researchSummary,
            categoryOrder,
            searchQuery,
            costCode,
            billingId: billingId,
            investigationId: investigationId,
            searchQueryType,
            timezone,
            language,
            contentsOptions: DEFAULT_DELIVERY_OPTIONS.documentViewDeliveries,
        }
    );

    payload.postFilters = renameUboCategoryInPostFilters(payload.postFilters);
    payload = { ...payload, ...addUboTableInfo(model) };

    ReportBuilderApi.downloadElasticSelectedArticles(payload)
        .then((response) => {
            notificationService.pollReportDownload(response.reportId, response.id);
            return response;
        })
        .catch(() => {
            dispatchErrorMessage({
                title: 'DeliveryService.downloadArticleFail.title',
                message: 'DeliveryService.downloadArticleFail.description',
            });
        });
}

//********* end download documents ******//

//********* print documents *********//
function printElasticArticles(
    searchQueryRequest: SearchQueryRequestType,
    printInfo: PrintInfoType,
    model: ModelType,
    postFilters: PostFilterType
) {
    const store = reduxStore.getState();
    const billingId = store.investigation.billingId;
    const investigationId = store.investigation.id;
    const costCode = costCodeUtils.getCostCode();
    const timezone = store.user.timezone;
    const searchResults = store.searchResults;
    const additionalPostFilterData = getAdditionalDataForPostFilters(model.articleType, model, searchResults, store);
    const language = getMongoLanguageKey(store.user.preferences.language);
    const startEachArticleOnNewPage = store.user.preferences.startEachArticleOnNewPage;
    const maxNumberOfArticles = store.user.maxNumberOfArticles;
    const isCustomFuzzy = store.searchParams.isCustomFuzzy;

    if (model.resultListType === ALL_ARTICLES_REPORT) {
        const {
            totalArticlesCount,
            researchSummary,
            categoryOrder,
            singleSource,
            contentsOptions,
            originalArticleType,
        } = model;
        let message = 'DeliveryService.processingDocuments.withCount';

        if (totalArticlesCount === 1) {
            message = 'DeliveryService.processingDocument.withCount';
        } else if (totalArticlesCount > maxNumberOfArticles) {
            message = 'DeliveryService.processingDocuments.withoutCount';
        }

        dispatchSuccessMessage({
            message,
            messageParameters: totalArticlesCount,
            type: BACKGROUND_MESSAGE_TYPE_PRINT,
        });

        let { searchQuery, searchQueryType, prefilterQuery } = searchQueryRequest;

        let payload = Object.assign(
            {},
            {
                printInfo,
                searchQuery,
                searchQueryType,
                prefilterQuery,
                costCode,
                researchSummary,
                categoryOrder,
                fuzzyNames: postFilters.fuzzyNames,
                suggestedNames: postFilters.suggestedNames,
                postFilters: [
                    restructurePostFiltersForAllDocs(postFilters, additionalPostFilterData, originalArticleType),
                ],
                billingId: billingId,
                investigationId: investigationId,
                timezone,
                language,
                isCustomFuzzy,
                contentsOptions: contentsOptions,
                startEachArticleOnNewPage,
                singleSource,
            }
        );

        payload.postFilters = renameUboCategoryInPostFilters(payload.postFilters);
        payload = { ...payload, ...addUboDataForDelivery(model, searchResults) };

        SnapshotDeliveryUtils.addSnapshotToPayload(store, contentsOptions, payload);

        ReportBuilderApi.printElasticAllArticles(payload)
            .then((printBuild) => {
                notificationService.pollPrintReport(printBuild.reportId, printBuild.id);
                return printBuild;
            })
            .catch(() => {
                dispatchErrorMessage({
                    title: 'DeliveryService.printArticleFail.title',
                    message: 'DeliveryService.printArticleFail.description',
                });
            });
    } else {
        const {
            researchSummary,
            categoryOrder,
            articles,
            searchQuery,
            searchQueryType,
            originalArticleType,
            useNewResearchSummary,
            contentsOptions,
        } = model;
        const documentsToBeAdded = articles.map((article) => {
            return {
                id: article?.id,
                title: article?.title,
                uboTradeUp: article?.isBranch,
                persistedNodes: article?.persistedNodes,
                singleSource: article?.singleSource,
                documentAccessType: 'SIMPLE',
            };
        });

        const message =
            articles.length === 1
                ? 'DeliveryService.processingDocument.withCount'
                : 'DeliveryService.processingDocuments.withCount';

        dispatchSuccessMessage({
            message,
            messageParameters: articles.length,
            type: BACKGROUND_MESSAGE_TYPE_PRINT,
        });

        let payload = Object.assign(
            {},
            {
                fuzzyNames: postFilters.fuzzyNames,
                suggestedNames: postFilters.suggestedNames,
                postFilters: restructurePostFilters(postFilters, additionalPostFilterData, originalArticleType),
                documentIds: documentsToBeAdded,
                printInfo,
                researchSummary: !useNewResearchSummary ? researchSummary : undefined,
                categoryOrder,
                searchQuery,
                costCode,
                searchQueryType,
                billingId: billingId,
                investigationId: investigationId,
                timezone,
                language,
                contentsOptions: model.contentsOptions,
                startEachArticleOnNewPage,
            }
        );

        payload.postFilters = renameUboCategoryInPostFilters(payload.postFilters);
        payload = { ...payload, ...addUboTableInfo(model) };

        SnapshotDeliveryUtils.addSnapshotToPayload(store, contentsOptions, payload);

        ReportBuilderApi.printElasticSelectedArticles(payload)
            .then((printBuild) => {
                notificationService.pollPrintReport(printBuild.reportId, printBuild.id);
                return printBuild;
            })
            .catch(() => {
                dispatchErrorMessage({
                    title: 'DeliveryService.printArticleFail.title',
                    message: 'DeliveryService.printArticleFail.description',
                });
            });
    }
}

export function printElasticDocument(printInfo: PrintInfoType, model: ModelType, postFilters: PostFilterType) {
    dispatchSuccessMessage({
        message: 'DeliveryService.processingDocument.withCount',
        type: BACKGROUND_MESSAGE_TYPE_PRINT,
    });

    const store = reduxStore.getState();
    const investigationId = store.investigation.id;
    const {
        articleType,
        researchSummary,
        categoryOrder,
        articles,
        searchQuery,
        searchQueryType,
        singleSource,
        originalArticleType,
    } = model;
    const documentId = getDocumentData(articles, 'id');
    const documentTitle = getDocumentData(articles, 'title');
    const documentTradeUp = getDocumentData(articles, 'tradeUp');
    const documentPersistedNodes = getDocumentData(articles, 'persistedNodes');
    const costCode = costCodeUtils.getCostCode();
    const timezone = store.user.timezone;
    const searchResults = store.searchResults;
    const additionalPostFilterData = getAdditionalDataForPostFilters(articleType, model, searchResults, store);
    const language = getMongoLanguageKey(store.user.preferences.language);

    const billingId = store.investigation.billingId;

    const documentIdsObj = {
        id: documentId,
        title: documentTitle,
        uboTradeUp: documentTradeUp,
        persistedNodes: documentPersistedNodes,
        singleSource,
        documentAccessType: 'SIMPLE',
    };
    addEsgAccessType(documentIdsObj, articleType, store);

    let payload = Object.assign(
        {},
        {
            fuzzyNames: postFilters.fuzzyNames,
            suggestedNames: postFilters.suggestedNames,
            postFilters: restructurePostFilters(postFilters, additionalPostFilterData, originalArticleType),
            documentIds: [documentIdsObj],
            printInfo,
            researchSummary,
            categoryOrder,
            searchQuery,
            searchQueryType,
            costCode,
            billingId: billingId,
            investigationId,
            timezone,
            language,
            contentsOptions: DEFAULT_DELIVERY_OPTIONS.documentViewDeliveries,
        }
    );

    payload.postFilters = renameUboCategoryInPostFilters(payload.postFilters);
    payload = { ...payload, ...addUboTableInfo(model) };
    ReportBuilderApi.printElasticSelectedArticles(payload)
        .then((printBuild) => {
            notificationService.pollPrintReport(printBuild.reportId, printBuild.id);
            return printBuild;
        })
        .catch(() => {
            dispatchErrorMessage({
                title: 'DeliveryService.printArticleFail.title',
                message: 'DeliveryService.printArticleFail.description',
            });
        });
}

//********* end print documents *********//

//********* print reports *********//
function printReports(model) {
    let printData = {
        comments: model.comments,
    },
        reportsIds = model.reportListIds,
        timezone = reduxStore.getState().user.timezone,
        language = getMongoLanguageKey(reduxStore.getState().user.preferences.language),
        startEachArticleOnNewPage = reduxStore.getState().user.preferences.startEachArticleOnNewPage,
        message =
            model.reportListIds?.length === 1 ? 'DeliveryService.printSingleReport' : 'DeliveryService.printReports';

    dispatchSuccessMessage({
        title: 'DeliveryService.processing',
        message,
        messageParameters: model.reportListIds?.length,
        type: BACKGROUND_MESSAGE_TYPE_PRINT,
    });

    let payload = {
        printInfo: printData,
        reportIds: reportsIds,
        timezone: timezone,
        language,
        reportType: model.reportType,
        contentsOptions: model.contentsOptions,
        startEachArticleOnNewPage,
    };

    ReportBuilderApi.sendPrintReports(payload)
        .then((response) => {
            notificationService.pollPrintReport(response.reportId, response.id);
            return response;
        })
        .catch((ex) => {
            let description, exceptionContent;

            try {
                exceptionContent = JSON.parse(ex.response.text);
                description = exceptionContent.message.includes(ERRORS.TOO_MANY_REPORTS_PRINT)
                    ? 'DeliveryService.printReportsFail.description'
                    : 'DeliveryService.downloadArticleFail.description2';
            } catch (e) {
                description = 'DeliveryService.downloadArticleFail.description2';
            }

            dispatchErrorMessage({
                title: 'DeliveryService.printArticleFail.title',
                message: description,
                messageParameters: exceptionContent && exceptionContent.maxValue,
            });
        });
}

function printReportCategories(model) {
    let timezone = reduxStore.getState().user.timezone;
    let language = getMongoLanguageKey(reduxStore.getState().user.preferences.language);
    let startEachArticleOnNewPage = reduxStore.getState().user.preferences.startEachArticleOnNewPage;
    let printData = buildPrintData(model, timezone, language, startEachArticleOnNewPage);
    let snippetsCount = model.count;
    let message = snippetsCount === 1 ? 'DeliveryService.printSingleArticles' : 'DeliveryService.printArticles';

    dispatchSuccessMessage({
        title: 'DeliveryService.processing',
        message,
        messageParameters: snippetsCount,
        type: BACKGROUND_MESSAGE_TYPE_PRINT,
    });

    ReportBuilderApi.sendPrintReportCategories(printData)
        .then((response) => {
            notificationService.pollPrintReport(response.reportId, response.id);
            return response;
        })
        .catch(() => {
            dispatchErrorMessage({
                title: 'DeliveryService.printArticleFail.title',
                message: 'DeliveryService.printArticleFail.description',
            });
        });
}

//********* end print reports *********//

//********* download reports *********//
function downloadReports(model: ModelType): void {
    const downloadData = buildDownloadData(model);
    ReportBuilderUtils.isReportCombined(model.reportType)
        ? downloadCombinedReports(model, downloadData)
        : downloadSeparateReports(model, downloadData);
}

export const downloadCombinedReports = async (model: ModelType, downloadData: DownloadDataType): Promise<any> => {
    const message = 'DeliveryService.downloadSingleReport';
    dispatchSuccessMessage({ message, type: BACKGROUND_MESSAGE_TYPE_DOWNLOAD });

    downloadData.reportTitle = downloadData.downloadInfo.fileName;
    const [response, error] = await ReportBuilderApi.downloadReports(downloadData);

    if (error) handleReportsDownloadError(error);
    if (response) {
        const { pageSize, publicRecordsOn } = model.deliveryData;

        await ReportBuilderService.loadReport(undefined, publicRecordsOn, pageSize);
        const reportId = response.reportId;

        model.deselectAllReports([]);

        // @TODO this consecutive redux updated are re-rendering the component every time -> do a bulk properties update

        reduxStore.dispatch(reportBuilderActions.updateReportProperty(reportId, 'showSpinner', true));
        reduxStore.dispatch(reportBuilderActions.updateReportProperty(reportId, 'buildStatus', 'STARTED'));
        reduxStore.dispatch(reportBuilderActions.updateReportProperty(reportId, 'buildId', response.id));

        notificationService.pollReport(reportId, response.id, model.fileName, reduxStore.dispatch);
    }
};

async function downloadSeparateReports(model, downloadData) {
    const message =
        model.reportListIds?.length === 1 ? 'DeliveryService.downloadSingleReport' : 'DeliveryService.downloadReports';

    dispatchSuccessMessage({
        message,
        messageParameters: model.reportListIds?.length,
        type: BACKGROUND_MESSAGE_TYPE_DOWNLOAD,
    });

    const [response, error] = await ReportBuilderApi.downloadReports(downloadData);
    if (error) handleReportsDownloadError(error);

    if (response) {
        notificationService.pollReportDownload(response.reportId, response.id);
    }
}

function handleReportsDownloadError(exception) {
    let description;
    let exceptionContent;
    try {
        exceptionContent = JSON.parse(exception.response.text);
        description = exceptionContent.message.includes(ERRORS.TOO_MANY_REPORTS_DOWNLOAD)
            ? 'DeliveryService.downloadReportsFail.description'
            : 'DeliveryService.downloadArticleFail.description2';
    } catch (e) {
        description = 'DeliveryService.downloadArticleFail.description2';
    }
    dispatchErrorMessage({
        title: 'DeliveryService.downloadArticleFail.title',
        message: description,
        messageParameters: exceptionContent && exceptionContent.maxValue,
    });
}

function downloadReportCategories(model) {
    let snippetsCount = model.count;
    let timezone = reduxStore.getState().user.timezone;
    let language = getMongoLanguageKey(reduxStore.getState().user.preferences.language);
    let startEachArticleOnNewPage = reduxStore.getState().user.preferences.startEachArticleOnNewPage;
    let downloadData = buildDownloadCategoryData(model, timezone, language, startEachArticleOnNewPage);

    let message = snippetsCount === 1 ? 'DeliveryService.downloadSingleArticles' : 'DeliveryService.downloadArticles';

    dispatchSuccessMessage({
        message,
        messageParameters: snippetsCount,
        type: BACKGROUND_MESSAGE_TYPE_DOWNLOAD,
    });

    ReportBuilderApi.downloadReportCategories(downloadData)
        .then((response) => {
            notificationService.pollReportDownload(response.reportId, response.id);
            return response;
        })
        .catch(() => {
            dispatchErrorMessage({
                title: 'DeliveryService.downloadArticleFail.title',
                message: 'DeliveryService.downloadArticleFail.description2',
            });
        });
}

//********* end download reports *********//

//********* start email reports *********//

function emailReports(model) {
    let emailInfo = buildEmailInfo(model),
        reportIds = model.reportListIds,
        timezone = reduxStore.getState().user.timezone,
        language = getMongoLanguageKey(reduxStore.getState().user.preferences.language),
        startEachArticleOnNewPage = reduxStore.getState().user.preferences.startEachArticleOnNewPage,
        message =
            model.reportListIds?.length === 1 ? 'DeliveryService.emailSingleReport' : 'DeliveryService.emailReports';

    dispatchSuccessMessage({
        title: 'DeliveryService.sending',
        message,
        messageParameters: model.reportListIds?.length,
    });

    let payload = {
        reportIds,
        emailInfo,
        timezone,
        language,
        reportType: model.reportType,
        contentsOptions: model.contentsOptions,
        startEachArticleOnNewPage,
    };

    ReportBuilderApi.sendEmailReports(payload)
        .then((response) => {
            notificationService.pollEmailReports(response.id);
            return response;
        })
        .catch((ex) => {
            let description, exceptionContent;

            try {
                exceptionContent = JSON.parse(ex.response.text);
                description = exceptionContent.message.includes(ERRORS.TOO_MANY_REPORTS_EMAIL)
                    ? 'DeliveryService.emailReportsFail.description'
                    : 'DeliveryService.downloadArticleFail.description2';
            } catch (e) {
                description = 'DeliveryService.downloadArticleFail.description2';
            }

            dispatchErrorMessage({
                title: 'DeliveryService.emailFailedToSend',
                message: description,
                messageParameters: exceptionContent && exceptionContent.maxValue,
            });
        });
}

function sendEmailCategoryReports(model) {
    let category = model.reportCategory;
    let emailInfo = buildEmailInfo(model);
    let snippetsCount = model.count;
    let timezone = reduxStore.getState().user.timezone;
    let language = getMongoLanguageKey(reduxStore.getState().user.preferences.language);
    let startEachArticleOnNewPage = reduxStore.getState().user.preferences.startEachArticleOnNewPage;

    let message = snippetsCount === 1 ? 'DeliveryService.emailSingleArticles' : 'DeliveryService.emailArticles';

    dispatchSuccessMessage({ title: 'DeliveryService.sending', message, messageParameters: snippetsCount });

    let payload = {
        reportId: model.reportId,
        category: category,
        emailInfo: emailInfo,
        timezone,
        language,
        contentsOptions: model.contentsOptions,
        startEachArticleOnNewPage,
    };

    ReportBuilderApi.sendEmailCategoryReports(payload)
        .then((response) => {
            notificationService.pollEmailReports(response.id);
            return response;
        })
        .catch(() => {
            utils.showNotificationsMessage({
                messageText: 'ReportBuilderPage_Errors.sendingEmails',
                messageType: 'system-error',
            });
            reduxStore.dispatch(backgroundActions.clearBackgroundMessages());
        });
}

//********* end email reports *********//

//********* start download reports *********//

function downloadReport(model) {
    let reports = reduxStore.getState().reportBuilder.reports;
    let startEachArticleOnNewPage = reduxStore.getState().user.preferences.startEachArticleOnNewPage;

    let report = reports.find((entry) => {
        return entry.id === model.reportId;
    });
    let message = 'DeliveryService.downloadSingleReport';
    // Get the interface language, replace character according to the db naming
    let interfaceLanguage = getMongoLanguageKey(reduxStore.getState().user.preferences.language);

    reduxStore.dispatch(reportBuilderActions.setSelectedReport(report));
    reduxStore.dispatch(reportBuilderActions.updateReportProperty(model.reportId, 'showSpinner', true));
    reduxStore.dispatch(reportBuilderActions.updateReportProperty(model.reportId, 'buildStatus', 'STARTED'));
    dispatchSuccessMessage({ message, type: BACKGROUND_MESSAGE_TYPE_DOWNLOAD });

    let payload = {
        filetype: model.docType,
        filename: model.fileName,
        startEachArticleOnNewPage,
        language: interfaceLanguage,
        contentsOptions: model.contentsOptions,
    };

    ReportBuilderApi.getReportBuild(model.reportId, payload)
        .then((build) => {
            reduxStore.dispatch(reportBuilderActions.updateReportProperty(model.reportId, 'buildId', build.buildId));
            notificationService.pollReport(model.reportId, build.buildId, model.fileName, reduxStore.dispatch);

            return build;
        })
        .catch(() => {
            dispatchErrorMessage({
                title: 'DeliveryService.downloadArticleFail.title',
                message: 'DeliveryService.downloadArticleFail.description2',
            });
        });
}

const downloadBatchReport = (model, dispatch = reduxStore.dispatch) => {
    const { reportId, buildId, reportTitle } = model;

    dispatch(reportBuilderActions.addBatchReportToPooling(reportId));
    notificationService.pollBatchReport(reportId, buildId, reportTitle, dispatch);
};

//********* end download reports *********//

//********* delete report *********//

function deleteReport(model) {
    let titleOfReport = model.fileName;
    let reports = reduxStore.getState().reportBuilder.reports;

    let report = reports.find((entry) => {
        return entry.id === model.reportId;
    });

    reduxStore.dispatch(reportBuilderActions.setSelectedReport(report));
    reduxStore.dispatch(
        backgroundActions.setSuccessBackgroundMessages({
            message: 'ReportBuilderPage_Notifications.deleteReport',
            messageParameters: titleOfReport,
            isVisible: true,
        })
    );

    ReportBuilderService.deleteReport(model.reportId, titleOfReport);

    if (typeof model.onSelectionChange === 'function') {
        ReportBuilderUtils.updateReportBuilderSelection(reports, report.id, model.onSelectionChange);
    }
}

//********* end delete report *********//

//*********  delete report note *********//

function deleteNoteFromReport(model) {
    ReportBuilderApi.deleteNoteFromReportApi(model.reportId)
        .then((response) => {
            reduxStore.dispatch(reportBuilderActions.updateReportProperty(model.reportId, 'note', null));
            reduxStore.dispatch(reportBuilderActions.updateDeliveryStatusesForChildAndParent(model.reportId));

            return response;
        })
        .catch(() => {
            utils.showNotificationsMessage({
                messageText: 'ReportBuilderPage_Errors.deleteNoteFromReport',
                messageType: 'system-error',
            });
        });
}

//*********  end delete report note *********//

// applies for both no documents of interest and no documents found note
export const isNoDocumentNote = (docId: string | void): boolean =>
    docId === NO_DOCUMENTS_FOUND_DOC_ID || docId === NO_DOCUMENTS_OF_INTEREST;

//*********  delete document from report *********//

export const deleteDocument = async (model: ModelType): Promise<any> => {
    const {
        reportId,
        category,
        documentId,
        onAutosave,
        onUpdateReportBuilderPage,
        articles,
    }: {
        reportId: string,
        category?: string,
        documentId?: string,
        onAutosave: ({ hasError?: boolean, show: boolean }) => void,
            onUpdateReportBuilderPage ?: (string) => void,
            articles: Array <string>,
    } = model;

if (articles && articles.length && reportId && onAutosave && typeof onAutosave === 'function') {
    let reportSnippetId: string = articles[0];
    onAutosave({ show: true });

    const [response: JSONResponse, error: ErrorResponse] = await ReportBuilderApi.deleteArticleFromReport(
        reportSnippetId
    );

    if (response) {
        onAutosave({ show: false, hasError: false });
        reduxStore.dispatch(reportBuilderActions.updateDeliveryStatusesForChildAndParent(reportId));
        reduxStore.dispatch(
            reportBuilderActions.deleteDocument(reportId, reportSnippetId, onUpdateReportBuilderPage)
        );

        // no document of interest and no document found are already considered as 0 documents in count so there is no need to update the overall counts if they are removed
        if (!isNoDocumentNote(documentId) && !!category) {
            reduxStore.dispatch(reportBuilderActions.updateDocumentsCounts(reportId, [reportSnippetId], category));
        }
    }

    if (error) {
        onAutosave({ show: false, hasError: true });
        utils.showNotificationsMessage({
            messageText: 'ReportBuilderPage_Errors.deleteArticlesFromReport',
            messageType: 'system-error',
        });
    }
} else {
    console.error('Required params are missing from the model');
}
};

//*********  end delete document from report *********//

//*********  delete document note from report *********//

function deleteNoteFromDocument(model: ModelType) {
    let reportSnippetId = model.articles[0];
    let reportId = model.reportId;

    model.onAutosave({ show: true });
    ReportBuilderApi.deleteNoteFromReportSnippet(reportSnippetId)
        .then((response) => {
            model.onAutosave({ show: false, hasError: false });
            reduxStore.dispatch(reportBuilderActions.updateDeliveryStatusesForChildAndParent(reportId));
            reduxStore.dispatch(reportBuilderActions.deleteNoteFromArticle(reportId, reportSnippetId));

            return response;
        })
        .catch((error) => {
            console.log('--- removing notes from article ', error);

            model.onAutosave({ show: false, hasError: true });
            utils.showNotificationsMessage({
                messageText: 'ReportBuilderPage_Errors.deleteNoteFromArticle',
                messageType: 'system-error',
            });
        });
}

//*********  end delete document note from report *********//

function restructurePostFilters(postFilters, additionalData, originalArticleType) {
    let postFiltersClone = { ...cloneDeep(postFilters), ...additionalData };

    delete postFiltersClone.searchQuery;
    delete postFiltersClone.searchQueryType;

    if (categoryUtils.isNewsSource(originalArticleType)) {
        postFiltersClone = withContentSourceFilter().extendConfig(originalArticleType, postFiltersClone);
    }

    return postFiltersClone;
}

function restructurePostFiltersForAllDocs(postFilters, additionalData, originalArticleType) {
    let restructuredPostFilters = restructurePostFilters(postFilters, additionalData, originalArticleType);
    delete restructuredPostFilters.fuzzyNames;
    delete restructuredPostFilters.suggestedNames;

    return restructuredPostFilters;
}

export function getAdditionalDataForAllArticlesDownload(
    categorySearchResults: CategorySearchResultsType
): AdditionalDataForAllArticlesDownloadType {
    let { prefilterQuery, proximity, dateRange, excludeTerms, includeTerms, sort, terms } =
        categorySearchResults.postFilters;
    const customPrefilterQuery = categorySearchResults.name === CATEGORY_NAMES.ESG_RATINGS ? '' : prefilterQuery;

    return {
        prefilterQuery: customPrefilterQuery,
        proximity,
        dateRange,
        excludeTerms,
        includeTerms,
        sort,
        terms,
    };
}

export function getAdditionalDataForPostFilters(
    category: string,
    model: ModelType,
    searchResults: CategorySearchResultsType,
    store: Object
): Object {
    let results = 0;
    let additionalPostFilters = {};

    if (store.user.newsExcludeTogglesEnabled && categoryUtils.isNewsSource(category)) {
        additionalPostFilters[POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS] =
            searchResults[category].postFilters[POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS];
        additionalPostFilters[POST_FILTER_EXCLUDE_NEWS_WIRES] =
            searchResults[category].postFilters[POST_FILTER_EXCLUDE_NEWS_WIRES];
    }

    if (categoryUtils.isExtended(category)) {
        results = searchResults[category].count;
        category = categoryUtils.getParent(category);
    } else {
        results = model.totalArticlesCount;
    }

    let { contentLanguage, contentSource, customNews, postFilters } = model;

    return {
        ...additionalPostFilters,
        category,
        contentLanguage,
        contentSource,
        customNews,
        newsSource: categoryUtils.isNewsSource(category)
            ? postFilters && utils.mapNewsSourceNameToKey(postFilters.newsSource)
            : null,
        results,
    };
}

function getPostFilters(category, searchResults, model, store) {
    let additionalArticlesDownloadData = getAdditionalDataForAllArticlesDownload(searchResults[category]);

    return withContentSourceFilter().extendConfig(category, {
        ...additionalArticlesDownloadData,
        ...getAdditionalDataForPostFilters(category, model, searchResults, store),
    });
}

function saveArticleNote(model) {
    model.onAutosave({ show: true });

    ReportBuilderApi.addNoteToReportSnippet(model.article.id, model.article.note)
        .then((response) => {
            reduxStore.dispatch(reportBuilderActions.updateDeliveryStatusesForChildAndParent(model.reportId));
            reduxStore.dispatch(
                reportBuilderActions.updateNoteOnArticle(model.reportId, model.article.id, model.article.note)
            );
            model.onAutosave({ show: false, hasError: false });

            return response;
        })
        .catch(() => {
            model.onAutosave({ show: false, hasError: true });

            utils.showNotificationsMessage({
                messageText: 'ReportBuilderPage_Errors.addNoteToArticle',
                messageType: 'system-error',
            });
        });
}

function hideSnapshotPage(state) {
    if (state.isUserMIP) {
        reduxStore.dispatch(userPreferencesActions.updateSnapshotVisibility(false));
    } else {
        userPreferenceApi
            .updateUserPreference({
                userPreferences: {
                    generalSettings: {
                        showSnapshot: false,
                    },
                },
            })
            .then((response) => {
                reduxStore.dispatch(userPreferencesActions.updateSnapshotVisibility(false));

                return response;
            });
    }
}

export function deletePublicRecordAlert(model: ModelType) {
    let alertIds = model.alertIds;
    reduxStore.dispatch(alertsManagerActions.deletePublicRecordsAlerts(alertIds));
}

export function deleteAlert(model: ModelType) {
    let alertIds = model.alertIds;
    let alertType = model.alertType;
    reduxStore.dispatch(alertsManagerActions.deleteSelectedAlerts([alertIds], alertType));
}

export function deleteSelectedAlerts(model) {
    let alertIds = model.alertIds;
    let alertType = model.alertType;
    reduxStore.dispatch(alertsManagerActions.deleteSelectedAlerts(alertIds, alertType));
}

export function getMongoLanguageKey(interfaceLanguage: string): string {
    if (!interfaceLanguage) {
        return '';
    }

    return interfaceLanguage === 'nl' ? 'nl_nl' : interfaceLanguage.replace('-', '_');
}

function addUboDataForDelivery(model, searchResults) {
    if (model.articleType.includes(UBO_MAIN_CATEGORY)) {
        let dunsList = [];
        let uboSearchTerms = [];
        let downloadFullReport = false;

        // check to see if the delivery is 'Download Full Report' (otherwise type would be string)
        if (Array.isArray(model.articleType)) {
            downloadFullReport = true;
        }

        // if category is the main one and not a child
        if (model.articleType === UBO_MAIN_CATEGORY || downloadFullReport) {
            // we have to send the duns numbers or searchTerms for all ubo children
            Object.keys(searchResults).forEach((category) => {
                if (category.includes(UBO_MAIN_CATEGORY)) {
                    !!searchResults[category].duns && dunsList.push(searchResults[category].duns);
                    !!searchResults[category].term && uboSearchTerms.push(searchResults[category].term);
                }
            });
            // otherwise, send the duns number/searchTerm for the specific child
        } else {
            !!searchResults[model.articleType].duns && dunsList.push(searchResults[model.articleType].duns);
            !!searchResults[model.articleType].term && uboSearchTerms.push(searchResults[model.articleType].term);
        }

        return {
            dunsList,
            uboSearchTerms,
        };
    }
}

export function renameUboCategoryInPostFilters(postFilters: Array<PostFilterType> | PostFilterType): any {
    let formattedPosFilters = cloneDeep(postFilters);

    if (Array.isArray(postFilters)) {
        let UBOFound = false;

        formattedPosFilters = postFilters
            .map((postFilter) => {
                if (postFilter.category.indexOf(UBO_MAIN_CATEGORY) > -1) {
                    // removing extra ubo categories
                    if (UBOFound) return;

                    postFilter.category = UBO_MAIN_CATEGORY;
                    UBOFound = true;
                }
                return postFilter;
            })
            .filter((postFilter) => postFilter);
    } else if (formattedPosFilters.category.includes(UBO_MAIN_CATEGORY)) {
        formattedPosFilters.category = UBO_MAIN_CATEGORY;
    }
    return formattedPosFilters;
}

// for UBO category, send filtering and sorting options for BE to be able to build the table in report properly
function addUboTableInfo(model) {
    if (model.articleType.includes(UBO_MAIN_CATEGORY)) {
        const docViewDeliveryInfo = reduxStore.getState().ubo.docViewDeliveryInfo;
        const sortingFields = docViewDeliveryInfo.sorting;
        const filteringFields = docViewDeliveryInfo.filtering;

        return {
            sortingFields,
            filteringFields,
        };
    }
}

export function isArticleDataAvailable(article: Array<ArticleType>): boolean {
    return !!(article && article[0]);
}

export function isUboArticleDataAvailable(article: Array<ArticleType>): boolean {
    return !!article[0].inquiryDetails;
}

export function getDocumentData(
    article: Array<ArticleType>,
    dataType: string
): UboPersistedNodeType | null | string | boolean {
    switch (dataType) {
        case 'id': {
            let documentId = null;
            if (isArticleDataAvailable(article)) {
                if (article[0].errorCode) {
                    documentId = article[0].duns;
                } else {
                    documentId = isUboArticleDataAvailable(article)
                        ? article[0].inquiryDetails[DOCUMENT_DATA.UBO.ID]
                        : article[0][DOCUMENT_DATA.ELASTIC.ID];
                }
            }
            return documentId;
        }
        case 'title': {
            let documentTitle = null;
            if (isArticleDataAvailable(article)) {
                if (article[0].errorCode) {
                    documentTitle = article[0].name;
                } else {
                    documentTitle = isUboArticleDataAvailable(article)
                        ? article[0].inquiryDetails[DOCUMENT_DATA.UBO.TITLE]
                        : article[0][DOCUMENT_DATA.ELASTIC.TITLE];
                }
            }
            return documentTitle;
        }
        case 'tradeUp':
            return !!(
                isUboArticleDataAvailable(article) && article[0].inquiryDetails[DOCUMENT_DATA.UBO.TRADEUP] === 'true'
            );
        case 'persistedNodes':
            return isUboArticleDataAvailable(article) ? article[0].persistedNodes : null;
        default:
            return null;
    }
}

function updateContentOptions(model) {
    let preferences = reduxStore.getState().user.preferences;
    let contentOptions = preferences.contentsOptions;
    let modifiedByUser = preferences.modifiedByUser;
    let showDeliveryOptionPreference = preferences.showDeliveryOptions;

    utils.updatePreference({
        contentsOptions: {
            ...model.contentsOptions,
            shouldContainResearchSummary: contentOptions.shouldContainResearchSummary,
        },
        showDeliveryOptions: modifiedByUser.showDeliveryOptions
            ? model.showDeliveryOptions
            : showDeliveryOptionPreference,
        modifiedByUser: modifiedByUser,
    });
}

function updateResultsListContentOptions(model) {
    let preferences = reduxStore.getState().user.preferences;
    let contentOptions = preferences.resultsContentOptions;
    let modifiedByUser = preferences.modifiedByUser;
    let showDeliveryOptionPreference = preferences.resultsListShowDeliveryOptions;

    utils.updatePreference({
        resultsContentOptions: {
            ...model.contentsOptions,
            shouldContainResearchSummary: contentOptions.shouldContainResearchSummary,
        },
        resultsListShowDeliveryOptions: modifiedByUser.resultsListShowDeliveryOptions
            ? model.showDeliveryOptions
            : showDeliveryOptionPreference,
        modifiedByUser: modifiedByUser,
    });
}

function deliverSnapshotPage(data) {
    const state = reduxStore.getState();
    const searchResults = state.searchResults;

    dispatchSuccessMessage({
        message: 'DeliveryService.downloadSingleArticles',
        type: BACKGROUND_MESSAGE_TYPE_DOWNLOAD,
    });

    data.language = getMongoLanguageKey(data.language);
    data.postFilters = buildPostFiltersForDownloadAll({
        postFilters: data.postFilters,
        model: data.model,
        searchResults,
        state,
    });

    ReportBuilderApi.downloadElasticAllArticles(data)
        .then((response) => {
            notificationService.pollReportDownload(
                response.reportId,
                response.id,
                'DeliveryService.documentReadyToDownload'
            );
            return response;
        })
        .catch(() => {
            dispatchErrorMessage({
                title: 'DeliveryService.downloadArticleFail.title',
                message: 'DeliveryService.downloadArticleFail.description',
            });
        });
}

class DeliveryService {
    model: ModelType;

    constructor(model: ModelType) {
        this.model = model;
    }

    doDelivery(state: PopupModalStateType) {
        switch (this.model.popupType) {
            case MODAL_TYPE.ELASTIC_PRINT_DOCUMENT:
                this.printDocument();
                break;
            case MODAL_TYPE.ELASTIC_DOWNLOAD_DOCUMENT:
                this.downloadDocument();
                break;
            case MODAL_TYPE.ELASTIC_EMAIL_DOCUMENT:
                this.emailDocument();
                break;
            case MODAL_TYPE.EMAIL_RESULT_LIST:
                this.emailResultList();
                updateResultsListContentOptions(this.model);
                break;
            case MODAL_TYPE.DOWNLOAD_RESULT_SNAPSHOT:
                this.downloadResultList(this.model);
                break;
            case MODAL_TYPE.DOWNLOAD_RESULT_LIST:
                this.downloadResultList(this.model);
                updateResultsListContentOptions(this.model);
                break;
            case MODAL_TYPE.PRINT_RESULT_LIST:
                this.printResultList();
                updateResultsListContentOptions(this.model);
                break;
            case MODAL_TYPE.ADD_TO_REPORT:
                this.addToReport();
                break;
            case MODAL_TYPE.PRINT_REPORTS:
            case MODAL_TYPE.PRINT_REPORTS_SEPARATE:
            case MODAL_TYPE.PRINT_REPORTS_COMBINED:
                printReports(this.model);
                updateContentOptions(this.model);
                break;
            case MODAL_TYPE.DOWNLOAD_REPORTS:
            case MODAL_TYPE.DOWNLOAD_REPORTS_SEPARATE:
            case MODAL_TYPE.DOWNLOAD_REPORTS_COMBINED:
                downloadReports(this.model);
                updateContentOptions(this.model);
                break;
            case MODAL_TYPE.EMAIL_REPORTS:
            case MODAL_TYPE.EMAIL_REPORTS_COMBINED:
            case MODAL_TYPE.EMAIL_REPORTS_SEPARATE:
                emailReports(this.model);
                updateContentOptions(this.model);
                break;
            case MODAL_TYPE.EMAIL_REPORT_CATEGORY:
                sendEmailCategoryReports(this.model);
                updateContentOptions(this.model);
                break;
            case MODAL_TYPE.DOWNLOAD_REPORT_CATEGORY:
                downloadReportCategories(this.model);
                updateContentOptions(this.model);
                break;
            case MODAL_TYPE.PRINT_REPORT_CATEGORY:
                printReportCategories(this.model);
                updateContentOptions(this.model);
                break;
            case MODAL_TYPE.DOWNLOAD_REPORT:
                downloadReport(this.model);
                updateContentOptions(this.model);
                break;
            case MODAL_TYPE.DOWNLOAD_BATCH_REPORT:
                downloadBatchReport(this.model);
                break;
            case MODAL_TYPE.DELETE_REPORT:
                deleteReport(this.model);
                break;
            case MODAL_TYPE.DELETE_REPORT_NOTE:
                deleteNoteFromReport(this.model);
                break;
            case MODAL_TYPE.DELETE_DOCUMENT_FROM_REPORT:
                deleteDocument(this.model);
                break;
            case MODAL_TYPE.DELETE_NOTE_FROM_DOCUMENT:
                deleteNoteFromDocument(this.model);
                break;
            case MODAL_TYPE.EDIT_ARTICLE_NOTE:
                saveArticleNote(this.model);
                break;
            case MODAL_TYPE.SHOW_SNAPSHOT:
                hideSnapshotPage(state);
                break;
            case MODAL_TYPE.DELETE_ALERT:
                deleteAlert(this.model);
                break;
            case MODAL_TYPE.DELETE_ALERTS:
                deleteSelectedAlerts(this.model);
                break;
            case MODAL_TYPE.DELETE_PUBLIC_RECORD_ALERT:
            case MODAL_TYPE.DELETE_PUBLIC_RECORD_ALERTS:
                deletePublicRecordAlert(this.model);
                break;
            case MODAL_TYPE.ADHOC_SEARCH:
                this.updatePostFilters();
                break;
            case MODAL_TYPE.APPLY_ADMIN_CHANGES:
            case MODAL_TYPE.DISCARD_ADMIN_CHANGES:
                this.model.action();
                break;
            case MODAL_TYPE.USER_PREFERENCES_EDIT_NEGATIVE_SEARCH_TERMS:
                this.saveCustomSearchQuery(this.model, state);
                break;
            case MODAL_TYPE.USER_PREFERENCES_DELETE_CUSTOM_NEWS_QUERY:
                this.deleteCustomNewsQuery(this.model);
                break;
            case MODAL_TYPE.USER_PREFERENCES_ADD_CUSTOM_NEWS_QUERY:
            case MODAL_TYPE.USER_PREFERENCES_EDIT_CUSTOM_NEWS_QUERY:
                this.updateCustomNewsQuery(this.model, state);
                break;
            case MODAL_TYPE.DOWNLOAD_HISTORY:
                this.model.action(this.model);
                break;
            case MODAL_TYPE.DOWNLOAD_SNAPSHOT_PAGE:
                this.buildAndDownloadSnapshotPage(this.model);
                break;
            case MODAL_TYPE.CREATE_ALERTS:
                this.createMultipleAlerts(
                    state.alert,
                    state.comments,
                    state.emailAddress,
                    state.sourceCategories,
                    state.contentLanguages,
                    this.model.paramsForUrl,
                    this.model.timezone,
                    this.model.getPayloadCallback,
                    this.model.onDeliverySuccess,
                    this.model.onDeliveryApiError
                );
                break;
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING:
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_SNAPSHOT:
                this.downloadBatchScreening(this.model);
                break;
            case MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_FULL_REPORT:
                this.downloadBatchScreening(this.model);
                updateResultsListContentOptions(this.model);
                break;
            case MODAL_TYPE.DOWNLOAD_ENTITIES:
                this.downloadEntityViewTable(this.model, state.fileName, state.downloadEntitiesFormat);
                break;
            case MODAL_TYPE.CHANGE_COLUMNS_VISIBILITY_MULTIPLE_ENTITIES:
                this.model.action(this.model);
                break;
            default:
                return;
        }
    }

    printResultList() {
        let searchQueryRequest = buildSearchQueryRequest(this.model);
        let postFilters = buildPostFilters(this.model);
        let printInfo = { comments: this.model.comments };
        printElasticArticles(searchQueryRequest, printInfo, this.model, postFilters);
    }

    printDocument() {
        let printInfo = { comments: this.model.comments };
        let postFilters = buildPostFilters(this.model);
        printElasticDocument(printInfo, this.model, postFilters);
    }

    buildAndDownloadSnapshotPage(model: ModelType) {
        const costCode = costCodeUtils.getCostCode();
        const downloadInfo = {
            fileName: this.model.fileName,
            fileType: this.model.docType,
        };

        const data = {
            ...model.storeProperties,
            snapshotDelivery: model.snapshotDelivery,
            searchQuery: model.searchQuery,
            searchQueryType: model.searchQueryType,
            prefilterQuery: model.prefilterQuery,
            categoryOrder: model.categoryOrder,
            categoryName: model.categoryName,
            researchSummary: model.researchSummary,
            postFilters: model.postFilters,
            downloadInfo,
            costCode,
            model: this.model,
            contentsOptions: DEFAULT_DELIVERY_OPTIONS.snapshotPageOnlyDelivery,
        };

        deliverSnapshotPage(data);
    }

    downloadResultList(model: ModelType) {
        const searchQueryRequest = buildSearchQueryRequest(this.model);
        const postFilters = buildPostFilters(this.model);
        const state = reduxStore.getState();
        const billingId = state.investigation.billingId;
        const investigationId = state.investigation.id;
        const costCode = costCodeUtils.getCostCode();
        const maxNumberOfArticles = state.user.maxNumberOfArticles;
        const isSnapshotVisible = state.user.preferences.generalSettings.showSnapshot;
        let contentsOptions;

        const downloadInfo = {
            comments: this.model.comments,
            fileName: this.model.fileName,
            fileType: this.model.docType,
        };

        //temporary fix until we implement content options on snapshot
        if (model.popupType === MODAL_TYPE.DOWNLOAD_RESULT_SNAPSHOT) {
            // add the default values -> keep how it currently works
            contentsOptions = cloneDeep(DEFAULT_DELIVERY_OPTIONS.snapshotDeliveries);
            contentsOptions.shouldContainSnapshot = this.model.contentsOptions.shouldContainSnapshot;
        } else {
            contentsOptions = this.model.contentsOptions;
        }

        if (!isSnapshotVisible) {
            contentsOptions.shouldContainSnapshot = false;
        }

        const data = {
            searchQueryRequest,
            downloadInfo,
            model: this.model,
            postFilters,
            billingId,
            investigationId: investigationId,
            costCode,
            contentsOptions,
            maxNumberOfArticles,
        };

        downloadElasticArticles(data);
    }

    downloadDocument() {
        let downloadInfo = {
            comments: this.model.comments,
            fileName: this.model.fileName,
            fileType: this.model.docType,
        };
        let postFilters = buildPostFilters(this.model);
        downloadElasticDocument(downloadInfo, this.model, postFilters);
    }

    emailDocument() {
        let emailInfo = buildEmailInfo(this.model);
        let postFilters = buildPostFilters(this.model);
        emailElasticDocumentDelivery(emailInfo, this.model, postFilters);
    }

    emailResultList() {
        let searchQueryRequest = buildSearchQueryRequest(this.model);
        let emailInfo = buildEmailInfo(this.model);
        let postFilters = buildPostFilters(this.model);
        emailElasticArticlesDelivery(emailInfo, searchQueryRequest, this.model, postFilters);
    }

    addToReport() {
        let currentReport = reduxStore.getState().currentReport;
        let uploadingDocuments = reduxStore.getState().articlesManager.uploadingDocuments;
        const selectedArticles = this.model.articles || [];

        // composing an array with article ids we want to exclude -> selected snippets and uploading documents
        const categoryKey = this.model.articleType;
        const excludeArticlesIds = [
            ...currentReport.articlesSnippets.map((snippet) => snippet.documentId),
            ...(uploadingDocuments[categoryKey] || []),
        ];
        let { articleType, contentLanguage, customNews, originalArticleType } = this.model;

        let addToReportParams: {
            articleType: string,
            originalArticleType: string,
            articles?: Array<{ id: string, title: string }>,
            forcedFilters?: Array<string>,
        } = {
            articleType,
            originalArticleType,
        };

        if (
            this.model.resultListType === NO_DOCUMENTS_OF_INTEREST ||
            this.model.resultListType === NO_DOCUMENTS_FOUND
        ) {
            let categoryName = categoryUtils.getCategoryName(categoryKey);
            let subCategory;
            if (categoryKey === CATEGORY_NAMES.CUSTOM_NEWS) {
                subCategory = categoryUtils.getList(categoryKey).find((cat) => cat.key === customNews);
                categoryName = subCategory
                    ? withCustomNewsTranslation(subCategory.key, this.model.searchQueryType)
                    : categoryName;
            }

            let noDocuments = {
                id: NO_DOCUMENTS_OF_INTEREST,
                title: 'No Documents of Interest found - ' + categoryName,
            };

            if (this.model.resultListType === NO_DOCUMENTS_FOUND) {
                let category = categoryName;

                if (categoryKey === CATEGORY_NAMES.NEGATIVE_NEWS) {
                    category += ` ${contentLanguage}`;
                } else if (categoryKey === CATEGORY_NAMES.CUSTOM_NEWS) {
                    category = `${CATEGORIES[articleType].name} - ${categoryName}`;
                }

                noDocuments = {
                    id: '-1',
                    title: `No documents found for your ${category} search.`,
                };
            }

            addToReportParams.articles = [noDocuments];
            addToReportParams.forcedFilters = [];

            reduxStore.dispatch(currentReportActions.addArticlesToReportThunk(addToReportParams));
        } else {
            const articlesToAdd = differenceWith(
                selectedArticles,
                excludeArticlesIds,
                (article, excludedArticleId) => article.id === excludedArticleId
            );

            if (articlesToAdd && articlesToAdd.length) {
                addToReportParams.articles = articlesToAdd;
                addToReportParams.forcedFilters = [];

                reduxStore.dispatch(currentReportActions.addArticlesToReportThunk(addToReportParams));
            }
        }
    }

    updatePostFilters() {
        let defaultDateSource = reduxStore.getState().user.preferences.generalSettings.legalSources.defaultDateSource;

        let { newsSearchSources, customQuery, searchQueryType, newsDateRange, companyDateRange, legalDateRange } =
            this.model;
        let defaultCostCode = reduxStore.getState().user.preferences.generalSettings.selectedCostCode;
        let isDefaultNewsSourcesChanged = utils.isDefaultNewsSourcesChangedInAdHoc(newsSearchSources, searchQueryType);

        // if history category is news sources, legal sources or company source, override editSearch date with specific adhoc daterange
        let historyCategoryName = reduxStore.getState().historyCategory.categoryName;
        let editSearchObject;
        if (historyCategoryName !== '') {
            editSearchObject = cloneDeep(reduxStore.getState().editSearch[historyCategoryName]);
            if (categoryUtils.isNewsSource(historyCategoryName)) {
                editSearchObject.postFilters.dateRange = newsDateRange.range;
            } else if (categoryUtils.isLegalSource(historyCategoryName)) {
                editSearchObject.postFilters.dateRange = legalDateRange.range;
            } else if (categoryUtils.isCompanySources(historyCategoryName)) {
                editSearchObject.postFilters.dateRange = companyDateRange.range;
            }
            reduxStore.dispatch(
                editSearchActions.updateEditSearchProperty(
                    editSearchObject.category,
                    POST_FILTERS_KEY,
                    editSearchObject.postFilters
                )
            );
        }

        if (!this.model.updateUserPreferencesCheckbox) {
            if (this.model.costCode === COST_CODE.customCostCode.name) {
                this.model.costCode = '';
            }
            const adHocObject = cloneDeep(this.model);
            Object.keys(adHocObject).forEach((key) => adHocObject[key] === undefined && delete adHocObject[key]);
            reduxStore.dispatch(adHocSearchActions.updateAdHocSearchObject(adHocObject));

            if (!isEmpty(editSearchObject)) {
                reduxStore.dispatch(
                    editSearchActions.updateEditSearchProperty(
                        editSearchObject?.category,
                        COST_CODE_KEY,
                        this.model.costCode
                    )
                );
            }
        } else {
            if (defaultDateSource !== this.model.lawSource) {
                reduxStore.dispatch(
                    userPreferencesActions.updateLegalSource(this.model.lawSource, legalDateRange.label)
                );
            }
            if (defaultCostCode !== this.model.costCode) {
                reduxStore.dispatch(userPreferencesActions.changeCostCode(this.model.costCode));
            }

            if (isDefaultNewsSourcesChanged) {
                let languages = [];

                customQuery.forEach((query) => {
                    if (query.checked) {
                        languages.push(query.language);
                    }
                });

                reduxStore.dispatch(
                    userPreferencesActions.updateNewsSourcesSelection(
                        PREFERENCES_KEY[searchQueryType],
                        newsSearchSources
                    )
                );
                reduxStore.dispatch(
                    userPreferencesActions.updateCheckedLanguagesUponKey(PREFERENCES_KEY[searchQueryType], languages)
                );
            }

            let prefCopy = cloneDeep(reduxStore.getState().user.preferences.generalSettings);
            let categoryPrefCopy = cloneDeep(reduxStore.getState().user.preferences[PREFERENCES_KEY[searchQueryType]]);

            delete prefCopy.proximities;
            delete prefCopy.dateRanges;
            delete prefCopy.lawSources;
            delete prefCopy.showFilters;
            delete prefCopy.negativityLevel;
            delete prefCopy.fuzzyMatch;
            delete prefCopy.availableQueryLanguages;
            delete prefCopy.proximity;
            delete prefCopy.showSnapshot;
            delete prefCopy.pageSize;
            delete prefCopy.isCostCodeRequired;
            delete prefCopy.costCodesList;
            delete prefCopy.chooseOnlyFromCostCodesList;
            prefCopy.enableFuzzy = prefCopy.isFuzzyEnabled;
            delete prefCopy.isFuzzyEnabled;
            if (prefCopy.selectedCostCode === COST_CODE.customCostCode.name) {
                prefCopy.selectedCostCode = '';
                reduxStore.dispatch(userPreferencesActions.changeCostCode(''));
            }

            reduxStore.dispatch(adHocSearchActions.updateAdHocProperty('costCode', null));

            delete categoryPrefCopy.negativityLevel;

            let preferences = {
                userPreferences: {
                    generalSettings: { ...prefCopy },
                    [PREFERENCES_KEY[searchQueryType]]: { ...categoryPrefCopy },
                },
            };

            utils.updatePreferencesAfterAdHoc(preferences);
        }
    }

    saveCustomSearchQuery(model: ModelType, state: PopupModalStateType) {
        let customQuery;

        if (model.userIsAdmin) {
            customQuery = cloneDeep(reduxStore.getState().user.adminPreferences[model.segment].customQuery);
        } else {
            customQuery = cloneDeep(reduxStore.getState().user.preferences[model.segment].customQuery);
        }

        customQuery.forEach((query) => {
            if (query.language === model.clickedTerm) {
                query.lowQueryString = utils.sanitizeSearchStringInput(state.query.low).trim();
                query.mediumQueryString = utils.sanitizeSearchStringInput(state.query.medium).trim();
                query.highQueryString = utils.sanitizeSearchStringInput(state.query.high).trim();
            }
        });

        if (model.userIsAdmin) {
            reduxStore.dispatch(userPreferencesActions.updateAdminPreferencesCustomQuery(customQuery, model.segment));
        } else {
            reduxStore.dispatch(userPreferencesActions.updateUserPreferencesCustomQuery(customQuery, model.segment));
        }
    }

    deleteCustomNewsQuery(model: ModelType) {
        if (model.userIsAdmin) {
            reduxStore.dispatch(
                userPreferencesActions.deleteAndUnlockAdminCustomNewsQuery(model.segment, model.queryName, {
                    [model.lockName]: false,
                })
            );
        } else {
            reduxStore.dispatch(userPreferencesActions.deleteCustomNewsQuery(model.segment, model.queryName));
        }
    }

    updateCustomNewsQuery(model: ModelType, state: PopupModalStateType) {
        if (model.userIsAdmin) {
            reduxStore.dispatch(
                userPreferencesActions.updateAdminUserPreferencesCustomNewsQuery(
                    model.segment,
                    utils.sanitizeSearchStringInput(state.customNewsQuery).trim(),
                    model.queryName,
                    state.editableTitle,
                    { [model.lockName]: false }
                )
            );
        } else if (model.customNewsCreatedBy === CUSTOM_NEWS_QUERY_TYPES_ID.LN) {
            reduxStore.dispatch(
                userPreferencesActions.updateUserPreferencesLnCustomNewsQuery(
                    model.segment,
                    utils.sanitizeSearchStringInput(state.customNewsQuery).trim(),
                    model.queryName,
                    state.editableTitle,
                    { [model.lockName]: false }
                )
            );
        } else {
            reduxStore.dispatch(
                userPreferencesActions.updateUserPreferencesCustomNewsQuery(
                    model.segment,
                    utils.sanitizeSearchStringInput(state.customNewsQuery).trim(),
                    model.queryName,
                    state.editableTitle,
                    { [model.lockName]: false }
                )
            );
        }
    }

    async createMultipleAlerts(
        alertData: AlertFrequencyType,
        comments: string,
        emails: string,
        categories: Array<string> = [],
        contentLanguages: Array<string> = [],
        paramsForUrl: Object,
        timezone: string,
        getPayloadCallback: () => Object,
        onDeliverySuccess: (response: Object) => void,
        onDeliveryApiError?: (error: Object, urlParams: Object) => void
    ) {
        const { shouldTriggerForNoResults, frequencyType, dayOfMonth, dayOfWeek, hourOfDay, attachType, deliveryType } =
            alertData;

        const alertPayload = {
            accessType: ALERT_ACCESS_TYPE.PRIVATE,
            active: false,
            shouldTriggerForNoResults,
            recipientData: {
                attachType,
                deliveryType,
                comments,
                emails: utils.splitStringByComma(emails),
            },
            scheduleMeta: {
                frequency: frequencyType,
                dayOfMonth,
                dayOfWeek,
                hourOfDay,
            },
        };

        let sourceCategories = categoryUtils.getAlertSourceCategories(categories);

        const payload = {
            alertDataTO: alertPayload,
            sourceCategories,
            ...(contentLanguages.length && { contentLanguages }),
            timezone,
            ...getPayloadCallback(),
        };

        const [response, error] = await EntityViewApi.createMultipleAlerts(payload, paramsForUrl);

        if (response && response.statusCode === 200) {
            onDeliverySuccess(response);
        }
        if (error) {
            if (onDeliveryApiError) {
                onDeliveryApiError(error, paramsForUrl);
            } else {
                utils.showNotificationsMessage({
                    messageText: 'BatchScreening.generalError.message',
                    messageType: 'system-error',
                });
            }
        }
    }

    async downloadBatchScreening(model: ModelType) {
        const billingId = model.deliveryData.billingId;
        const costCode = costCodeUtils.getCostCode();
        const timezone = model.deliveryData.timezone;
        const language = getMongoLanguageKey(model.deliveryData.language);
        const startEachArticleOnNewPage = model.deliveryData.startEachArticleOnNewPage;
        const batchReportsFilename = model.fileName;
        const categoryOrder = model.deliveryData.categoryOrder;
        const paramsForUrl = model.paramsForUrl;
        const fileType = model.docType;
        const comments = model.comments;
        const reportContentType = model.reportContentType;
        const downloadInfo = { fileName: batchReportsFilename, fileType, comments };
        const onDeliveryApiError = model.onDeliveryApiError;
        const getPayloadCallback = model.getPayloadCallback;

        const store = reduxStore.getState();
        const dateRangeForCategories = model.dateRangeForCategories;

        const payload = {
            batchReportsFilename,
            billingId,
            costCode,
            timezone,
            language,
            startEachArticleOnNewPage,
            categoryOrder,
            downloadInfo,
            contentsOptions: { ...model.contentsOptions, reportContentType },
            displayRiskScores: store.user.preferences.generalSettings.displayRiskScores,
            dateRangeForCategories,
            ...(getPayloadCallback && getPayloadCallback()),
        };

        const [batchId, error] = await EntityViewApi.downloadEntities(payload, paramsForUrl);

        if (batchId) {
            reduxStore.dispatch(mainActions.setEntityViewDownloadReportsPollingActive(true));
            await notificationService.pollDownloadEntityViewReports(batchId, () => {
                reduxStore.dispatch(mainActions.setEntityViewDownloadReportsPollingActive(false));
                utils.showNotificationsMessage({
                    messageText: 'BatchScreening.notification.downloadAddedToReportBuilder',
                    messageType: 'info',
                });
            });
        }

        if (error) {
            reduxStore.dispatch(mainActions.setEntityViewDownloadReportsPollingActive(false));
            if (onDeliveryApiError) {
                onDeliveryApiError(error, paramsForUrl);
            } else {
                utils.showNotificationsMessage({
                    messageText: 'BatchScreening.generalError.message',
                    messageType: 'system-error',
                });
            }
        }
    }

    async downloadEntityViewTable(model: ModelType, fileName: string, downloadEntitiesFormat: string) {
        const isTableFormat = downloadEntitiesFormat === DOWNLOAD_ENTITIES_FORMAT_TYPE.TABLE_FORMAT
        const { exportEntitiesPayload, onDeliveryApiError } = model;

        const [excelDeliveryId, error] = await EntityViewApi.downloadEntityViewTable(
            fileName,
            exportEntitiesPayload,
            isTableFormat
        );

        if (excelDeliveryId && !isTableFormat) {
            reduxStore.dispatch(backgroundActions.clearBackgroundMessages());
            reduxStore.dispatch(
                backgroundActions.setSuccessBackgroundMessages({
                    title: 'DeliveryService.downloadReady',
                    message: 'DynamicMessage',
                    messageParameters: fileName,
                    type: BACKGROUND_MESSAGE_TYPE_DOWNLOAD,
                    link: `/api/screening-entity/export/${excelDeliveryId}`,
                    isVisible: true,
                })
            );
        } else if (excelDeliveryId && isTableFormat) {
            const notificationData = snackbarUtils.getProcessingStatusInitialData({
                actionType: ACTION_TYPES.DOWNLOAD,
            });
            reduxStore.dispatch(mainActions.addNewBatchId({batchId: excelDeliveryId, data: notificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.DOWNLOAD}));
            notificationService.pollDownloadEntities(excelDeliveryId, fileName);
        }

        if (error) {
            console.error(error);
            if (onDeliveryApiError) {
                onDeliveryApiError(error, exportEntitiesPayload);
            } else {
                utils.showNotificationsMessage({
                    messageText: 'BatchScreening.generalError.message',
                    messageType: 'system-error',
                });
            }
        }
    }
}

export default DeliveryService;
