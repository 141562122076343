import { ACTION_TYPES, NOTIFICATION_STORE_KEYS } from '@constants';

const mapActionTypeToReduxState = {
    [ACTION_TYPES.COPY]: NOTIFICATION_STORE_KEYS.COPY,
    [ACTION_TYPES.CREATE]: NOTIFICATION_STORE_KEYS.CREATE,
    [ACTION_TYPES.DELETE_ALERT]: NOTIFICATION_STORE_KEYS.DELETE_ALERT,
    [ACTION_TYPES.DELETE]: NOTIFICATION_STORE_KEYS.DELETE,
    [ACTION_TYPES.REFRESH]: NOTIFICATION_STORE_KEYS.REFRESH,
    [ACTION_TYPES.UPLOAD]: NOTIFICATION_STORE_KEYS.UPLOAD,
    [ACTION_TYPES.DOWNLOAD]: NOTIFICATION_STORE_KEYS.DOWNLOAD,
};

export const computeNotificationData = (batchIdsData = []) => {
    let totalCounts = 0;
    let readyCounts = 0;
    let errorCounts = 0;
    let processedCounts = 0;
    let processing = true;
    //will sum the errorCounts, processedCount, readyCounts, and totalCounts for all batchIds with the same actionType
    processing = batchIdsData.find(({ processing }) => processing);
    errorCounts = batchIdsData.reduce(
        (accumulator, currentValue) => accumulator + (currentValue.errorCount ?? 0) , 0);
    processedCounts = batchIdsData.reduce(
        (accumulator, currentValue) => accumulator + currentValue.processedCount, 0);
    readyCounts = batchIdsData.reduce(
        (accumulator, currentValue) => accumulator + (currentValue.readyCount ?? 0) , 0);
    totalCounts = batchIdsData.reduce(
        (accumulator, currentValue) => accumulator + currentValue.totalCount, 0);

    return {
        processing: !!processing,
        messageProps: {
            error: errorCounts,
            processed: processedCounts,
            ready: readyCounts,
            total: totalCounts,
        }
    }
};
export const createNotificationObject = (actionType, dispatch, mainActions, batchIdsData) => {
    return {
        ...computeNotificationData(batchIdsData),
        onClose: () => dispatch(mainActions.closeNotification(mapActionTypeToReduxState[actionType])),
        reset: () => dispatch(mainActions.resetNotification({keyInTheStore: mapActionTypeToReduxState[actionType]}))
    };
};

export const getNotificationObject = (actionType, dispatch, mainActions, batchIdsData) => {
    return {
        actionType,
        ...createNotificationObject(actionType, dispatch, mainActions, batchIdsData)
    }
};

const snackbarUtils = {
    getSnackbarMessage:  (actionType = '', status = '') => {
        if(!actionType) return '';
        const lowerCaseActionType = actionType.toLowerCase();
        const lowerCaseStatus = status.toLowerCase();
        return !lowerCaseActionType.includes("alert") ?
            `BatchScreening.snackbar.${lowerCaseActionType}.${lowerCaseStatus}` : `Alerts.snackbar.${lowerCaseActionType}.${lowerCaseStatus}`;
    },
    getProcessingStatusInitialData: (arg) => {
        if(!arg?.actionType) {
            console.error('The argument for actionType was not provided!');
        }

        return {
            actionType: arg?.actionType ?? '',
            errorCount: 0,
            processing: true,
            processedCount: 0,
            readyCount: 0,
            totalCount: 0,
            ...arg
        }
    }
}
export default snackbarUtils;