import { UPDATE_SANCTIONS_POD_RISK, RESET_SANCTIONS_POD_RISK } from './SanctionsRisk.actions';
import update from 'immutability-helper';
import errorUtils from '@utils/errors/error-utils';

const APP_MODULE = 'SanctionsRisk.reducers';

const sanctionsRisk = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_SANCTIONS_POD_RISK:
            if (!action.payload) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_SANCTIONS_POD_RISK,
                    { payload: action.payload },
                    APP_MODULE
                );

                return state;
            }

            return update(state, { $set: action.payload });
        case RESET_SANCTIONS_POD_RISK:
            return update(state, {
                $set: {
                    count: 0,
                    riskScore: null,
                    documents: [],
                    sendFeatureFunctionAccessEvent: true,
                },
            });
        default:
            return state;
    }
};

export default sanctionsRisk;
